import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-ie',
  template: `
    <h2 style="text-align: center;">Få en endnu bedre oplevelse af vores hjemmeside</h2>

    <p style="font-size: 15px; text-align: center">Du bruger en version af Internet Explorer, som ikke længere er understøttet. Microsoft
      beder alle om at
      opgradere til den nye og bedre Microsoft Edge.
    </p>

    <p style="font-size: 13px; text-align: center">Læs hvorfor og hvordan du selv kan opgradere her:
      <a href="https://www.microsoft.com/da-dk/microsoft-365/windows/end-of-ie-support">https://www.microsoft.com/da-dk/microsoft-365/windows/end-of-ie-support</a>
    </p>
    <div mat-dialog-actions style="text-align: center">
      <button class="ui-button" style="width: 100px; color:white;
           margin-top:10px;background-color:#003b6e" mat-button (click)="onNoClick()">Ok
      </button>
    </div>`
})
export class DialogIEComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogIEComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
