import {
  linkMe
} from '@sitemule/ngx-sitemule';

const TEASER_STACK_LAYOUTS = {
  'layout-1': {
    teaserHeight: '100',
    fullPageWidth: true,
    backgroundImageSize: 'cover',
    backgroundImagePosition: 'center',
  },
  'layout-2': {
    teaserHeight: '100',
    fullPageWidth: true,
    backgroundImageSize: 'cover',
    backgroundImagePosition: 'center'
  },
  'layout-3': {
    teaserHeight: '250',
    fullPageWidth: false,
    backgroundImageSize: 'cover',
    backgroundImagePosition: 'center',
  },
  'layout-4': {
    teaserHeight: '250',
    fullPageWidth: false,
    backgroundImageSize: 'cover',
    backgroundImagePosition: 'center'
  }
};

const TEASER_STACK_THEMES = {
  'theme-1': {
    backgroundColor: '#eee',
  },
  'theme-2': {
    backgroundColor: '#eee',
  },
  'theme-3': {
    backgroundColor: '#fff',
  },
  'theme-4': {
    backgroundColor: '#fff',
  },
  'theme-5': {
    backgroundColor: '#fff',
  }
};

const TEASER_LAYOUTS = {
  'layout-1': {
    teaserHeight: '100',
    componentLayout: 'layout-3',
    backgroundImageSize: 'cover',
    backgroundImagePosition: 'center',
    buttonSize: 'lg',
    buttonBorderRadius: '36px',
  },
  'layout-2': {
    teaserHeight: '100',
    componentLayout: 'layout-3',
    backgroundImageSize: 'contain',
    backgroundImagePosition: 'center',
    buttonSize: 'lg',
    buttonBorderRadius: '36px',
  },
  'layout-3': {
    teaserHeight: '190',
    componentLayout: 'layout-4',
    backgroundImageSize: 'cover',
    backgroundImagePosition: 'center',
    buttonSize: 'lg',
    buttonBorderRadius: '36px',
  },
  'layout-4': {
    teaserHeight: '190',
    componentLayout: 'layout-4',
    backgroundImageSize: 'contain',
    backgroundImagePosition: 'center',
    buttonSize: 'lg',
    buttonBorderRadius: '36px',
  }
};

const TEASER_THEMES = {
  'theme-1': {
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,.1)',
    hoverBorder: '',
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.1)',
    hoverBoxShadow: '0 8px 13px 0 rgba(0, 0, 0, 0.16)',
    textColor: '',
    buttonTheme: ''
  },
  'theme-2': {
    backgroundColor: 'var(--primary-color)',
    border: '1px solid rgba(0,0,0,.1)',
    hoverBorder: '',
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.1)',
    hoverBoxShadow: '0 8px 13px 0 rgba(0, 0, 0, 0.16)',
    textColor: '#fff',
    buttonTheme: ''
  },
  'theme-3': {
    backgroundColor: 'var(--primary-color)',
    border: '1px solid rgba(0,0,0,.1)',
    textColor: '#fff',
    buttonStyle: {
        '--button-background-color': '#fff',
        '--button-hover-background-color': '#fff',
        '--button-text-color': 'var(--primary-color)',
        '--button-hover-text-color': 'var(--primary-color)'
    },
  },
  'theme-4': {
    backgroundColor: '#C51A1B',
    border: '1px solid rgba(0,0,0,.1)',
    textColor: '#fff',
    buttonStyle: {
        '--button-background-color': '#fff',
        '--button-hover-background-color': '#fff',
        '--button-text-color': '#C51A1B',
        '--button-hover-text-color': '#C51A1B'
    },
  },
  'theme-5': {
    backgroundColor: '#F2F2F2',
    border: '1px solid rgba(0,0,0,.1)',
    textColor: 'var(--text-color)',
    buttonStyle: {
        '--button-background-color': 'var(--primary-color)',
        '--button-hover-background-color': 'var(--primary-color)',
        '--button-text-color': '#fff',
        '--button-hover-text-color': '#fff'
    },
  }
};

export const sanitizeTeaserStack2 = (content: any, availableLanguages: string[], defaultLang: string) => {
  const selectedTeaserStackLayout = TEASER_STACK_LAYOUTS[content.properties.page_layout.componentLayout] || TEASER_STACK_LAYOUTS['layout-1'];
  const selectedTeaserStackTheme = TEASER_STACK_THEMES[content.properties.page_theme.componentTheme] || TEASER_STACK_THEMES['theme-1'];

  return {
    contentType: 'teaserstack2',
    title: content.properties.header,
    description: content.properties.description,
    columnCount: content.properties.columnCount,
    rowCount: content.properties.rowCount,
    backgroundImage: content.properties.images,
    gridLayout: content.properties.gridLayout,

    fullPageWidth: selectedTeaserStackLayout.fullPageWidth,
    teaserHeight: selectedTeaserStackLayout.teaserHeight,
    backgroundImagePosition: selectedTeaserStackLayout.backgroundImagePosition,
    backgroundImageSize: selectedTeaserStackLayout.backgroundImageSize,

    backgroundColor: selectedTeaserStackTheme.backgroundColor,

    teasers: (() => {
      return content.items?.map(i => {
        const selectedTeaserLayout = TEASER_LAYOUTS[content.properties.page_layout.componentLayout] || TEASER_LAYOUTS['layout-1'];
        const selectedTeaserTheme = TEASER_THEMES[i.properties.page_theme.componentTheme] || TEASER_THEMES[content.properties.page_theme.componentTheme] || TEASER_THEMES['theme-1'];
        const link = i.properties.link && linkMe(i.properties.link, defaultLang, availableLanguages);
        return {
          contentType: 'teaser2',
          imageUrl: i.properties.images,
          header: i.properties.header,
          description: i.properties.description,
          link,
          linkTarget: i.properties.linkTarget,
          backgroundImageSize: selectedTeaserLayout.backgroundImageSize,
          backgroundImagePosition: selectedTeaserLayout.backgroundImagePosition,
          teaserHeight: selectedTeaserLayout.teaserHeight,
          layout: selectedTeaserLayout.componentLayout,
          border: selectedTeaserTheme.border,
          hoverBorder: selectedTeaserTheme.hoverBorder,
          boxShadow: selectedTeaserTheme.boxShadow,
          hoverBoxShadow: selectedTeaserTheme.hoverBoxShadow,
          textColor: selectedTeaserTheme.textColor,
          backgroundColor: selectedTeaserTheme.backgroundColor,
          links: i.properties.buttonText && link ? [{
            label: i.properties.buttonText || 'test',
            href: link,
            target: i.properties.linkTarget,
            size: selectedTeaserLayout.buttonSize,
            borderRadius: selectedTeaserLayout.buttonBorderRadius,
            theme: selectedTeaserTheme.buttonTheme,
            style: selectedTeaserTheme.buttonStyle,
          }] : undefined,
        }
      });
    })()
  }
};
