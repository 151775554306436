<ng-container *ngFor="let item of items">
  <ng-container *ngTemplateOutlet="pageTemplate; context: { item: item }"></ng-container>
</ng-container>
<ng-template #pageTemplate let-item="item">
  <ng-container [ngSwitch]="item.contentType">
    <div class="sm-stack" *ngSwitchCase="'stack2'"
      [style]="item.style"
      [style.--background-color]="item.backgroundColor"
      [style.--background-image]="item.backgroundImage"
      [style.--background-image-position]="item.backgroundImagePosition"
      [style.--background-image-size]="item.backgroundImageSize">
      <h2 *ngIf="item.header" class="page-header">{{item.header}}</h2>
      <ng-container *ngIf="item.items?.length">
        <ng-container *ngFor="let innerItem of item.items">
          <section class="page-section" [class]="'page-section-type-' + innerItem.contentType">
            <ng-container *ngTemplateOutlet="pageTemplate; context: { item: innerItem }"></ng-container>
          </section>
        </ng-container>
      </ng-container>
    </div>
    <sm-teaser-stack-2
      *ngSwitchCase="'teaserstack2'"
      [style]="item.style"
      [fullPageWidth]="item.fullPageWidth"
      [backgroundColor]="item.backgroundColor"
      [title]="item.title"
      [description]="item.description"
      [teaserHeight]="item.teaserHeight"
      [columnCount]="item.columnCount"
      [rowCount]="item.rowCount"
      [backgroundImage]="item.backgroundImage"
      [gridLayout]="item.gridLayout"
      [backgroundImagePosition]="item.backgroundImagePosition"
      [backgroundImageSize]="item.backgroundImageSize"
      [teasers]="item.teasers"></sm-teaser-stack-2>
    <sm-basic-article *ngSwitchCase="'text2'" [header]="item.header" [content]="item.content"></sm-basic-article>
    <ng-container *ngSwitchCase="'banner'">
      <sm-banner-layout-1
        [title]="item.title"
        [description]="item.description"
        [height]="item.height"
        [contentPosition]="item.contentPosition"
        [fullPageWidth]="item.fullPageWidth"
        [backgroundImagePosition]="item.backgroundImagePosition"
        [backgroundImageSize]="item.backgroundImageSize"
        [backgroundImageUrl]="item.backgroundImageUrl"
        [backgroundImageFullPageWidth]="item.backgroundImageFullPageWidth"
        [contentTextColor]="item.contentTextColor"
        [contentBackgroundColor]="item.contentBackgroundColor"
        [imageBorderRadius]="item.imageBorderRadius"
        [contentBorderRadius]="item.contentBorderRadius"
        [links]="item.links"
        [wrapperLink]="item.wrapperLink"
        [ngStyle]="item.style"
        [ngClass]="item.className"
        *ngIf="item.layout === 'layout-1'">
      </sm-banner-layout-1>
      <sm-banner-layout-2
        [title]="item.title"
        [description]="item.description"
        [fullPageWidth]="item.fullPageWidth"
        [ngStyle]="item.style"
        [ngClass]="item.className"
        *ngIf="item.layout === 'layout-2'">
      </sm-banner-layout-2>
    </ng-container>
    <sm-carousel *ngSwitchCase="'carousel'" [height]="item.height">
      <sm-carousel-item *ngFor="let carousel of item.items">
        <sm-banner-layout-1
          [title]="carousel.title"
          [description]="carousel.description"
          [height]="carousel.height"
          [contentPosition]="carousel.contentPosition"
          [fullPageWidth]="carousel.fullPageWidth"
          [backgroundImagePosition]="carousel.backgroundImagePosition"
          [backgroundImageSize]="carousel.backgroundImageSize"
          [backgroundImageUrl]="carousel.backgroundImageUrl"
          [backgroundImageFullPageWidth]="carousel.backgroundImageFullPageWidth"
          [contentTextColor]="carousel.contentTextColor"
          [contentBackgroundColor]="carousel.contentBackgroundColor"
          [imageBorderRadius]="carousel.imageBorderRadius"
          [contentBorderRadius]="carousel.contentBorderRadius"
          [links]="carousel.links"
          [wrapperLink]="carousel.wrapperLink">
        </sm-banner-layout-1>
      </sm-carousel-item>
    </sm-carousel>
    <ng-container *ngSwitchDefault>
      <h3>COMPONENT-{{ item | json}}</h3>
    </ng-container>
  </ng-container>
</ng-template>
