import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-sitemule-spare-parts',
  templateUrl: './spare-parts.component.html',
  styleUrls: ['./spare-parts.component.scss'],
})
export class SparePartsComponent {
  @Input() title: string;

  constructor() {}
}
