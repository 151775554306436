import {
  Component,
  Input} from '@angular/core';
import {IGetProduct} from '../../product-service.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'product-template',
  templateUrl: './product-template.component.html',
  styleUrls: ['./product-template.component.scss']
})

export class ProductTemplateComponent {
  @Input() product: IGetProduct;

  getPrioritized(array: any[]) {
    return array.filter(p => p.prioritized === true);
  }
}
