<div class="user-creat-account-container ">

	<ng-container *ngIf="hasSubmitted == false; else accountCreated">

		<h1 class="project-content-margin">{{ 'web.user.createaccount' | translate }}</h1>
		<div class="padding">
			<div class="project-content-margin">
				<div class="steps">
					<div class="step" (click)="goToStep(0)" [class.active]="selectedTabIndex == 0">1. {{ 'web.user' |
						translate }}</div>
					<div class="step-line"></div>
					<div class="step" (click)="goToStep(1)" [class.active]="selectedTabIndex == 1">2. {{
						'web.user.company' | translate }}</div>
				</div>
			</div>
		</div>
		<form [formGroup]="accountForm" #form (ngSubmit)="submit()" class="form ">
			<div class="project-content-margin">
				<ui-tab [selectedTabIndex]="selectedTabIndex">
					<div class="tab">
						<div class="page project-content-margin" formGroupName="user">
							<div class="left">
								<div class="ui-input-form">
									<div class="label">{{ 'web.user.email' | translate }}</div>
									<input type="text" formControlName="email">
									<ng-container *ngIf="accountForm.get('user.email')?.errors">
										<div class="error" *ngIf="accountForm.get('user.email')?.errors?.notUnique">{{
											'web.user.emailnotunique' | translate }}</div>
									</ng-container>
								</div>
								<div class="ui-input-form">
									<div class="label">{{ 'web.user.name' | translate }}</div>
									<input type="text" formControlName="name">
								</div>
								<div class="ui-input-form">
									<div class="label">{{ 'web.user.title' | translate }} ({{ 'web.optional' | translate
										}})</div>
									<input type="text" formControlName="title">
								</div>
								<div class="ui-input-form">
									<div class="label">{{ 'web.user.phonenumber' | translate }} ({{ 'web.optional' |
										translate }})</div>
									<input type="number" formControlName="phoneNumber">
								</div>
								<div class="ui-input-form">
									<div class="label">{{ 'web.user.mobilenumber' | translate }} </div>
									<input type="text" formControlName="mobileNumber">
								</div>
							</div>
							<div class="right">
								<ng-container *ngIf="accountForm.get('user.email')?.errors?.notUnique">
									<a [routerLink]="'/user/account'">{{ 'web.user.emailnotunique.long' |
										translate
										}}</a>
								</ng-container>
							</div>
						</div>
					</div>
					<div class="tab">
						<div class="page project-content-margin" formGroupName="company">
							<div class="left">
								<div class="ui-input-form">
									<div class="label">{{ 'web.user.vat' | translate }}</div>
									<div class="hint">
									</div>
									<input type="text" formControlName="cvr" (blur)="findFromCVR()"
										(keyup.enter)="findFromCVR()">
									<div class="error" *ngIf="accountForm.get('company.cvr')?.errors?.doesntExist">
										{{ 'web.user.vatnotfound' | translate }}
									</div>
								</div>
								<h4 style="font-size: 14.4px;">{{ 'web.user.company' | translate }}</h4>
								<div class="ui-input-form">
									<div class="label">{{ 'web.user.companyname' | translate }}</div>
									<input type="text" formControlName="companyName">
								</div>
								<div class="ui-input-form">
									<div class="label">{{ 'web.user.addressone' | translate }}</div>
									<input type="text" formControlName="addressOne">
								</div>
								<div class="ui-input-form">
									<div class="label">{{ 'web.user.addresstwo' | translate }} ({{ 'web.optional' |
										translate }})</div>
									<input type="text" formControlName="addressTwo">
								</div>
								<div style="display: grid; grid-template-columns: 100px 1fr; gap: 10px;">
									<div class="ui-input-form">
										<div class="label">{{ 'web.user.zipcode' | translate }}</div>
										<input type="number" formControlName="zipcode">
									</div>
									<div class="ui-input-form">
										<div class="label">{{ 'web.user.city' | translate }}</div>
										<input type="text" formControlName="city">
									</div>
								</div>
								<div class="ui-input-form">
									<div class="label">{{ 'web.user.country' | translate }}</div>
									<input type="text" formControlName="country">
								</div>
								<div class="ui-input-form">
									<div class="label">{{ 'web.user.phonenumber' | translate }} ({{ 'web.optional' |
										translate }})</div>
									<input type="text" formControlName="phoneNumber">
								</div>
								<div class="ui-input-form">
									<div class="label">{{ 'web.user.billingemail' | translate }} ({{ 'web.optional' |
										translate }})</div>
									<input type="text" formControlName="email">
								</div>

							</div>
							<div class="right">
								<b>{{successMessage}}</b>
							</div>
						</div>
					</div>
				</ui-tab>
			</div>

			<div class="step-controls ">
				<div class="project-content-margin">

					<ui-tab [selectedTabIndex]="selectedTabIndex">
						<div class="tab">
							<button class="ui-button" type="button" (click)="goback()">{{ 'web.cancel' | translate
								}}</button>
							<button class="ui-button success" type="button" (click)="goToStep(1)">{{ 'web.continue' |
								translate }}</button>
						</div>
						<div class="tab" style="justify-content: flex-end;">
							<button class="ui-button success" type="submit">{{ 'web.user.confirmandcreate' | translate
								}}</button>
						</div>
					</ui-tab>
				</div>
			</div>
		</form>
	</ng-container>
	<ng-template #accountCreated>


		<h1 class="project-content-margin">{{ 'web.user.donecreatingaccount' | translate }}</h1>
		<div class="form done">
			<div class="project-content-margin">
				<b>{{ 'web.user.almostfinished' | translate }}</b>
				<b style="margin-bottom: 20px;">{{ 'web.user.evaluatesubmission' | translate }}</b>
				<div class="label">{{ 'web.user' | translate }}</div>
				<span *ngIf="accountForm.value.user.email">{{accountForm.value.user.email}}</span>
				<span *ngIf="accountForm.value.user.name">{{accountForm.value.user.name}}</span>
				<span *ngIf="accountForm.value.user.title">{{accountForm.value.user.title}}</span>
				<span *ngIf="accountForm.value.user.phoneNumber">{{accountForm.value.user.phoneNumber}}</span>
				<span *ngIf="accountForm.value.user.mobileNumber">{{accountForm.value.user.mobileNumber}}</span>
				<div class="label">{{ 'web.user.company' | translate }}</div>
				<span *ngIf="accountForm.value.company.cvr">{{accountForm.value.company.cvr}}</span>
				<span *ngIf="accountForm.value.company.companyName">{{accountForm.value.company.companyName}}</span>
				<span *ngIf="accountForm.value.company.addressOne">{{accountForm.value.company.addressOne}}</span>
				<span *ngIf="accountForm.value.company.addressTwo">{{accountForm.value.company.addressTwo}}</span>
				<span *ngIf="accountForm.value.company.zipcode">{{accountForm.value.company.zipcode}}
					{{accountForm.value.company.city}}</span>
				<br>
				<span *ngIf="accountForm.value.company.country">{{accountForm.value.company.country}}</span>
				<span *ngIf="accountForm.value.company.phoneNumber">{{accountForm.value.company.phoneNumber}}</span>
				<span *ngIf="accountForm.value.company.email">{{accountForm.value.company.email}}</span>
			</div>
		</div>

			<div class="step-controls ">
				<div class="project-content-margin" style="display: flex; justify-content: flex-end;">
							<button class="ui-button success" [routerLink]="'/'" type="submit">{{ 'web.ok' | translate
								}}</button>
				</div>
			</div>
	</ng-template>
</div>
