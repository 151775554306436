import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormParent } from './form.interface';

@Component({
  selector: 'page-form-set',
  templateUrl: './form-set.component.html',
  styleUrls: ['./form-set.component.scss'],
})
export class FormSetComponent {
  @Input() formData: FormParent;
  @Input() formList: FormParent[];
  @Output() changeSet: EventEmitter<any> = new EventEmitter<any>();
  @Output() submitData: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  formRetrieve(event) {
    this.submitData.emit(event.value);
  }

  formValid(event, formData?) {
    if (this.formList) {
      const unHiddenForms = this.formList.filter(
        (form) => form.hidden !== true
      ); // Only look for them that are shown.
      const formIndex = unHiddenForms.findIndex(
        (x) => x.id === this.formData.id
      );
      if (event === 'VALID') {
        this.formData.disabled = false;
        unHiddenForms[formIndex].valid = true;
        if (unHiddenForms.length > formIndex + 1) {
          unHiddenForms[formIndex + 1].disabled = false;
          unHiddenForms[formIndex + 1].opened = true;
        }
      } else {
        unHiddenForms[formIndex].valid = false;
      }
    } else {
      formData.items.forEach((item) => {
        item.valid = event === 'VALID';
      });
    }
  }

  formHandler(event) {
    this.formList.forEach((form) => {
      if (form.dependsOn) {
        if (form.hidden) {
          if (form.anchor === event) {
            form.hidden = false;
          }
        } else {
          form.hidden = true;
        }
      }
    });
  }
}
