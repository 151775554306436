import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddAcceptLanguageHttpInterceptor } from '@sitemule/ng-components/http-interceptors/add-accept-language';
import { HttpErrorInterceptor as BaseHttpErrorInterceptor } from '@sitemule/ng-components/http-interceptors/log-error';
import { RetryHttpInterceptor } from '@sitemule/ng-components/http-interceptors/retry-http';
import { AuthorizedInterceptor } from '@sitemule/ngx-sitemule';

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AddAcceptLanguageHttpInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RetryHttpInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BaseHttpErrorInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthorizedInterceptor,
    multi: true
  },

];
