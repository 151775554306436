import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { parseUrl } from '@sitemule/ngx-sitemule';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent {
  availableLanguages = this.translate.getLangs();
  currentLanguage = this.translate.defaultLang;

  isLoadingLanguage$ = new BehaviorSubject('');

  constructor(private route: Router, private translate: TranslateService, private http: HttpClient) {}

  changeLang(language: string) {
    if (this.currentLanguage === language) {
      return;
    }

    if (this.isLoadingLanguage$.getValue() === language) {
      return;
    }

    if (!this.availableLanguages.includes(language)) {
      throw new Error(`Language ${language} is not available`);
    }

    this.isLoadingLanguage$.next(language);
    const parsedUrl = parseUrl(this.route.routerState.snapshot.url, this.availableLanguages);

    if (parsedUrl.seoUrl) {
      this.http.get<{ seourl: string }>(`/cms/cms/getSeoUrlByLanguage?seourl=${parsedUrl.seoUrl}&language=${language}`)
        .subscribe(
          (res) => {
            if (parsedUrl.pageType === 'product') {
              this.replace(`/${language}/products/${res.seourl}`);
            }
            if (parsedUrl.pageType === 'page') {
              this.replace(`/${language}/${res.seourl}`);
            }
            this.isLoadingLanguage$.next('');
          },
          () => {
            // If current url has seourl but service returns 404, it means seourl is same for all languages
            if (parsedUrl.pageType === 'product') {
              this.replace(`/${language}/products/${parsedUrl.seoUrl}`);
            }
            if (parsedUrl.pageType === 'page') {
              this.replace(`/${language}/${parsedUrl.seoUrl}`);
            }
            this.isLoadingLanguage$.next('');
          }
        );
    }
    else if (parsedUrl.pageType === 'user') {
      this.replace(`/${language}/user${parsedUrl.url ? `/${parsedUrl.url}` : ''}`);
    }
    else if (parsedUrl.pageType === 'product') {
      this.replace(`/${language}/products`);
    }
    else {
      this.replace(`/${language}${parsedUrl.url ? `/${parsedUrl.url}` : ''}`);
    }
  }

  replace(url: string) {
    window.location.href = url;
  }
}
