<div appUiTable style="margin-bottom: 50px; font-size: 14px;">
  <div *ngIf="$tableHeaders | async as tableHeaders">
    <div *ngFor="let header of tableHeaders" [class.active]="(_sortBySource | async) == header"
      (click)="setSorting(header)">{{header.title}}</div>
  </div>
  <div [class.selected]="(_selectedRowSource | async) == row" *ngFor="let row of $tableData | async"
    (click)="rowClick(row)" style="cursor: pointer">
    <div *ngFor="let columnData of row.columns">
      <ng-container *ngIf="checkIfLink(columnData); else standard">
        <a href="{{columnData}}" target="_blank">Link</a>
      </ng-container>
      <ng-template #standard>
        {{columnData}}
      </ng-template>
    </div>
  </div>
  <!--  </ng-container>-->
</div>
<div class="pagination-container" *ngIf="_totalPages.length > 1">

  <div class="numbers" *ngIf="_paginationSource | async as pagination">
    <div class="icon" (click)="setCurrentPage(pagination.currentPage - 1)">
      <span class="material-icons-outlined">
        chevron_left
      </span>
    </div>
    <span [class.current]="pagination.currentPage == i" *ngFor="let item of _totalPages; let i = index"
      (click)="setCurrentPage(i)">
      {{i + 1}}
    </span>
    <div class="icon">
      <span class="material-icons-outlined" (click)="setCurrentPage(pagination.currentPage + 1)">
        chevron_right
      </span>
    </div>
  </div>
  <select (change)="setShownRows($event)">
    <option [value]="item" *ngFor="let item of [25,50,100,200]">{{item}} rækker</option>
  </select>
</div>