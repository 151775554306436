import { Injectable } from '@angular/core';
import { CanMatch, Route, UrlSegment } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CanMatchGuard implements CanMatch {
  constructor(private translateService: TranslateService) {}

  canMatch(_route: Route, segments: UrlSegment[]) {
    const availableLanguages = this.translateService.getLangs();
    return availableLanguages.indexOf(segments[0].path) > -1
  }
}
