<div class="list-overview-container">
  <form class="filter-bar" [formGroup]="searchForm">
    <div class="ui-input-icon-group">
      <i class="icon-Soeg"></i>
      <input #searchEl type="text" #input formControlName="search" placeholder="Søg på dine lister her">
    </div>
    <button class="ui-button" type="button" (click)="createList()">Opret</button>
  </form>
  <div class="filter-header" *ngIf="{ sortBy: sortBy$ | async, sortDir: sortDir$ | async } as sortData">
    <div class="head" (click)="sortList('overskrift');">
      Overskift
      <span class="material-icons" *ngIf="sortData.sortBy === 'overskrift'">
        {{sortData.sortDir === 'desc' ? 'north' : 'south'}}
      </span>
    </div>
    <div class="head" (click)="sortList('dato')">Dato
      <span class="material-icons" *ngIf="sortData.sortBy === 'dato'">
        {{sortData.sortDir === 'desc' ? 'north' : 'south'}}
      </span>
    </div>
  </div>
  <div class="lists">
    <ngx-sitemule-list-item
      *ngFor="let list of data$ | async"
      [listItem]="list"
      (outExport)="exportList(list)"
      (outOpen)="openList(list)"
      (outPrint)="printList(list)"
      (outPrintInfo)="printQRList(list)"
      (outDelete)="deleteList(list)"
      (outCopyListToBasket)="copyListToBasket(list)"
    >
    </ngx-sitemule-list-item>
  </div>
</div>
