<div class="ui-menu-container">
  <ng-container *ngFor="let menu of menuList">
    <div class="menu-item" [ngClass]="{'activeItem': activeMenu === menu}" [style.display]="menu.hidden ? 'none' : ''" (mouseenter)="menuMouseOver(menu)" (click)="menuItemClicked(menu)">
      <i class="icon {{menu.icon}}" *ngIf="menu.icon">
      </i>
      <div class="label-container">
        <a [smNavigate]="menu.seourl">
          <ng-container *ngIf="!menu.anchor">{{menu.label}}</ng-container>
          <ng-container *ngIf="menu.anchor">
            {{'web.menu.' + menu.anchor | lowercase |  translate: {default: menu.label} }}
          </ng-container>
        </a>
      </div>
    </div>
    <ui-menu *ngIf="menu.items && menu.expanded" [menuList]="menu.items" (outMenuMouseOver)="menuMouseOver($event)"
      [activeMenu]="activeMenu"></ui-menu>
  </ng-container>
</div>
