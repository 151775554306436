import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { catchError, filter, first } from 'rxjs/operators';
import { SiteConfigServiceV2 } from '../../core/services/site-config.service';
import { PageType, WebshopService } from '../../core/services/webshop.service';
import { BreadcrumbService } from '../../ui/components/breadcrumb/breadcrumb.service';
import { CatalogService } from '../catalog/catalog.service';
import { IGetProduct } from '../product/product-service.interface';
import { ProductService } from '../product/product.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'webshop-entry',
  templateUrl: './webshop-entry.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./webshop-entry.component.scss'],
})
export class WebshopEntryComponent implements OnDestroy {

  loadingCatalog$ = new BehaviorSubject(false);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private webshopEntryService: WebshopService,
    private catalogService: CatalogService,
    private productService: ProductService,
    private breadCrumbService: BreadcrumbService,
    private cdr: ChangeDetectorRef,
    private siteConfigService: SiteConfigServiceV2,
    private translate: TranslateService,
  ) {
    this.routeSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        const params = this.activatedRoute.snapshot.queryParams;
        const snapshotUrl = this.activatedRoute.snapshot.url.map(seg => seg.path.trim()).filter(p => !!p).join('/');

        // Move this logic handling pid- to backed service using 302 redirect
        if (snapshotUrl.startsWith('pid-')) {
          const productId = snapshotUrl.replace('pid-', '');

          this.loadingCatalog$.next(true);

          this.productService
            .getProductById(productId)
            .subscribe({
              error: () => {
                const notFoundPageId = this.siteConfigService.$config.getValue().not_found_404_page_id;
                this.router.navigateByUrl(`/${this.translate.defaultLang}/${notFoundPageId}`, {
                  replaceUrl: true,
                });
              },
              next: (res) => {
                this.router.navigateByUrl(`/${this.translate.defaultLang}/products/${res.seourl}`, {
                  replaceUrl: true,
                });
              }
            })
            return;
        }
        if (params.search) {
          this.doSearch(params.search);
          return;
        }

        this.setCatalogData(snapshotUrl, params.filter);
      });
  }

  teaserStack$ = this.webshopEntryService.getCatalogAndProductTeasers();
  pageType: PageType;
  routeSubscription: Subscription;
  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  private setCatalogData(seoUrl: string, filterUrl?: string) {
    this.loadingCatalog$.next(true);
    this.webshopEntryService
      .getContentBySeo(seoUrl, filterUrl)
      .pipe(
        first(),
        catchError(() => {
          const notFoundPageId = this.siteConfigService.$config.getValue().not_found_404_page_id;
          this.router.navigateByUrl(`/${this.translate.defaultLang}/${notFoundPageId}`, {
            replaceUrl: true,
          });

          return of();
        }),
      )
      .subscribe((res) => {
        if (res.type === 'catalog') {
          this.catalogService.setCatalogList(res.content);
        }
        if (res.type === 'product') {
          // @ts-ignore
          let productType: IProductHTTP = res;

          // @ts-ignore
          let product: IGetProduct = res.content;
          product.categorySeourl =
            productType.breadcrumb?.length > 2
              ? productType.breadcrumb[productType.breadcrumb.length - 2].seourl
              : '';
          this.breadCrumbService.setBreadcrumbList(
            // @ts-ignore
            res.breadcrumb ? res.breadcrumb : [],
            'products'
          );
          this.productService.setProduct(res.content);
        }
        let breadCrumb: any[] =
          // @ts-ignore
          res.type === 'catalog' ? res.content.breadcrumb : res.breadcrumb;
        breadCrumb = breadCrumb;
        if (this.breadCrumbService.getSizeOfBreadcrumbs() === 0) {
          this.breadCrumbService.setBreadcrumbList(
            breadCrumb ? breadCrumb : [],
            'products'
          );
        } else {
          if (res.type === 'catalog') {
            this.breadCrumbService.setBreadcrumbList(
              breadCrumb ? breadCrumb : [],
              'products'
            );
          }
        }
        this.pageType = res.type;
        this.cdr.markForCheck();
        this.loadingCatalog$.next(false);
      });
  }

  private doSearch(searchTxt) {
    this.loadingCatalog$.next(true);
    this.webshopEntryService
      .doProductSearch(searchTxt)
      .pipe(first())
      .subscribe((res: any) => {
        this.pageType = 'catalog';
        res.title = 'Søgning:  "' + res.label + '"';
        this.breadCrumbService.setBreadcrumbList(res.breadcrumb, 'products');
        this.catalogService.setCatalogList(res);
        this.cdr.detectChanges();
        this.loadingCatalog$.next(false);
      });
  }
}
