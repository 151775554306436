import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from '@sitemule/ng-components/pipes/marked';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxLoadingModule } from "ngx-loading";
import { SitemuleCoreModule } from '../../core/sitemule-core.module';
import { SitemuleUiModule } from '../../ui/sitemule-ui.module';
import { CatalogModule } from '../catalog/catalog.module';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { ProductTemplateComponent } from './components/product-template/product-template.component';
import { ProductComponent } from './components/product/product.component';
import { ProductEntryComponent } from './product-entry.component';
import { ProductService } from './product.service';
@NgModule({
  declarations: [
    ProductEntryComponent,
    ProductTemplateComponent,
    ProductComponent,
    DocumentListComponent
  ],
    imports: [CommonModule,
        CatalogModule,
        SitemuleUiModule,
        MatIconModule,
        SitemuleCoreModule,
        TranslateModule.forChild(),
        NgxQRCodeModule, NgxLoadingModule.forRoot({}), MarkdownModule
    ],
  exports: [ProductEntryComponent],
  providers: [ProductService],
})
export class ProductModule {
}
