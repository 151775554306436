
<ng-container *ngIf="pageContent$ | async as pageContent">
  <ng-container *ngIf="pageContent.type">
    <ng-container *ngTemplateOutlet="switchCtx; context: { ctx: pageContent.content }"></ng-container>
  </ng-container>
</ng-container>

<ng-template #switchCtx let-item="ctx" let-parentList="parentList">
  <ng-container [ngSwitch]="item.contentType">
    <app-page *ngSwitchCase="'newteaserstack2'" [items]="[item]"></app-page>
    <app-page *ngSwitchCase="'teaserstack2'" [items]="[item]"></app-page>
    <app-page *ngSwitchCase="'stack2'" [items]="[item]"></app-page>
    <app-page *ngSwitchCase="'banner'" [items]="[item]"></app-page>
    <app-page *ngSwitchCase="'carousel'" [items]="[item]"></app-page>
    <app-page *ngSwitchCase="'text2'" [items]="[item]"></app-page>
    <ng-container *ngSwitchCase="'stack'">
      <ng-container *ngTemplateOutlet="stack; context: item"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'stackAccordion'">
      <ng-container *ngTemplateOutlet="stackAccordion; context: item">
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'stackAccordionForm'">
      <ng-container *ngTemplateOutlet="stackAccordionForm; context: item">
      </ng-container>
    </ng-container>

    <page-article *ngSwitchCase="'text'" [articleOptions]="item"></page-article>
    <page-media-slider *ngSwitchCase="'mediagallery'" [mediaSlider]="item"></page-media-slider>
    <page-web-container *ngSwitchCase="'webcontainer'" [webContainerData]="item"></page-web-container>
    <page-teaser-stack *ngSwitchCase="'teaserstack'" [teaserStack]="item"></page-teaser-stack>
    <page-business-cards *ngSwitchCase="'businessCards'" [bcData]="item">
    </page-business-cards>
    <page-form-set *ngSwitchCase="'formSet'" [formData]="item" [formList]="parentList" (changeSet)="openNextAccordion($event)"
      (submitData)="submitFormData($event)">
    </page-form-set>
    <ng-container *ngSwitchDefault>
      <h3>COMPONENT-{{ item | json}}</h3>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #stack let-header="header" let-subHeader="subHeader" let-pageMenuId="pageMenuId" let-pageMenu="pageMenu"
  let-items="items">
  <ng-container>
    <h2 *ngIf="header && header.length > 0">{{ header }}</h2>
    <section *ngFor="let item of items" [class]="'page-' + item.contentType">
      <ng-container *ngTemplateOutlet="switchCtx; context: { ctx: this.item }"></ng-container>
    </section>
  </ng-container>
</ng-template>

<ng-template #stackAccordion let-header="header" let-subHeader="subHeader" let-pageMenuId="pageMenuId"
  let-pageMenu="pageMenu" let-hasMenu="hasMenu" let-submit="submit" let-items="items">
  <div class="accordion project-content-margin">
    <ng-container>
      <div class="left" *ngIf="hasMenu">
        <ui-menu [routerLink]="[]" [activeMenu]="$activeUIMenu | async" (outMenuSelected)="menuClick($event)"
          [menuList]="items">
        </ui-menu>
      </div>
      <div class="right">
        <p class="sa-header">{{'web.stack.' + header + '.header' | lowercase | translate: {default: header} }}</p>
        <ng-container *ngFor="let accordion of items">
          <div [id]="accordion.anchor">
            <ui-accordion #accordionComponent (toggle)="accordionToogle(accordion)" [accordion]="accordion">
              <ng-container *ngTemplateOutlet="switchCtx; context: { ctx: this.accordion, parentList: items }">
              </ng-container>
            </ui-accordion>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>


<!--Temp fix of form-->
<ng-template #stackAccordionForm let-header="header" let-translationKey="translationKey" let-subHeader="subHeader"
  let-pageMenuId="pageMenuId" let-pageMenu="pageMenu" let-caseType="caseType" let-seourl="seourl" let-hasMenu="hasMenu"
  let-submit="submit" let-items="items">
  <div class="accordion project-content-margin">
    <ng-container>
      <div class="right">
        <p class="sa-header">{{'web.form.' + header + '.header' | lowercase | translate: {default: header} }}</p>
        <ng-container *ngFor="let accordion of items">
          <div [id]="accordion.anchor">
            <ui-accordion #accordionComponent (toggle)="accordionToogle(accordion)" [accordion]="accordion">
              <page-form-set [formData]="this.accordion" [formList]="items" (changeSet)="openNextAccordion($event)"
                (submitData)="submitFormData($event)">
              </page-form-set>
            </ui-accordion>
          </div>
        </ng-container>
        <ng-container *ngIf="submit">
          <div class="submit" [style.width]="'60%'">
            <ng-container *ngIf="submit.template">
              <ng-container *ngTemplateOutlet="switchCtx; context: { ctx: submit.template}"></ng-container>
            </ng-container>
            <button class="ui-button" [disabled]="submit.template ? submit.template?.items[0]?.error : false"
              *ngIf="submit.button" (click)="stackAccClick(submit, items)">
              {{'web.form.' + translationKey + '.buttontxt' | lowercase | translate}}</button>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
