import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { EmptyComponent } from '@sitemule/ngx-sitemule';
import { HeaderAndFooterComponent } from './components/header-and-footer/header-and-footer.component';
import { PageStackComponent } from './components/page-stack/page-stack.component';
import { CanMatchGuard } from './interceptors/can-match.guard';
import { RedirectWithDefaultLanguageGuard } from './interceptors/root-language-redirector.guard';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',

  scrollOffset: [0, 64],
};


const routes: Routes = [
  {
    path: '',
    canActivate: [RedirectWithDefaultLanguageGuard],
    component: EmptyComponent,
  },
  {
    // FLE published https://folkelarsen.dk/download link on print media, which ended up in 404 page,
    //    So add redirect here. Remove this if needed
    path: 'download',
    canActivate: [RedirectWithDefaultLanguageGuard],
    component: EmptyComponent,
  },
  {
    // language matcher -> /{da | en}/products/testudstyr-og-transducere
    matcher: (url) => {
      return url && url[0] && url[0].path.length === 2
        ? { consumed: url.slice(0, 1) }
        : null;
    },
    canMatch: [CanMatchGuard],
    component: HeaderAndFooterComponent,
    children: [
      {
        path: '',
        component: PageStackComponent,
      },
      {
        path: 'user',
        loadChildren: () =>
          import('@sitemule/ngx-sitemule').then(
            (mod) => mod.SitemuleUserModule
          ),
      },
      {
        path: 'products',
        loadChildren: () =>
          import('@sitemule/ngx-sitemule').then(
            (mod) => mod.SitemuleWebshopModule
          ),
      },
      {
        path: ':seo',
        children: [
          {
            path: '',
            component: PageStackComponent,
          },
          {
            path: ':seo2',
            component: PageStackComponent,
          },
        ],
      },
    ],
  },
  {
    path: '**',
    data: {
      show404: true,
    },
    component: HeaderAndFooterComponent,
    children: [
      {
        path: '',
        component: PageStackComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
