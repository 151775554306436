<!--TODO: Should use dynamic form-->
<mat-card>
  <!--  <mat-card-content>-->
  <mat-card-title>
    <h3 style="margin-bottom: 10px;" *ngIf="userRegForm.title">{{userRegForm.title}}</h3>
  </mat-card-title>
  <mat-card-content>
    <ui-dynamic-form-group [datas]="userRegForm" (formValid)="formValidFn($event)" (formEmit)="setForm($event)">
    </ui-dynamic-form-group>
  </mat-card-content>
  <mat-card-footer>
    <div class="buttons">
      <button mat-stroked-button type="button" (click)="goBack()">Tilbage</button>
      <div style="flex:1"></div>
      <button mat-stroked-button color="primary" type="submit" [disabled]="formValid" (click)="onSubmit()">{{userRegForm.button}}</button>
    </div>
  </mat-card-footer>
  <!--  </mat-card-content>-->
</mat-card>
