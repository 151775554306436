import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private http: HttpClient) {
  }

  sendEmail(data: MustHaveEmailData) {
    return this.http.post('/cms/cms/createNotificationByTemplate', data);
  }
}

export interface MustHaveEmailData {
  templatePath: string;
  subject: string;
  cc?: string;
  replyTo?: string;
  sender?: string;
  recipient: string;
  data: any;
}
