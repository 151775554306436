<h3>Change Password</h3>
<form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
  <mat-form-field>
      <input matInput placeholder="Password" type="password" formControlName="oldPassword">
  </mat-form-field>
  <mat-form-field>
      <input matInput placeholder="New Password" type="password" formControlName="newPassword">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Repeat Password" type="password" formControlName="repeatPassword">
</mat-form-field>
  <div fxLayout="row">
      <button mat-raised-button color="primary">Submit</button>
  </div>
</form>
