import { Component, Input } from '@angular/core';
import { Article } from '../../page.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
})
export class ArticleComponent {
  @Input() articleOptions: Article;
}
