import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type ITeaserLayout = 'layout-1' | 'layout-2';
type IBackgroundImagePosition = 'left top' | 'top center' | 'right top' | 'right center' | 'right bottom' | 'bottom center' | 'left center' | 'center center';
type IBackgroundImageSize = 'cover' | 'contain' | 'fill';

interface ILinks {
  label: string;
  href: string;
  size: 'lg' | 'sm' | 'md';
  theme?: string;
  stroked: boolean;
  target?: string;
  borderWidth: string;
  borderRadius: string;
  style: {
    [key: string]: string
  }
}
interface ITeaser {
  link?: string;
  linkTarget?: 'blank';
  teaserHeight?: number;
  imageUrl?: string;
  header?: string;
  description?: string;
  border?: string;
  hoverBorder?: string;
  boxShadow?: string;
  hoverBoxShadow?: string;
  textColor?: string;
  backgroundColor?: string;
  backgroundImageSize?: IBackgroundImageSize;
  backgroundImagePosition?: IBackgroundImagePosition;
  layout?: ITeaserLayout;
  links?: ILinks[];
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sm-teaser-stack-2',
  templateUrl: './teaser-stack-2.component.html',
  styleUrls: ['./teaser-stack-2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeaserStack2Component {
  @Input() gridLayout?: string;
  @Input() teaserHeight?: number;
  @Input() columnCount?: number = 3;
  @Input() rowCount?: number;
  @Input() title?: string;
  @Input() description?: string;
  @Input() teasers: ITeaser[] = [];
  @Input() backgroundColor?: string;
  @Input() fullPageWidth? = false;
  @Input() backgroundImage?: string;
  @Input() backgroundImageSize?: IBackgroundImageSize = 'cover';
  @Input() backgroundImagePosition?: IBackgroundImagePosition = 'center center';

  get _gridLayout() {
    if (!this.gridLayout) {
      return 'custom';
    }
    return this.gridLayout;
  }

  get _teaserHeight() {
    if (!this.teaserHeight) {
      return 200;
    }
    return this.teaserHeight;
  }


  get finalTeasers() {
    if (this.rowCount && this.columnCount) {
      const totalItems = this.columnCount * this.rowCount;
      if (this.teasers.length <= totalItems) {
        return this.teasers;
      }
      return this.teasers.splice(0, totalItems);
    }
    return this.teasers;
  }

  trackByFn(_index: number, teaser: ITeaser) {
    return teaser.header;
  }

}
