<a class="spare-part" routerLink="{{link}}">
  <div class="inventory-status" [ngClass]="inventory.inventoryStatus" *ngIf="inventory">
  </div>
  <section class="spare-part__details">
    <div class="item">

      <span class="item__id">{{ productNumber }}</span>
      <p class="item__name">{{ title }}</p>
    </div>
    <div class="description">
      <span class="item__number">{{ label }}: {{ vendorItemNo }}</span>
      <p class="item__description">{{ description }}</p>
    </div>
    <div class="price">
      <p class="item__price">{{ price }},-</p>
      <span class="item__vat">{{ vatText }}</span>
    </div>
  </section>
  <section class="spare-part__icons">
    <mat-icon (click)="addToFavoriteList($event)">star_outline</mat-icon>
    <mat-icon class="material-icons-outlined" (click)="addToBasket($event)">shopping_cart</mat-icon>
  </section>
</a>
