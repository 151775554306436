import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ui-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * @deprecated, Seems not to be used. CHECK
 */
export class MapComponent implements OnChanges {
  /**
   * Ie: "Håndværkersvinget 8"
   */
  @Input() address: string;
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  $adressInfo: Observable<NominatimDBOInterface>;

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.address.currentValue) {
      this.$adressInfo = this.http
        .get<NominatimDBOInterface>(
          `https://nominatim.openstreetmap.org/search/${encodeURI(
            simpleChanges.address.currentValue
          )}?format=json`
        )
        .pipe(
          map((locations) => {
            if (Array.isArray(locations) && locations.length > 0) {
              locations = locations[0];
            }
            return locations;
          })
        );
    }
  }

  getUrl(nominatimDBO: NominatimDBOInterface) {
    const url = `https://www.openstreetmap.org/export/embed.html?bbox=${nominatimDBO.boundingbox[2]}%2C${nominatimDBO.boundingbox[0]}%2C${nominatimDBO.boundingbox[3]}%2C${nominatimDBO.boundingbox[1]}&amp;layer=mapnik&amp&marker=${nominatimDBO.lat}%2C${nominatimDBO.lon}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

export interface NominatimDBOInterface {
  place_id: number;
  licence: string;
  osm_type: string;
  osm_id: number;
  boundingbox: string[];
  lat: string;
  lon: string;
  display_name: string;
  class: string;
  type: string;
  importance: number;
}
