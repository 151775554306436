import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { BasketService } from '../../../../core/basket/basket.service';

@Component({
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss']
})
export class ReferenceComponent implements OnInit {

  minDate = new Date();
  loading$ = new BehaviorSubject(false);
  constructor(
    private basketServicev2: BasketService,
    private dialogRef:MatDialogRef<ReferenceComponent>) { }

  ngOnInit(): void {
    this.basketServicev2.basket$.pipe(first()).subscribe(b => {
      this.referenceGroup.setValue({
        deliveryDate: b.deliveryDate,
        yourReference: b.reference,
        comment: b.deliveryRemark
      })
    });
  }
  referenceGroup = new UntypedFormGroup({
    deliveryDate: new UntypedFormControl('', Validators.required),
    yourReference: new UntypedFormControl(''),
    comment: new UntypedFormControl('')
  })

  submit() {
    let formVal = this.referenceGroup.value;
    this.loading$.next(true);
    this.basketServicev2.updateBasket({
      deliveryDate: formVal.deliveryDate,
      reference: formVal.yourReference,
      deliveryRemark: formVal.comment
    }).subscribe(() => {
      this.loading$.next(false);
      this.dialogRef.close(true);
    }, () => {
      this.loading$.next(false);
    });
  }
}
