import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SEOService } from '@sitemule/ng-components/services/seo';
import {
  BreadcrumbService,
  EmailService,
  MustHaveEmailData,
  SiteConfigServiceV2,
  SitemulePageService
} from '@sitemule/ngx-sitemule';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
// @ts-ignore
import fragtskadeJson from '../../../assets/json/forms/Fragtskader.json';
// @ts-ignore
import reparationJson from '../../../assets/json/forms/Reparation_Kalibrering.json';
// @ts-ignore
import returneringJson from '../../../assets/json/forms/Returnering.json';
import { sanitizeContent } from './utils/sanitizeContent';

@Component({
  selector: 'app-page-stack',
  templateUrl: './page-stack.component.html',
  styleUrls: ['./page-stack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageStackComponent {
  @ViewChild('accordionComponent') accordionComponent;

  submitForm: object;
  pageContent$ = this.route.paramMap.pipe(
    switchMap((params: ParamMap) => {
      const show404 : boolean = this.route.snapshot.data['show404'];
      const notFoundPageId = this.siteConfigService.$config.getValue().not_found_404_page_id;
      const seoUrl = params.keys.map((k) => params.get(k)).join('/');
      const landingPageId = this.siteConfigService.$config.getValue().landing_page_id;

      if (show404) {
        return this.pageService.getContent(notFoundPageId, false);
      }

      if (seoUrl) {
        return this.pageService.getContent(seoUrl, false)
          .pipe(
            catchError(() => {
              return this.pageService.getContent(notFoundPageId, false)
            })
          );
      }

      if (landingPageId) {
        return this.pageService.getContent(landingPageId, true)
          .pipe(
            catchError(() => {
              return this.pageService.getContent(notFoundPageId, false)
            })
          );
      }

      throw new Error(`Empty seo_url`);
    }),
    map(res => {;
      return {
        ...res,
        content: sanitizeContent(res.content, this.translate.getLangs(), this.translate.defaultLang),
      };
    }),
    tap((res: any) => {
      this.seoService.setPageTitle(res.content.metatitle || res.content.header || '');
      this.seoService.setPageMetaDescription(res.content.metadescription ?? res.content.title ?? '')
    }),
    tap((res: any) => {
      const breadCrumb = (() => {
        const currentPageSeoUrl =  res.seourl;
        const isHome = currentPageSeoUrl === 'home';
        const breadcrumbFromResponse = res.breadcrumb || res.content.breadcrumb || [];
        const welcomeCrumb = {
          seourl: '',
          title: this.translate.defaultLang === 'da' ? 'Velkommen' : 'Welcome'
        };

        if (isHome) {
          return [];
        }

        const thisPageCrumb = (() => {
          const crumb = (breadcrumbFromResponse || []).find(crumb => {
            return crumb.seourl === currentPageSeoUrl;
          });

          if(crumb) {
            return crumb;
          }

          return {
            seourl: currentPageSeoUrl,
            title: (() => {
              const a = currentPageSeoUrl.trim().replace(/-/g, ' ');
              return `${a[0].toUpperCase()}${a.slice(1)}`;
            })()
          }
        })();

        return [welcomeCrumb, thisPageCrumb];
      })();
      this.breadCrumbServices.setBreadcrumbList(breadCrumb);
    }),
    map((res: any) => {
      switch (res.content.seourl) {
        case 'service/reparation':
        case 'service/repair':
          return {
            ...res,
            content: {
              ...res.content,
              contentType: 'stack',
              items: [res.content.items[0], reparationJson, ...res.content.items.splice(1)]
            }
          }
        case 'service/fragtskader':
        case 'service/freightdamage':
          return {
            ...res,
            content: {
              ...res.content,
              contentType: 'stack',
              items: [res.content.items[0], fragtskadeJson, ...res.content.items.splice(1)]
            }
          };
        case 'service/returnering':
        case 'service/returns':
          return {
            ...res,
            content: {
              ...res.content,
              contentType: 'stack',
              items: [res.content.items[0], returneringJson, ...res.content.items.splice(1)]
            }
          };
      }
      return res;
    }),
    switchMap((res: any) => {
      if (res.content.seourl === 'om-os/medarbejdere' || res.content.seourl === 'about-us/employees') {
        return this.http
          .get('/cms/cms/listContacts/1')
          .pipe(map((r: any) => {
            return {
              ...res,
              content: {
                ...res.content,
                items: [
                  ...res.content.items,
                  {
                    // contentType: 'businessCards',
                    contentType: 'stackAccordion',
                    translationKey: 'call-us',
                    header: 'Ring til os på tlf: 43 96 75 77',
                    hasMenu: true,
                    items: r.map((s) => {
                      // TODO: Remove quickhack after services is changed
                      s.anchor = s.header.toLowerCase();
                      if (s.header !== 'Kundesupport') {
                        s.opened = false;
                      }
                      s.contentType = 'businessCards';

                      s.content.items = s.content.items.sort((a, b) => {
                        return a.name - b.name;
                      });

                      return s;
                    }),
                  }
                ]
              }
            }
          }))
      }
      return of(res)
    }),
  );

  $activeUIMenu: Observable<any> = this.route.fragment.pipe(
    map((fragment) => {
      if (fragment) {
        return fragment;
      }
    })
  );

  constructor(
    private route: ActivatedRoute,
    private routes: Router,
    private pageService: SitemulePageService,
    private http: HttpClient,
    private breadCrumbServices: BreadcrumbService,
    private translate: TranslateService,
    private emailService: EmailService,
    private seoService: SEOService,
    private siteConfigService: SiteConfigServiceV2
  ) {}

  menuClick(event) {
    if (!event.disabled) {
      event.opened = true;
    }
  }

  openNextAccordion(event) {
    if (!event.disabled) {
      event.opened = true;
    }
  }

  submitFormData(data) {
    if (!this.submitForm) {
      this.submitForm = data;
    } else {
      this.submitForm = Object.assign(this.submitForm, data);
    }
  }

  accordionToogle(event) {
    event.opened = !event.opened;
    this.routes.navigate([location.pathname], {});
  }

  // TODO: Rewrite entire form component and handling.
  stackAccClick(submit, forms) {
    switch (submit.button.context.toLowerCase()) {
      case 'submit':
        this.submitEmailForm(this.submitForm, submit, forms);
        break;
      default:
        console.error('Unkown stack accordion context');
    }
  }

  private submitEmailForm(form, submit, formGroups) {
    const specs = [];

    formGroups.forEach((formItem) => {
      formItem.items.find((item) => item.id === '');
    });

    Object.keys(form).forEach((key, index) => {
      // Setting translatedKeys for mail.
      let translatedKey;
      let objValue = form[key];
      formGroups.forEach((formItem) => {
        const formControl = formItem.items.find((item) => item.key === key);
        if (formControl) {
          // Getting values for option dropdown if needed.
          if (formControl.controlType === 'dropdown') {
            const optionTranslation =
              'web.form.' +
              formItem.id +
              '.' +
              formControl.id +
              '.options.' +
              form[key];
            this.translate
              .get(optionTranslation.toLowerCase(), {})
              .pipe(first())
              .subscribe((res) => {
                objValue = res;
              });
          }
          const txt =
            'web.form.' + formItem.id + '.' + formControl.id + '.placeholder';
          this.translate
            .get(txt.toLowerCase(), {})
            .pipe(first())
            .subscribe((res) => {
              translatedKey = res;
            });
        }
      });
      if (key === 'terms_conditions') {
        const txt = 'web.form.terms_conditions.placeholder';
        this.translate
          .get(txt.toLowerCase(), {})
          .pipe(first())
          .subscribe((res) => {
            translatedKey = res;
          });
        objValue = form[key] == true ? 'Ja' : 'Nej';
      }
      // Creating mail object.
      const obj = {
        color: index % 2 ? '#e6e6e6' : 'white',
        label: translatedKey,
        value: objValue,
      };
      specs.push(obj);
    });

    setTimeout(() => {
      const FLEmailRcpAdr =
        submit.caseType == 'returneringssag'
          ? 'info@folkelarsen.dk'
          : 'kbn@folkelarsen.dk';
      const logo =
        'https://folkelarsen.dk/.0/cms/scaleImage.aspx?path=/cms-media/page/logo.png&width=340&height=80';
      const data = { specs, logo };
      const mailObj: MustHaveEmailData = {
        recipient: form.email + ';' + FLEmailRcpAdr + '(bcc)',
        data,
        subject: 'Din ' + submit.caseType + ' hos Folke Larsen A/S',
        templatePath: '/custom-config/templates/casesMail.tpl',
      };

      this.emailService.sendEmail(mailObj).subscribe((res: any) => {
        if (res) {
          this.routes.navigateByUrl('/da/tak-for-din-henvendelse');
        }
      });
    }, 500);
  }
}
