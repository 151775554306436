<div class="blue-bar">
  <div style="display: inline;" [innerHTML]="'web.header.callus' | lowercase | translate">
  </div>
  |
  <a style="text-decoration: underline; cursor:pointer" [smNavigate]="getLink('om-os/levering')">
    {{'web.header.orderwithin' | lowercase | translate}}</a>
</div>
<div class="full-width">

  <div class="link-container project-content-margin">

    <div class="link-column">
      <a [smNavigate]="getLink('products')" class="link">{{'web.footer.products' | lowercase | translate}}</a>
      <a [smNavigate]="getLink('automatik-montageteknik/automatik')" class="link">{{'web.footer.automatic' | lowercase | translate}}
      </a>
      <a [smNavigate]="getLink('automatik-montageteknik/montageteknik')" class="link">
        {{'web.footer.montage' | lowercase | translate}}</a>
      <a [smNavigate]="getLink('brands')" class="link">{{'web.footer.brands' | lowercase | translate}}</a>
      <a [smNavigate]="getLink('om-os/handelsbetingelser')" class="link">{{'web.footer.termsofsale' | lowercase | translate}}</a>
    </div>
    <div class="link-column">
      <a [smNavigate]="getLink('om-os')" class="link">{{'web.footer.aboutus' | lowercase | translate}}</a>
      <a [smNavigate]="getLink('kundesupport')" class="link">{{'web.footer.custsup' | lowercase | translate}}</a>
      <a [smNavigate]="getLink('service')" class="link">{{'web.footer.serrep' | lowercase | translate}}</a>
      <a [smNavigate]="getLink('faq')" class="link">{{'web.footer.faq' | lowercase | translate}}</a>
      <a [smNavigate]="getLink('om-os/levering')" class="link">{{'web.footer.daytoday' | lowercase | translate}}</a>
    </div>
    <div class="link-column">
      <div class="item">{{'web.footer.openinghours' | lowercase | translate}}</div>
      <div class="item">{{'web.footer.mon-turs' | lowercase | translate}}</div>
      <div class="item">{{'web.footer.friday' | lowercase | translate}}</div>
      <div class="item">{{'web.footer.weekend' | lowercase | translate}}</div>
      <div class="link"><a href="mailto:info@folkelarsen.dk">{{'web.footer.contactmail' | lowercase | translate}}</a></div>

    </div>
    <div class="link-column">
      <div class="item">{{'web.footer.companyname' | lowercase | translate}}</div>
      <div class="item">{{'web.footer.address' | lowercase | translate}}</div>
      <div class="item">{{'web.footer.addresstown' | lowercase | translate}}</div>
      <div class="link"><a style="text-decoration: none;"
          href="tel:+4543967577"></a>{{'web.footer.phoneno' | lowercase | translate}}</div>
      <div class="item">{{'web.footer.cvrno' | lowercase | translate}}</div>
      <a class="link" style="text-decoration: none;" target="_blank"
        href="https://www.linkedin.com/company/folke-larsen">{{'web.footer.linkedin' | lowercase | translate}}</a>
      <app-language-selector [ngStyle]="{
        marginTop: '5px'
      }"></app-language-selector>
    </div>
  </div>

</div>
<div class="copyright">
  Copyright © 2020 Folke-Larsen / <a
    [smNavigate]="getLink('cookiepolitik')">{{'web.footer.cookieanddatapolicy' | lowercase | translate}}</a>
</div>
