<ng-container *ngIf="!confirmCancel">
  <mat-dialog-content>
    <page-article [articleOptions]="data.article"></page-article>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button sitemule-button color="primary" [stroked]="true" [uppercase]="true"
      (click)="doReject()">{{data.rejectButtonText}}</button>
    <button sitemule-button color="success" [loading]="accepting" [uppercase]="true"
      (click)="doAccept()">{{data.acceptButtonText}}</button>
  </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="confirmCancel">
  <mat-dialog-content>
    <h2 class="confirm_reject_title">{{data.confimRejectTitle}}</h2>
    <p class="confirm_reject_description">{{data.confimRejectDescription}}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button sitemule-button color="primary" [stroked]="true" [uppercase]="true"
      (click)="undoReject()">{{data.undoRejectButtonText}}</button>
    <button sitemule-button color="success" [uppercase]="true"
      (click)="confirmReject()">{{data.confirmRejectButtonText}}</button>
  </mat-dialog-actions>
</ng-container>
