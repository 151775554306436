import { Directive, ElementRef, HostBinding, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appUiTable]'
})
export class UiTableDirective implements OnDestroy{
  domObserver;
  stickyObserver;
  @HostBinding('class')
  elementClass = 'ui-table sticky';
  @Input() set stickyTop(value: string) {
    this.elRef.nativeElement.style.top = value + 'px';
  }
  constructor(private elRef: ElementRef) {
    this.observeDomChanges(this.elRef.nativeElement);
  }
  observeDomChanges(elementRef: HTMLElement) {
     this.domObserver = new MutationObserver(() => {
      if (this.elRef.nativeElement.firstElementChild) {
        this.createStickyObserver(this.elRef.nativeElement.firstElementChild);
      }
    });
    this.domObserver.observe(elementRef, {
      childList: true,
    });
  }
  createStickyObserver(element: HTMLElement) {
    this.stickyObserver = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1),
      { threshold: [1] , rootMargin: '-50px 0px 100px 0px', root: null}
    );
    this.stickyObserver.observe(element)
  }
  ngOnDestroy(): void {
    this.domObserver ? this.domObserver.disconnect() : null;
    this.stickyObserver ? this.stickyObserver.disconnect() : null;
  }
}
