import {Component, ChangeDetectionStrategy, Output, EventEmitter} from '@angular/core';
import { IMenuItem } from '../../../../ui/components/menu/menu.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'webshop-catalog-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {
  showDropdown = false;
  @Output() outViewmodeChanged = new EventEmitter<'list' | 'grid'>();
  @Output() outSortOrderChanged = new EventEmitter<IMenuItem>();
  menuList: IMenuItem[] = [
    {label: 'Lavest pris'},
    {label: 'Højest pris'},
    {label: 'Alfabetisk A-Z'},
    {label: 'Alfabetisk Z-A'}];

  // {label: 'Popularitet'},

  buttonClicked(type: 'list' | 'grid') {
    this.outViewmodeChanged.emit(type);
  }

  sortOrderChanged(menuItem: IMenuItem) {
    this.outSortOrderChanged.emit(menuItem);
  }

  openDropdown() {
    this.showDropdown = true;
  }

  closeDropdown() {
    this.showDropdown = false;
  }
}
