import {Component, Input} from '@angular/core';

interface Document {
  url: string;
  title: string;
}

@Component({
  selector: 'ngx-sitemule-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent {
  @Input() documents : Document[] = [];
}

