import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ngx-sitemule-spare-part',
  templateUrl: './spare-part.component.html',
  styleUrls: ['./spare-part.component.scss'],
})
export class SparePartComponent {
  @Input() id: string;
  @Input() productNumber: string;
  @Input() vendorItemNo: string;
  @Input() inventory?: { inventoryStatus: 'IN_STOCK' | 'ON_THE_WAY' | 'SOLD_OUT'};
  @Input() price: number;
  @Input() lagerstatus?: string;
  @Input() title: string;
  @Input() description: string;
  @Input() label: string;
  @Input() vatText: string;
  @Input() link: string;


  @Output() addToBasketClick = new EventEmitter<string>();
  @Output() addToFavoriteListClick = new EventEmitter<string>();

  constructor() {}

  addToFavoriteList(evt: Event) {
    evt.stopPropagation();
    evt.preventDefault();
    this.addToFavoriteListClick.emit();
  }

  addToBasket(evt: Event) {
    evt.stopPropagation();
    evt.preventDefault();
    this.addToBasketClick.emit();
  }
}
