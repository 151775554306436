import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICatalog } from '../catalog/catalog.interface';

@Component({
	selector: 'webshop-catalog-mini',
	templateUrl: './catalog-mini.component.html',
	styleUrls: ['./catalog-mini.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogMiniComponent {
  sectionTitlte: string = "Vis flere produkter"
	showAll:boolean = false;
	shownCatalog: ICatalog;
	@Input()set catalog(catalog: ICatalog) {
		this._catalog = catalog;
		this.shownCatalog = this.reduceCatalog(this._catalog);
		this.showAll = false;
	};
	_catalog: ICatalog;
	@Input() type: 'grid' | 'list'  = 'grid';
	@Input() viewOptions: {
		maxShownItems:number;
		maxRows:number;
	}
	toggleShowAll(){
		this.showAll = !this.showAll;
		this.shownCatalog = this.showAll ? this._catalog : this.reduceCatalog(this._catalog);
	}
	reduceCatalog(catalog: ICatalog)
	{


		let catalogToShow = {...catalog};
		if(this.viewOptions?.maxShownItems)
			catalogToShow.items = catalogToShow.items.slice(0, this.viewOptions.maxShownItems);
		return catalogToShow;
	}
}

