import { CurrencyPipe, registerLocaleData } from '@angular/common';
import localeda from '@angular/common/locales/da';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DelayedHoverDirectiveModule } from "@sitemule/ng-components/directives/delayedHover";
import { MarkdownModule } from "@sitemule/ng-components/pipes/marked";
import { SafeSanitizerPipeModule } from "@sitemule/ng-components/pipes/safe-sanitizer";
import { TranslationKeyGeneratorPipeModule } from "@sitemule/utils/pipes/translation-key-generator";
import { DateFormat2Pipe } from "./pipes/date-format.pipe";
import { SitemuleCurrencyPipe } from './pipes/sitemule-currency/sitemule-currency.pipe';

registerLocaleData(localeda);

@NgModule({
  declarations: [
    SitemuleCurrencyPipe,
    DateFormat2Pipe,
  ],
  imports: [TranslateModule.forChild(), MarkdownModule, SafeSanitizerPipeModule, TranslationKeyGeneratorPipeModule, DelayedHoverDirectiveModule],
  exports: [
    SitemuleCurrencyPipe,
    DateFormat2Pipe,
  ],
  providers: [CurrencyPipe],
})
export class SitemuleCoreModule {}
