import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { SitemuleUiModule } from '../../ui/sitemule-ui.module';
import { MaterialModule } from '../modules/material.module';
import { AddressComponent } from './dialogs/address/address.component';
import { CopyOrderToBasketComponent } from './dialogs/copy-order-to-basket/copy-order-to-basket.component';
import { FirstPasswordDialogGuard } from './dialogs/first-password/first-password-dialog.guard';
import { FirstPasswordPageComponent } from './dialogs/first-password/first-password-page.component';
import { LogoutDialogGuard } from './dialogs/mat-login/logout-dialog.guard';
import { OrderConfirmationComponent } from './dialogs/order-confirmation/order-confirmation.component';
import { ReferenceComponent } from './dialogs/reference/reference.component';
import { CreateAccountPageComponent } from './pages/create-account/create-account-page.component';

@Component({
  template: ``,
})
export class EmptyComponent {
}

const routes: Routes = [
  {
    path: 'create',
    component: CreateAccountPageComponent
  },
  {
    path: 'logout',
    resolve: {
      doesntMatter: LogoutDialogGuard,
    },
    component: EmptyComponent,
  },
  {
    path: 'password/:ticket',
    resolve: {
      doesntMatter: FirstPasswordDialogGuard,
    },
    component: EmptyComponent,
  }];

@NgModule({
  declarations: [FirstPasswordPageComponent, AddressComponent, OrderConfirmationComponent, ReferenceComponent, CopyOrderToBasketComponent],
  imports: [CommonModule, RouterModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule, TranslateModule.forChild(), MaterialModule, MatDialogModule, SitemuleUiModule],
  exports: [],

  providers: [TranslatePipe]
})
export class AccountModule {
}



