import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, expand, map, scan, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WebshopService {
  searchLimit = 300;

  constructor(private http: HttpClient, private translateService: TranslateService) {
  }

  getContentBySeo(
    seoUrl: string,
    filterUrl?: string
  ): Observable<ICatalogHTTP | IProductHTTP> {
    const url = `/cms/prd/getContentBySeoUrl/${encodeURIComponent(seoUrl)}${filterUrl ? `?filter=${encodeURIComponent(filterUrl)}` : ''}`;
    return this.http.get<ICatalogHTTP | IProductHTTP>(url);
  }

  private catalogAndProductTeasersCache?: any;
  getCatalogAndProductTeasers() : Observable<any> {
    if (this.catalogAndProductTeasersCache) {
      return of(this.catalogAndProductTeasersCache);
    }
    return this.getContentBySeo('catalogandproductteasers').pipe(
      catchError(() => {
        return of()
      }),
      map((res: any) => {
        if (res.content) {
          delete res.content.header;
          delete res.content.description;
        }
        return {
          ...res,
          content: {
            ...res.content,
            items: res.content.items.map(item => {
              return {
                ...item,
                images: item.images.map(image => {
                  return {
                    ...image,
                    link: `/${this.translateService.currentLang}/${image.link}`
                  }
                })
              }
            })
          }
        };
      }),
      tap(res => {
        this.catalogAndProductTeasersCache = res;
      })
    )
  }

  doProductSearch(searchTerm: string, limit = 300, fast = true, productIds: string[] = []) {
    const data = {
      language: this.translateService.defaultLang,
      limit,
      pageno: 1,
      fast,
      productIds: productIds.join(','),
      search: searchTerm,
    };
    return this.http.post('/cms/prd/listProducts', data);
  }

  /**
   *
   * @param productNumbers array of product numbers to look up
   * @param useCache If we use our cache, or we go to navision for real data. EXPENSIVE
   *
   * @param sliceSize amount of products to take on each request. Taking less can prevent HTTP blockade on the server, when cache is on
   *
   * @returns observable with array of prices
   */
  getPricesAndInventory(productNumbers: string[], useCache = true, sliceSize = 1): Observable<GetPricesAndInventory[]> {
    const clonedProductNumbers = [...productNumbers];
    if (clonedProductNumbers.length == 0) {
      return of([]);
    }

    const data: PricesAndInventoryPayload = {
      customerPrice: true,
      inventory: true,
      lagerstatustekst: true,
      lagerstatus: true,
      useCache,
      products: clonedProductNumbers.splice(0, sliceSize)
    };
    return this.pricesAndInventoryHTTP(data).pipe(
      expand(() => {
        return clonedProductNumbers.length ? this.pricesAndInventoryHTTP({
          ...data,
          products: clonedProductNumbers.splice(0, sliceSize)
        }) : EMPTY;
      }),
      scan((acc, value) => [...value, ...acc])
    );
  }

  pricesAndInventoryHTTP(data: PricesAndInventoryPayload): Observable<GetPricesAndInventory[]> {
    return this.http.post<GetPricesAndInventory[]>('/cms/prd/listProductAdditionalInformation', data);
  }
}

interface Breadcrumb {
  title: string;
  seourl: string;
  type: string;
}

export interface Item {
  id: number;
  product_id: number;
  productNumber?: any;
  body?: string;
  inventory?: any;
  customerPrice?: any;
  lagerstatus?: any;
  seourl: string;
  leaf: number;
  imageUrl: string;
  label: string;
  items: any[];
}

export interface CatalogContent {
  title: string;
  filter?: any;
  breadcrumb: Breadcrumb[];
  items: Item[];
  totalRows?: number;
}

export interface ICatalogHTTP {
  type: PageType;
  content: CatalogContent;
}

// PRODUCT

export interface Property {
  name: string;
  label: string;
  value: string;
  prioritized?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Layout {
}

export interface Image {
  caption: string;
  title: string;
  type: string;
  url: string;
  layout: Layout;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Layout2 {
}

export interface Document {
  caption: string;
  title: string;
  type: string;
  url: string;
  layout: Layout2;
  prioritized: boolean;
}

export interface CatalogContent {
  product_id: number;
  productnumber: string;
  seourl: string;
  supplierdescription: string;
  unitprice: string;
  primarycatalogid: string;
  properties: Property[];
  title: string;
  metadescription: string;
  metakeywords?: string;
  description: string;
  images: Image[];
  documents: Document[];
}

export interface IProductHTTP {
  type: PageType;
  seourl: string;
  breadcrumb: Breadcrumb[];
  content: CatalogContent;
}

export type PageType = 'product' | 'catalog';

export interface PricesAndInventoryPayload {
  customerPrice: boolean;
  lagerstatustekst?:boolean;
  lagerstatus?:boolean;
  useCache: boolean;
  inventory: boolean;
  products: string[];
}

export interface CustomerPrice {
  salesPrice: number;
  discountPct: number;
  qty: number;
  unitCode: string;
  listPrice: number;
  discountAmount: number;
}

export interface Inventory {
  Key: string;
  productNumber: string;
  inventoryStatus: string;
}

export interface GetPricesAndInventory {
  lagerstatustekst?: string;
  lagerstatus?: 'IN_STOCK' | 'ON_THE_WAY' | 'SOLD_OUT';
  productNumber: string;
  customerPrice?: CustomerPrice;
  inventory: IInventory;
}

export interface IInventory {

  inventoryStatus: 'IN_STOCK' | 'ON_THE_WAY' | 'SOLD_OUT';
}
