<div class="ui-slider-container">
  <ui-slider [slides]="slides" [currentSlide]="currentImage">
    <div *ngFor="let slide of slides; let i=index" class="slide">
      <div class="image-container">
        <ng-container *ngIf="!slide.videoUrl; else youtube">
          <pinch-zoom style="height: 100%; width: 100%" [zoomControl]="'one-button'"
            [backgroundColor]="'rgba(0,0,0,0)'">
            <img [src]="slide.imageUrl" [alt]="slide.name ?? ''" />
          </pinch-zoom>
        </ng-container>
        <ng-template #youtube>
          <iframe class="youtube" *ngIf="currentImage === i" [src]="slide.videoUrl" frameborder="0" allow="autoplay;"
            allowfullscreen></iframe>
        </ng-template>
      </div>
    </div>
  </ui-slider>
</div>
<div class="thumbnails" *ngIf="slides && slides.length > 1">
  <div class="thumbnail" #thumbnail *ngFor="let slide of slides; let i=index" (click)="slideToIndex(i)"
    [class.active]="currentImage === i"
    [style.backgroundImage]="getBackgroundImage(slide.imageUrl,thumbnail) | safeSanitizer: 'style'"></div>
</div>
