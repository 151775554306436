// export interface MenuItemAbstract {
//   id: number;
//   title: string;
//   icon?: string;

// }

// export interface Anchor {
//   type: string;
//   url: string;
//   token: string;
//   target?: string;
// }

// export interface MenuItemLink extends MenuItemAbstract {
//   type: 'MENU_ITEM_LINK';
//   link: Anchor;
//   theme?: string;

//       // this.type = 'MENU_ITEM_BUTTON';
//       // switch (item.design) {
//       //   case 'primary':
//       //     this.theme = 'primary';
//       //     break;
//       //   case 'success':
//       //     this.theme = 'accent';
//       //     break;
//       //   default:
//       //     this.theme = 'primary';
//       //     console.warn(`MenuItemLink: Unknown theme '${item.design}'`);
//       //     break;
//       // }
// }

// export interface MenuItemGroup extends MenuItemAbstract {
//   type: 'MENU_ITEM_GROUP';
//   items: MenuItem[];
// }

// export type MenuItem = MenuItemGroup | MenuItemLink;

enum PageItemTypeEnum {
  ARTICLE = 'text',
  STACK = 'stack',
  MEDIAGALLERY = 'mediagallery',
  WIDGET = 'widgetloader',
  WEBCONTAINER = 'webcontainer',
  TEASER = 'teaser',
  TEASERSTACK = 'teaserstack',
  BRANCH = 'branch',
}

export interface Breadcrumb {
  title: string;
  seourl: string;
  type: string;
}
export interface DTOgetPage {
  content: PageItem;
  seourl: string;
  type: 'page';
}
//TODO clean up thiss mess of an interface
export interface PageItem {
  breadcrumb?: Breadcrumb[];
  type?: PageItemTypeEnum;
  content?: any;
  header?: string;
  id?: number;
  items?: PageItem[] | SlideItem[];
  title?: string;
  hasMenu?: boolean;
  seourl?: string;
  metakeywords?: string;
  metadescription?: string;
  subHeader?: string;
  pageMenu?: any;
}

export type LinkTarget = '' | 'blank';

export interface Teaser extends PageItem {
  title: string;
  composition: {
    autoScale: boolean; //Not using this. What is it??
    backgroundColor: string;
    border: boolean;
    borderColor: string;
    canvasImagePosition: 'side' | 'text'; //not really sure what this does, not using this
    inverse: boolean; //not using this. Why use inverse when you can just set the colors?
    textBackgroundColor: string;
  };
  link?: string;
  // subtitle: string;
  images: {
    caption: string;
    buttonText: string;
    layout: {
      icon: string;
      iconColor: string;
      autoScale: false; //what is this?
      backgroundcolor: string; //what background is this?
      buttonBackgroundColor: string;
      buttonPosition: Position;
      buttonTextColor: string;
      iconPosition: Position;
      imageposition: Position;
      inverse: boolean; //WHY?!?! not using this
      textBackgroundColor: string; //used as color on text.
      useImageAsBackground: boolean;
    };
    link: string;
    title: string;
    url: string;
    target: LinkTarget;
  }[];
  imageUrl: string;
}

export type Position = 'tl' | 'tc' | 'tr' | 'lc' | 'cc' | 'rc' | 'bl' | 'bc' | 'br';
export interface TeaserStack extends PageItem {
  composition: {
    backgroundcolor: string //background color behind the teasers. Use this in combination with gap to create fake border;
    borderColor: string //border for the whole teaser stack area;
    columns: number //number of columns;
    design?: 'rl'; //not relevant as of now
    fullPageWidth: boolean; //if element should be full width
    fullColorWidth: boolean;//full width for color, but content is centeret
    hasBorder: boolean; //not using this. if border color is set, border is shown
    hasPadding: boolean; //not using this
    rows: number; //number of rows
    // gap: number; NOT IMPLEMENTES, WE SHOULD DO THIS.
    shuffle: false; //used to shuffle order, and the amount of teasers shown. Used in combination with columns and rows
    teaserHeight?: number; //teaser minimum height
    textBackgroundColor: string; //background color for the header text.
    textColor: string; //color for the header text
    textPosition: string; //Can only choose top and bottom. useless as of now. needs to be left, center and right
  };
  description?: string;
  items: Teaser[];
}

export interface WebContainer extends PageItem {
  height: number;
  url: string;
}


export enum SlideItemTypeEnum {
  IMAGE = 'image',
}

export interface SlideItem {
  type: SlideItemTypeEnum;
  title: string;
  caption: string;
  target: string;
  url: string;
  link?: string;
  composition: {
    textAlignment: { vertical: string, horizontal: string };
    layout: string;
    textColor: string;
  };
  layout: any;
}

export interface MediaSlider extends PageItem {
  items: SlideItem[];
  composition: {
    height: number;
    autoSpeed: number;
    fullPageWidth?: boolean;
  };
}

export interface ArticleImage {
  title?: string;
  url?: string;
  layout: {
    snapToEdge?: boolean;
    css?: string;
    position?: ['left' | 'center' | 'right', 'top' | 'center' | 'bottom'];
  };
}

export interface Article extends PageItem {
  title: string;
  // subtitle: string;
  description: string;
  items: any[];
  composition: {
    layout: string;
    design: string;
    backgroundcolor?: string; // FIXME: change to backgroundColor, also needs to be changed in BE.
    inverse: boolean;
    fontColor?: string;
    fullPageWidth?: boolean;
    border: boolean;
    borderColor: string;
  };
  images?: ArticleImage[];
  call2action: any[];
}
export interface Link {
}

export interface Call2Action {
  ptype: string;
  behaviour: string;
  buttontext: string;
  target: string;
  link: {
    type: string;
    title: string;
    url: string;
    linktype: string;
  };
  theme: string;
  size: string;
  background: boolean;
  border: boolean;
  show_icon: boolean;
  icon: string;
  iconPosition: string;
}
export interface GlobalData {
  currentLanguage: string;
  languages: {
    key: string;
    text: string;
    imgUrl: string;
  }[];
  footer: {
    footerPageItem?: PageItem;
    address: any;
    sitemap: any;
  };
  pages: {
    contactUrl: string;
    cookiePolicyUrl: string;
  };
}

export interface Branch extends PageItem {
  title: string;
  contact: {
    address?: string;
    city?: string;
    country?: string;
    cvr?: string;
    email?: string;
    fax?: string;
    phone?: string;
    zipcode?: string;
  };
  items: any[];
}
export interface Widget extends PageItem {
  name: string;
  description?: string;
  form?: any;
  theme?: string;
}
export interface StackPage extends PageItem {
  items: PageItem[];
}
