import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BannerLayout1Module } from '@sitemule/banner/layout-1';
import { BannerLayout2Module } from '@sitemule/banner/layout-2';
import { CarouselModule } from '@sitemule/ng-components/components/carousel';
import { BasicArticleModule } from '../basic-article/basic-article.module';
import { TeaserStack2Module } from '../teaser-stack-2/teaser-stack-2.module';
import { PageComponent } from './page.component';


@NgModule({
  declarations: [
    PageComponent
  ],
  imports: [
    CommonModule,
    TeaserStack2Module,
    CarouselModule,
    BasicArticleModule,
    BannerLayout1Module,
    BannerLayout2Module,
  ],
  exports:[
    PageComponent
  ],
})
export class Stack2Module {
}
