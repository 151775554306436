import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap
} from 'rxjs/operators';
import { NotificationService } from '../../../../core/services/notification.service';
import { ListService } from '../../../services/list.service';
import { CreateListComponent } from '../create-list/create-list.component';

let previousSelectedList;

@Component({
  templateUrl: './add-to-list.component.html',
  styleUrls: ['./add-to-list.component.scss'],
})
export class AddToListComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

  selectListForm = new UntypedFormGroup({
    name: new UntypedFormControl(''),
  });
  selectedList;
  filteredLists$ = this.selectListForm.controls.name.valueChanges.pipe(
    startWith(''),
    debounceTime(500),
    distinctUntilChanged(),
    switchMap((query) =>
      this.listService.getGroupedList(query, 5).pipe(
        map((d: any) => {
          return d;
        })
      )
    )
  );

  constructor(
    public listService: ListService,
    private dialogRef: MatDialogRef<AddToListComponent>,
    private matDialog: MatDialog,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public dialogData : { productIds: string[] },
  ) {}

  ngOnInit(): void {
    if (previousSelectedList) {
      this.setList(previousSelectedList);
    }
  }

  createNewList() {
    const dialogRef = this.matDialog.open(CreateListComponent, {
      maxWidth: '100%',
      panelClass: 'small-dialog',
      autoFocus: false,
      data: {},
    });
    dialogRef.afterClosed().subscribe((s: any) => {
      if (s) {
        this.filteredLists$ =
          this.selectListForm.controls.name.valueChanges.pipe(
            startWith(''),
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((query) =>
              this.listService.getGroupedList(query, 5).pipe(
                map((d: any) => {
                  return d;
                })
              )
            )
          );
        this.setList(s);
      }
    });
  }

  setList(value) {
    previousSelectedList = value;
    this.selectedList = value;
    this.selectListForm.controls['name'].setValue(value);
  }

  selectList() {
    if (this.selectedList?.list_id) {
      this.listService
        .addProductToList(this.selectedList.list_id, this.dialogData.productIds)
        .subscribe((res: any) => {
          if (res[0]) {
            this.dialogRef.close();
            this.notificationService.show('Tilføjet til liste', 'success');
          } else {
            this.notificationService.show(
              'Fejl ved tilføjelse af produkt til liste',
              'error'
            );
          }
        });
    }
  }

  displayFn(list): string {
    return list.description;
  }

  submit() {
    if (this.selectListForm.invalid) {
      this.selectListForm.markAllAsTouched();
      return;
    }
    const data = {
      description: this.selectListForm.value.name,
      collection_id: this.selectListForm.value.type,
    };
    this.listService.createOrUpdateList('0', data).subscribe((res) => {
      this.dialogRef.close(res);
    });
  }
}
