import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'sitemule-user-mat-change-password',
  templateUrl: './mat-change-password.component.html',
  styleUrls: ['./mat-change-password.component.scss'],
})
export class SitemuleUserMatChangePasswordComponent implements OnInit {
  changePasswordForm: UntypedFormGroup;
  submitted: boolean;
  forgotPasswordForm: any;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      repeatPassword: ['', Validators.required],
    });
  }

  onSubmit() {
    // this.submitted = true;
    // if (this.changePasswordForm.valid && this.changePasswordForm.value.newPassword === this.changePasswordForm.value.repeatPassword) {
    //   this.userService.changePassword(this.changePasswordForm.value.oldPassword, this.changePasswordForm.value.newPassword).pipe(
    //     first()
    //   ).subscribe((res) => {
    //     if (res) {
    //     } else {
    //     }
    //   });
    // } else {
    // }
  }
}
