<ul class="breadcrumb" *ngIf="$breadcrumblist | async as breadcrumblist">
  <li
    *ngFor="let breadcrumb of breadcrumblist"
    [class.active]="selectedBreadcrumb === breadcrumb"
    [class.has-nested-items]="breadcrumb?.menu?.length > 0"
    (mouseenter)="onMouseEnter(breadcrumb, breadcrumblist)"
    [smDelayedHover]="200"
    (delayedHover)="selectedBreadcrumb = breadcrumb"
    (click)="selectedBreadcrumb = breadcrumb"
    (mouseleave)="selectedBreadcrumb = undefined">
    <a [smNavigate]="breadcrumb.full_seourl">{{breadcrumb.title}}</a>
    <i class="arrow icon-arrowright"></i>
    <ui-menu *ngIf="selectedBreadcrumb === breadcrumb &&  breadcrumb?.menu?.length > 0" [menuList]="breadcrumb.menu">
    </ui-menu>
  </li>
</ul>
