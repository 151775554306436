<div class="wrapper" *ngIf="teaserStack" [style.--columns]="teaserStack.composition?.columns"
  [style.--rows]="teaserStack.composition?.rows" [style.--background-color]="teaserStack.composition.backgroundcolor"
  [style.--border-color]="teaserStack.composition.borderColor" [style.--design]="teaserStack.composition.design"
  [style.--padding]="teaserStack.composition.hasPadding ?  '10px' : '0'"
  [style.--teaser-height]="teaserStack.composition.teaserHeight"
  [style.--text-background-color]="teaserStack.composition.textBackgroundColor"
  [style.--text-color]="teaserStack.composition.textColor"
  [style.--text-position]="teaserStack.composition.textPosition">
  <div class="fake-background-color" *ngIf="!!teaserStack.composition.fullColorWidth"></div>
  <div class="teaser-stack" [class.project-content-margin]="!teaserStack.composition.fullPageWidth">
    <div class="header" *ngIf="teaserStack.header || teaserStack.description">
      <h2 *ngIf="teaserStack.header">{{teaserStack.header}}</h2>
      <p class="content" *ngIf="teaserStack.description" [innerHTML]="teaserStack.description | marked"></p>
    </div>
    <ngx-sitemule-teaser [teaser]="teaser" *ngFor="let teaser of teasers">
    </ngx-sitemule-teaser>
  </div>
</div>
