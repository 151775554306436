import {
  linkMe, sanitizeUrl
} from '@sitemule/ngx-sitemule';
import * as marked from 'marked';
import { sanitizeBanner } from './sanitizeBanner';
import { sanitizeTeaserStack2 } from './sanitizeTeaserStack2';

const fixLinkInMarkdown = (markdownText: string, availableLanguages: string[], defaultLang: string) => {
  let m = markdownText || '';

  m.match(/\[[^\]]+\]\([^\)]+\)/g)?.forEach(link => {
    const splittedLink = link.match(/\(([^\)]+)\)$/);

    if (splittedLink && splittedLink.length && splittedLink[1]) {
      const newLink = sanitizeUrl(splittedLink[1], defaultLang, availableLanguages);
      m = m.replace(link, link.replace(splittedLink[1], newLink));
    }
  });

  return m;
}

export const sanitizeContent = (content: any, availableLanguages: string[], defaultLang: string) => {
  if (content.contentType === 'teaserstack2' || content.contentType === 'newteaserstack2') {
    return sanitizeTeaserStack2(content, availableLanguages, defaultLang);
  }
  if (content.contentType === 'stack2') {
    const backgroundImage = content.properties.images;
    const backgroundColor = content.properties.page_theme.backgroundColor;
    return {
      contentType: 'stack2',
      header: content.properties.header,
      backgroundImage: backgroundImage ? `url('${backgroundImage}')` : undefined,
      backgroundImagePosition: content.properties.page_layout.backgroundImagePosition,
      backgroundImageSize: content.properties.page_layout.backgroundImageSize,
      backgroundColor,
      style: {
        paddingTop: content.properties.header && (backgroundImage || backgroundColor) ? '30px' : ''
      },
      items: content.items.map(i => sanitizeContent(i, availableLanguages, defaultLang))
    };
  }
  if (content.contentType === 'banner') {
    return sanitizeBanner(content, availableLanguages, defaultLang);
  }
  if (content.contentType === 'carousel') {
    return {
      ...content,
      height: content.properties.page_layout.height,
      items: content.items.map(i => sanitizeBanner(i, availableLanguages, defaultLang)).map(i => ({
        ...i,
        height: '100%'
      }))
    };
  }
  if (content.contentType === 'stack') {
    return {
      ...content,
      items: content.items.map(i => sanitizeContent(i, availableLanguages, defaultLang))
    };
  }
  if (content.contentType === 'text2') {
    return {
      ...content,
      header: content.header,
      content: marked.parse(content.description),
    };
  }
  if (content.contentType === 'mediagallery') {
    return {
      ...content,
      items: content.items.map(item => {
        return {
          ...item,
          caption: fixLinkInMarkdown(item.caption, availableLanguages, defaultLang),
          link: item.link ? linkMe(item.link, defaultLang, availableLanguages) : item.link,
        }
      })
    };
  }
  if (content.contentType === 'text') {
    return {
      ...content,
      description: fixLinkInMarkdown(content.description, availableLanguages, defaultLang),
      call2action: content.call2action.map(item => {
        return {
          ...item,
          href: linkMe(item.link, defaultLang, availableLanguages),
        }
      })
    };
  }
  if (content.contentType === 'teaserstack') {
    return {
      ...content,
      items: content.items.map(item => {
        return {
          ...item,
          images: item.images.map(image => {
            return {
              ...image,
              link: image.link ? linkMe(image.link, defaultLang, availableLanguages) : image.link
            }
          })
        }
      })
    };
  }

  return content;
};
