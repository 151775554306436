<ngx-loading
  *ngIf="loadingCatalog$ | async"
  [ngStyle]="{
    position: 'relative',
    display: 'block'
  }"
  [config]="{
    primaryColour: 'var(--primary-color)',
    secondaryColour: 'var(--primary-color)',
    tertiaryColour: 'var(--primary-color)',
    backdropBackgroundColour: 'transparent',
    backdropBorderRadius: '3px'
  }"
  [show]="true">
</ngx-loading>
<div *ngIf="pageType" class="project-content-margin">
 <ng-container [ngSwitch]="pageType">
    <ng-container *ngSwitchCase="'catalog'">
      <webshop-catalog-entry>
      </webshop-catalog-entry>
    </ng-container>
    <ng-container *ngSwitchCase="'product'">
      <webshop-product-entry></webshop-product-entry>
    </ng-container>
    <ng-container *ngSwitchDefault>
      Not found
    </ng-container>
  </ng-container>
  <!-- TODO: Remove when we got some site settings.-->
  <div class="wshteaserstack" *ngIf="teaserStack$ | async as teaserStack">
    <page-teaser-stack [teaserStack]="teaserStack?.content">
    </page-teaser-stack>
  </div>
</div>
