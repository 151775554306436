<app-header #headerComponent [$userLoggedIn]="$user" [activeUrl]="$activeUrl | async" [primaryMenu]="$mainNavigation | async" [secondaryMenu]="$secondaryNavigation | async"></app-header>
<div class="main-navigation">
  <sm-navigation class="project-content-margin" [items]="$mainNavigation | async"></sm-navigation>
</div>
<div class="content" [class.loggedin]="($loggedin | async) === true">
  <div class="project-content-margin">
    <div class="topbar" [style.display]="hideTopbar ? 'none' : 'flex'">
      <ui-breadcrumb>
      </ui-breadcrumb>

      <div class="search-container">
        <form #form="ngForm" (ngSubmit)="searchProduct(input.value); input.value = '';">

          <div class="ui-input-icon-group">
            <i class="material-icons-outlined">
              search
            </i>
            <input #searchEl type="text" #input
              [placeholder]="'web.webshop.searchinputplaceholder' | lowercase | translate" name="search">
          </div>
        </form>

      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
<button *ngIf="$showScrollButton | async" class="floating-button" (click)="scrollTop()"><i class="icon-arrowup"></i>
</button>
