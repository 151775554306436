import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { NotificationService } from '../../../../core/services/notification.service';
import { ListService, SingleList } from '../../../services/list.service';
import { CreateListComponent } from '../../dialog/create-list/create-list.component';
import { EditListComponent } from '../../dialog/edit-list/edit-list.component';

@Component({
  templateUrl: './list-overview-page.component.html',
  styleUrls: ['./list-overview-page.component.scss'],
})
export class ListOverviewPageComponent {
  constructor(
    private matDialog: MatDialog,
    private listService: ListService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
  ) {}

  searchForm = new UntypedFormGroup({
    search: new UntypedFormControl(''),
  });

  private searchText$ = this.searchForm.valueChanges.pipe(
    startWith(undefined),
    map(v => {
      return v?.search || '';
    })
  );
  private refetch$ = new BehaviorSubject(1);
  private listType$ = this.activatedRoute.paramMap.pipe(map(p => p.get('listType')));
  sortBy$ = new BehaviorSubject('overskrift');
  sortDir$ = new BehaviorSubject<'desc' | 'asc'>('desc')
  data$ = combineLatest({
    refetch: this.refetch$,
    listType: this.listType$,
    searchText: this.searchText$,
    sortBy: this.sortBy$,
    sortDir: this.sortDir$,
  }).pipe(
    debounceTime(100),
    switchMap(({ listType, searchText, sortBy, sortDir }) => {
      return this.listService.getListsBasedOnListTypes(
        searchText,
        listType
      ).pipe(
        map((items: any) => {
          const clonedItems = [...items];
          clonedItems.sort((a, b) => {
            const [propertyA, propertyB] = (() => {
              if (sortBy === 'overskrift') {
                return [a.description, b.description];
              }
              if (sortBy === 'dato') {
                return [a.properties?.date, b.properties?.date];
              }
              return [];
            })();

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
            return (valueA < valueB ? -1 : 1) * (sortDir == 'asc' ? 1 : -1);
          });
          return clonedItems;
        })
      )
    }));

  reFetchSearchForm() {
    this.refetch$.next(this.refetch$.getValue() + 1);
  }

  createList() {
    const dialogRef = this.matDialog.open(CreateListComponent, {
      maxWidth: '100%',
      panelClass: 'small-dialog',
      data: {},
    });
    dialogRef.afterClosed().subscribe((s: SingleList) => {
      if (s) {
        this.openList(s);
      }
    });
  }

  exportList(list: SingleList) {
    window.open(
      `${location.protocol}//${location.host}/cms/cmn/createexcel?payload={"entity":"list","reportType" : "list","id" : ${list.list_id}}`
    );
  }

  deleteList(list: SingleList) {
    this.listService.deleteList(list).subscribe(() => {
      this.reFetchSearchForm()
    });
  }
  sortList(sortBy) {
    const currentSortBy = this.sortBy$.getValue();
    const currentSortDir = this.sortDir$.getValue();

    if (currentSortBy !== sortBy) {
      this.sortBy$.next(sortBy);
      this.sortDir$.next('desc');
    }
    else {
      if (currentSortDir === 'desc') {
        this.sortDir$.next('asc');
      }
      else {
        this.sortDir$.next('desc');
      }
    }
  }

  printList(list: SingleList) {
    this.listService.printList(list.list_id);
  }
  printQRList(list: SingleList) {
    this.listService.printListAsInfoCards(list.list_id);
  }
  openList(list: SingleList) {
    const dialogRef = this.matDialog.open(EditListComponent, {
      panelClass: 'ui-dialog',
      position: { right: '0', top: '0', bottom: '0' },
      maxHeight: 'none',
      maxWidth: 'none',
      data: {
        listId: list.list_id,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.reFetchSearchForm()
    });
  }

  copyListToBasket(list: SingleList) {
    this.listService.copyListToBasket(`${list.list_id}`)
      .subscribe(() => {
        this.notificationService.show('Added to basket', 'success');
      });
  }
}
