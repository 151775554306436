import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CustomerComponent } from './customer/customer.component';
import { FavoritListComponent } from './components/favorit-list/favorit-list.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SitemuleUiModule } from '../../ui/sitemule-ui.module';



@NgModule({
  declarations: [ CustomerComponent, FavoritListComponent, ProfileComponent],
  imports: [
    CommonModule,
    RouterModule,
    SitemuleUiModule
  ],
  exports: [CustomerComponent, FavoritListComponent]
})
export class BaseModule { }
