<ng-container *ngIf="!bambora">
  <h3>{{"web.order.confirmation" | translate}}</h3>
</ng-container>
<ng-container *ngIf="bambora">
  <br>
  <h3>Dette er en Demo - Kortbetaling er ikke understøttet endnu.</h3>
  <div #payel style="height: 430px ;width:500px; margin: auto">
  </div>
</ng-container>

