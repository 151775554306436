import {
  ChangeDetectionStrategy, Component, Input
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BasketDialogService, MenuItemV2, MenuService, UserService } from '@sitemule/ngx-sitemule';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() activeUrl: string;
  @Input() primaryMenu: MenuItemV2[];
  @Input() secondaryMenu: MenuItemV2[];
  @Input() $userLoggedIn;
  showDropdown = false;
  showDropdownMenu$ = this.menuService.getMenuBySystemPropertyName('user_menu_mini');
  isRedBarClosed = true;
  isBurgerOpen = false;

  workAsClientOrCustomer$ = this.userService.currentSession$.pipe(map(sess => {
    return sess.workAsClient || sess.workAsCustomer;
  }))

  constructor(
    private userService: UserService,
    private basketDialogService: BasketDialogService,
    private menuService: MenuService,
    private translate: TranslateService,
  ) {}

  openBasket() {
    this.basketDialogService.openBasket();
  }

  loginDialog() {
    this.userService.showLoginDialog({ returnUrl: `/${this.translate.defaultLang}/user` });
  }

  closeInfoBar() {
    this.isRedBarClosed = true;
  }

  get mobileMenu() {
    return [...(this.primaryMenu || []), ...(this.secondaryMenu || [])];
  }
}
