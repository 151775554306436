import {
  ChangeDetectionStrategy,
  Component, HostBinding,
  Input,
  OnChanges, SimpleChanges
} from '@angular/core';
import { ICatalogItem } from '../catalog-item/catalog-item.interface';
import { ICatalog } from './catalog.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'webshop-catalog',
  templateUrl: './catalog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent implements OnChanges {
  @Input() disableActions = false;
  @Input() catalog: ICatalog;
  @Input() type: 'grid' | 'list' = 'grid';
  @Input() fetchInventoryDetails = false;
  @HostBinding('class') class;
  expandCatalogItems: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    this.class = this.type;
    if (changes.catalog && changes.catalog.currentValue) {
      this.expandCatalogItems = this.setExpanded();
    }
  }

  setExpanded(): boolean {
    return this.catalog.items?.some((p) => p.price || (p.body && p.label));
  }

  trackBy(_index: number, item: ICatalogItem) {
    return item.seourl;
  }
}
