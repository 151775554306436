<ng-container *ngIf="basket$ | async as basket">
<div class="basket-page">
  <div class="project-content-margin" style="display: flex; justify-content: space-between;">
    <div class="header">
      <h4>{{'web.order.baskettotalprice' | translate }} {{(basket.totalAmount || 0)| sitemuleCurrency:
        'financial'}}</h4>
      <h3 style="font-weight: 400;">{{'web.order.orderbeforeandgettomorrow' | translate}}</h3>
      <h3 *ngIf="basket.isDeliveryForFree == false && basket.missingAmountForFreeDelivery != 0">
        {{'web.order.orderforadditionalandgetfreedelivery' | translate}}
        {{basket.missingAmountForFreeDelivery| sitemuleCurrency: 'financial' }}
      </h3>
      <a routerLink="/da/products">{{'web.order.continueshopping' | translate }}</a>
    </div>
  </div>
  <div class="buyer-info">
    <div class="project-content-margin">
      <div class="info-box ">
        <b>{{'web.order.billingaddress' | translate }}</b>
        <p>{{basket.billing.ame}}</p>
        <p>{{basket.customerName}}</p>
        <p>{{basket.billing.address1}}</p>
        <p>{{basket.billing.zipcode}} {{basket.billing.city}}</p>
      </div>
      <div class="info-box">
        <b>{{'web.order.deliveryaddress' | translate }}:
          <a [matMenuTriggerFor]="menu" style="cursor: pointer;"><span class="material-icons-outlined">
              edit
            </span></a>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let address of addresses$ | async"
                    (click)="editAddress(address)">{{address.name}}, {{address.address_1}}</button>
            <button mat-menu-item (click)="editAddress()"><b
              style="display: flex; justify-content: space-between; align-items: center;">{{'web.order.createnewaddress'
              | translate }}
              <i class="icon-plus" style="font-size: 2rem;"></i></b></button>
          </mat-menu>
        </b>
        <p>{{basket.delivery.name}}</p>
        <p>{{basket.delivery.address1}}</p>
        <p>{{basket.delivery.zipcode}} {{basket.delivery.city}}</p>
      </div>
      <div class="info-box"><b>{{'web.order' | translate }}:
        <a (click)="editReference()" style="cursor: pointer;"><span class="material-icons-outlined">
              edit
            </span></a>
      </b>
        <p *ngIf="currentSession$ | async as currentsession">{{'web.user.customernumber' | translate }}:
          {{currentsession.customer?.erp_id}}
        </p>
        <p>{{'web.order.deliverydate' | translate }}: {{basket.deliveryDate | date: 'dd-MM-yyyy'}}</p>
        <p>{{'web.order.yourordernumber' | translate }}: {{basket.id}}</p>
        <p>{{'web.order.yourreference' | translate }}: {{basket.reference}}</p>
        <p>{{'web.order.comment' | translate }}: {{basket.deliveryRemark}}</p>
      </div>
    </div>
  </div>
  <div class="basket project-content-margin">
    <form class="input" [formGroup]="searchProductForm">
      <div class="ui-input-combined basket-search">
        <input type="text" placeholder="{{'web.order.searchandadditemstobasket' | translate }}" class="ui-input search"
               formControlName="product" [matAutocomplete]="auto">
        <mat-spinner *ngIf="filteredProductsLoading$ | async" class="basket-search-spinner" diameter="20" color="primary"></mat-spinner>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="addProduct()">
          <mat-option *ngFor="let product of filteredProducts$ | async" [value]="product"
                      [disabled]="(product.customerPrice.salesPrice || product.price) == '0'">
            {{product.label}}
          </mat-option>
        </mat-autocomplete>
        <!--        <input type="number" step="1" class="ui-input amount" value="1" min="1" formControlName="amount">-->
        <!--        <button class="ui-button"  style="font-size: 1.4em;">{{'web.add' | translate }}</button>-->
      </div>
      <div style="flex: 1;">
      </div>
      <button style="float:right; margin-right: 20px" type="button" class="ui-button" (click)="favoriteLists()"><i
        class="icon-star"></i>{{'web.order.favoritelists' | translate
        }}</button>
      <button style="float:right;" class="ui-button" type="button" (click)="emptyBasket()">{{'web.order.emptybasket' |
        translate
        }}</button>
    </form>
    <div class="list-action" style="display: none;">
      <div>
        <i class="icon-star-outline"></i>
        {{'web.order.basket.addfromfavoritlists' | translate}}
      </div>
      <div>
        <i class="icon-star-outline"></i>
        {{'web.order.basket.addtofavoritlist' | translate}}
      </div>
    </div>
    <user-pricelist (outUpdate)="updateOrderLine($event)" (outRemove)="removeFromBasket($event)"
                    [readonly]="false" [basket]="basket"></user-pricelist>
    <div class="price">
      <div class="content">
        <div class="error" *ngIf="aggreementForm.errors" style="font-size: 16px; font-weight: bold; color: red;">
          {{aggreementForm.errors.missingItems}}
        </div>
        <div class="accept-payment">
          <div [formGroup]="aggreementForm">

            <div class="final-details">
              <div class="ui-input-form">
                <div class="label">{{'web.order.deliverydate' | translate }} </div>
                <input type="date" formControlName="delivery_date" [min]="mindate | date:'yyyy-MM-dd'"/>
              </div>
              <div class="ui-input-form">
                <div class="label">{{'web.order.yourreference' | translate }} (Max 35 tegn)</div>
                <input formControlName="reference" maxlength="35">
              </div>
              <div class="ui-input-form">
                <div class="label">{{'web.order.comment' | translate }} (Max 80 tegn)</div>
                <input formControlName="delivery_remark" maxlength="80" name="" id="" rows="2">
              </div>
              <div class="ui-input-form" style="grid-column: span 3;">
                <div class="label">{{'web.order.deliveryaddress' | translate }}:</div>
                <select [formControl]="addressFormControl" (ngModelChange)="updateAddress($event)">
                  <option [value]="address.address_1" *ngFor="let address of addresses$ | async">
                    {{address.name}}
                    {{address.address_1}}
                    {{address.zipcode}} {{address.city}}
                  </option>
                  <option value="create_new_address">{{'web.order.createnewaddress' | translate }}</option>
                </select>
              </div>
              <div class="ui-input-form" formGroupName="properties" style="grid-column: span 2;">
                <div class="ui-input" style="display: flex; grid-area: middle;">
                  <input type="checkbox" name="" style="width: auto; flex:0; margin-right: 10px;"
                         formControlName='collectAtCompany'>
                  <div class="label">{{ 'web.order.collectatfle' | translate }}</div>
                </div>
              </div>
            </div>
            <div style="text-align: right; " formGroupName="properties">
              <label for="iaccept" class="label">
                {{'web.order.iaccept' | translate }}
                <a *ngIf="termsAndConditinPageId$ | async as termsAndConditinPageId; else labelWithoutLink" routerLink="/{{termsAndConditinPageId}}">{{'web.order.salesanddeliveryterms' | translate }}</a>
                <ng-template #labelWithoutLink>
                  {{'web.order.salesanddeliveryterms' | translate }}
                </ng-template>:
              </label>
              <input type="checkbox" class="ui-input-checkbox" style="margin-right:10px;" name="iaccept" id="iaccept"
                     formControlName='acceptSalesAndDeliveryTerms'>
              <button class="ui-button success" [disabled]="basketLoading$ | async" (click)="postOrder()">{{'web.order.confirmandpayorder' |
                translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-container>
