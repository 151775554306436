<div class="catalog-container {{ type }}" *ngIf="catalog && catalog.items" >
      <webshop-catalog-item
        *ngFor="let catalogItem of catalog.items; trackBy:trackBy"
        [type]="type"
        [catalogItem]="catalogItem"
        [disableActions]="disableActions"
        [fetchInventoryDetails]="fetchInventoryDetails"
        [expanded]="expandCatalogItems">
      </webshop-catalog-item>
</div>
