import { formatDate } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, first, map, share, switchMap, tap } from 'rxjs/operators';
import { Status } from '../../../core/basket/basket.interfaces';
import { BasketService, IBasket, IBasketItem } from '../../../core/basket/basket.service';
import { SiteConfigServiceV2 } from '../../../core/services/site-config.service';
import { SparePartsService } from "../../../core/services/spareparts.service";
import { WebshopService } from '../../../core/services/webshop.service';
import { AddToListComponent } from '../../list/dialog/add-to-list/add-to-list.component';
import { Address } from '../../models/User';
import { UserService } from '../../services/sitemule-user.service';
import { AddressComponent } from '../../user/dialogs/address/address.component';
import { OrderConfirmationComponent } from '../../user/dialogs/order-confirmation/order-confirmation.component';
import { ReferenceComponent } from '../../user/dialogs/reference/reference.component';

@Component({
  templateUrl: './basket-page.component.html',
  styleUrls: ['./basket-page.component.scss'],
})
export class BasketPageComponent implements OnInit, OnDestroy {
  mindate = new Date();
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  currentSession$ = this.userService.getCurrentSession();
  addresses$ = this.userService.getAddresses().pipe(share());
  termsAndConditinPageId$ = this.siteConfigService.$config.pipe(map(s => s.terms_and_condition_article_seourl));

  addressFormControl = new UntypedFormControl('', );
  aggreementForm = new UntypedFormGroup({
    properties: new UntypedFormGroup({
      acceptSalesAndDeliveryTerms: new UntypedFormControl('', Validators.requiredTrue),
      useCreditCard: new UntypedFormControl(''),
      collectAtCompany: new UntypedFormControl(''),
    }),
    delivery_date: new UntypedFormControl(new Date()),
    reference: new UntypedFormControl('',[Validators.required]),
    delivery_remark: new UntypedFormControl(),
  });
  searchProductForm = new UntypedFormGroup({
    product: new UntypedFormControl(''),
    amount: new UntypedFormControl(1, [Validators.min(1)]),
  });


  filteredProductsLoading$ = new BehaviorSubject(false);
  filteredProducts$ = this.searchProductForm.controls.product.valueChanges.pipe(
    debounceTime(750),
    distinctUntilChanged(),
    filter((query: string) => query?.length > 2),
    tap(() => {
      this.filteredProductsLoading$.next(true);
    }),
    switchMap((query) =>
      forkJoin([
        this.webshopService.doProductSearch(query, 5, true).pipe(
          map((d: any) => {
            return d.items;
          })
        ),
        this.sparePartsService.fetchSpareParts({
          search: query,
          language: this.translate.defaultLang,
          limit: 5,
          pageno: 1,
        }).pipe(map(spareparts => {
          return spareparts.items.map(item => {
            return {
              label: item.title,
              customerPrice: {
                salesPrice: item.price
              },
              price: item.price,
              product_id: item.productId,
              imageUrl: item.imageUrl,
              productNumber: item.productNumber,
              seourl:item.seourl,
              seo_url_id: item.seoUrlId_DONOT_USE_THIS,
              quantity: 1,
            }
          })
        }))
      ])
      .pipe(map(([products, spareparts]) => [...products, ...spareparts]))
      .pipe(tap(() => {
        this.filteredProductsLoading$.next(false);
        this.trigger.openPanel();
      }))
    )
  );

  basket$ = this.basketServicev2.basket$;
  basketLoading$ = this.basketServicev2.basketLoading$;

  subs: Subscription[] = [];

  constructor(
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private userService: UserService,
    private basketServicev2: BasketService,
    private webshopService: WebshopService,
    private siteConfigService: SiteConfigServiceV2,
    private sparePartsService: SparePartsService,
  ) {}

  ngOnInit(): void {
    this.initializeAggrementUpdater();
    this.subs.push(this.basketServicev2.basket$.subscribe(basket => {
      if (!basket) {
        return;
      }
      // if order_date before today, overwrite. TODO: Should be handled service side.
      const checkDate = new Date(basket.deliveryDate);
      const todayDate = new Date();
      const deliveryDate = checkDate < todayDate ? formatDate(todayDate, 'yyyy-MM-dd', 'en-US') : basket.deliveryDate;

      this.aggreementForm.patchValue({
        properties: {
          acceptSalesAndDeliveryTerms: basket.agreement.acceptSalesAndDeliveryTerms ,
          useCreditCard: basket.agreement.useCreditCard,
          collectAtCompany: basket.agreement.collectAtCompany,
        },
        delivery_date:  deliveryDate,
        reference: (() => {
          if (this.aggreementForm.dirty && this.aggreementForm.controls.reference.value !== basket.reference) {
            return this.aggreementForm.controls.reference.value;
          }
          return basket.reference;
        })(),
        delivery_remark: (() => {
          if (this.aggreementForm.dirty && this.aggreementForm.controls.delivery_remark.value !== basket.deliveryRemark) {
            return this.aggreementForm.controls.delivery_remark.value;
          }
          return basket.deliveryRemark;
        })(),
      }, { emitEvent: false });

      if (
        basket.delivery.address1 &&
        basket.delivery.address1 != this.addressFormControl.value
      ) {
        this.addressFormControl.patchValue(basket.delivery.address1, {
          emitEvent: false,
          onlySelf: true,
        });
      }
    }));
  }

  openConfirmDialog(basket: IBasket) {
    const dialogRef = this.dialog.open(OrderConfirmationComponent, {
      data: { basket },
    });
    if (!basket.agreement.useCreditCard) {
      dialogRef.afterClosed().pipe(first()).subscribe(() => {
        this.router.navigate([`/${this.translate.currentLang}/user/orders`],
          {
            queryParams: {
              activeOrderId: basket.id
            },
          });
      });
    }
  }

  postOrder() {
    this.basket$.pipe(first()).subscribe(basket => {
      if (basket.items.length === 0) {
        this.aggreementForm.setErrors({
          missingItems: this.translate.instant('web.order.missingitemsinbasket'),
        });
      }
      else if(this.aggreementForm.invalid) {
        this.aggreementForm.markAllAsTouched();
      }
      else if(!this.addressFormControl.valid) {
        this.aggreementForm.markAllAsTouched();
      }
      else if(basket.status === Status.ordered) {
        throw new Error('Invalid order');
      }
      else {
        this.openConfirmDialog(
          basket
        );
      }
    });
  }


  displayFn(product): string {
    return product.label;
  }

  emptyBasket() {
    this.basketServicev2.emptyBasket().subscribe();
  }

  removeFromBasket(orderLine: IBasketItem) {
    this.basketServicev2
      .removeItemFromBasket(orderLine.id)
      .subscribe();
  }

  initializeAggrementUpdater() {
    this.subs.push(this.aggreementForm.valueChanges
      .pipe(
        debounceTime(1000),
        switchMap(({ delivery_date, delivery_remark, reference, properties: { acceptSalesAndDeliveryTerms, collectAtCompany, useCreditCard}}) => {
          return this.basketServicev2.updateBasket({
            deliveryDate: delivery_date,
            deliveryRemark: delivery_remark,
            reference,
            agreement: {
              acceptSalesAndDeliveryTerms,
              collectAtCompany,
              useCreditCard,
            }
          });
        })
      )
      .subscribe());
  }

  updateOrderLine({ basketLine, newQuantity }) {
    this.basketServicev2.updateItemInBasket({
      id: basketLine.id,
      quantity: newQuantity
    }).subscribe();
  }

  updateAddress(addres_1: string) {
    if (addres_1 === 'create_new_address') {
      this.editAddress();
    }
    else {
      this.addresses$.pipe(first()).subscribe((addresses) => {
        const address = addresses.find((a) => a.address_1 == addres_1);
        if (address != undefined && address != null) {
          this.setAddress(address);
        }
      });
    }
  }

  setAddress(address: Address) {
    this.basketServicev2.updateBasket({
      deliveryAddress: {
        name: address.name,
        address1: address.address_1,
        address2: address.address_2,
        zipcode: address.zipcode,
        city: address.city,
        country: address.country_code,
        phoneNumber: address.phone,
      }
    }).subscribe();
  }

  addProduct() {
    const formVal = this.searchProductForm.value;
    if (!formVal.product || !formVal.product.product_id) {
      return;
    }

    const product = formVal.product;
    this.basketServicev2.addItemToBasket({
      image: product.imageUrl,
      title: product.label,
      productId: product.product_id,
      productNumber: product.productNumber,
      productSEOUrl:product.seourl,
      productSEOUrlId_DONOT_USE_THIS: product.seo_url_id,
      quantity: 1,
      pricePerUnit: product.customerPrice.salesPrice,
    }).subscribe();
    this.searchProductForm.reset({ product: '', amount: 1 });
  }

  favoriteLists() {
    this.basket$.pipe(first()).subscribe(basket => {
      this.dialog.open(AddToListComponent, {
        maxWidth: '100%',
        panelClass: 'small-dialog',
        autoFocus: false,
        data: {
          productIds: basket.items.map(({ productSEOUrlId_DONOT_USE_THIS }) => productSEOUrlId_DONOT_USE_THIS)
        }
      });
    })
  }

  editAddress(address?) {
    const ref = this.dialog.open(AddressComponent, {
      panelClass: 'ui-dialog',
      width: '78%',
      maxWidth: '700px',
      data: address,
      height: '100vh',
      position: { right: '0', top: '0', bottom: '0' },
    });
    ref.afterClosed().pipe(first()).subscribe(() => {
      this.addresses$ = this.userService.getAddresses().pipe(share());
    });
  }

  editReference() {
    this.dialog.open(ReferenceComponent, {
      panelClass: 'ui-dialog',
      width: '400px',
      height: '100vh',
      position: { right: '0', top: '0', bottom: '0' },
    });
  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
