import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isFullPath } from '@sitemule/ng-components/directives/navigation';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

/**
 * @deprecated Use MenuService instead
 */
@Injectable({
  providedIn: 'root'
})
export class CoreMenuService {

  constructor(public http: HttpClient) {
  }

  /**
   * Used to get navigation menus. Strips it from the root element, and returns the items.
   *
   * @todo make it GET
   * @param menuId is the name of the menu
   *
   */
  // getMenu(menuId: string): Observable<MenuItem[]>{
  //   return this.http.post<IListMenuDTO[]>('/cms/cms/listMenus', {menu:menuId}).pipe(map(s => s[0].items));
  // }
  //
  getMenu(menuId: string): Observable<MenuItem[]> {
    return this.http.post<IListMenuDTO[]>('/cms/cms/listMenus', {menu: menuId}).pipe(map(s => s[0].items), shareReplay(1));
  }

  getClientMenu() {
    return this.getMenu('user_menu_root').pipe(shareReplay(1));
  }

  getClientMenuMini() {
    return this.getMenu('user_menu_mini').pipe(shareReplay(1));
  }

}

export interface MenuItem {
  fcid: string;
  url: string;
  title: string;
  icon: string;
  items?: MenuItem[];
  show_icon: number;
  Design: string;
}

interface IListMenuDTO {
  fcid: string;
  title: string;
  icon: string;
  show_icon: number;
  Design: string;
  items: MenuItem[];
}

export const parseUrl = (url: string, available_languages: string[]) : {
  pageType: 'product' | 'user' | 'external' | 'page';
  seoUrl?: string;
  url?: string;
} => {
  // external url
  if (isFullPath(url)) {
    return {
      pageType: 'external',
      url,
    }
  }
  const splittedUrl = url.split('/').filter(x => x); // Split and filter empty items

  // Homepage
  if (!splittedUrl.length) {
    return {
      pageType: 'page',
    }
  }
  const includesLanguage = includesLanguageInUrl(url, available_languages);
  const splittedUrlWithoutLanguage = includesLanguage ? [...splittedUrl].slice(1) : [...splittedUrl];

  // Homepage with language '/da'
  if (!splittedUrlWithoutLanguage.length) {
    return {
      pageType: 'page'
    }
  }

  // Products page /products/<seo_id>
  if (splittedUrlWithoutLanguage[0] === 'products' || splittedUrlWithoutLanguage[0] === 'produkter') {
    return {
      pageType: 'product',
      seoUrl: ([...splittedUrlWithoutLanguage].slice(1)).join('/')
    }
  }

  // User page /user/<id>
  if (splittedUrlWithoutLanguage[0] === 'user') {
    return {
      pageType: 'user',
      url: ([...splittedUrlWithoutLanguage].slice(1)).join('/')
    }
  }
  else {
    // Page /<seo_id>
    return {
      pageType: 'page',
      seoUrl: splittedUrlWithoutLanguage.join('/')
    }
  }
}

// linkTo Object to url
export const linkMe = (link: { type: 'link' | 'page' | 'catalog' | 'product' | 'stack' | 'url' | 'file', value: string, url: string | undefined } | string | number,  language: string, available_languages: string[]) => {
  if (!link) {
    return sanitizeUrl('', language, available_languages);
  }
  if (typeof link === 'number' || typeof link === 'string') {
    return sanitizeUrl(`${link}`, language, available_languages);
  }
  if (link.type === 'file') {
    return link.url;
  }
  if (link.type === 'url') {
    return sanitizeUrl(link.url, language, available_languages);
  }
  if (link.type === 'link') {
    return sanitizeUrl(link.value, language, available_languages);
  }
  if (link.type === 'page' || link.type === 'stack') {
    return `/${language}/${link.value}`;
  }
  if (link.type === 'catalog' || link.type === 'product') {
    return `/${language}/products/${link.value}`;
  }
  throw new Error(`Unknown link type: ${link.type}`);
}

const includesLanguageInUrl = (url: string, available_languages: string[]) : boolean => {
  if ((new RegExp(`^/(${available_languages.join('|')})$`)).test(url)) {
    return true;
  }
  return (new RegExp(`^/(${available_languages.join('|')})/`)).test(url);
};

// Adds language in url if needed
export const sanitizeUrl = (url: string, language: string, available_languages: string[]) : string => {
  if (!url) {
    return `/${language}`;
  }

  if (isFullPath(url)) {
    return url;
  }
  const urlThatStartsWithSlash = url.startsWith('/') ? url : `/${url}`;

  if (includesLanguageInUrl(urlThatStartsWithSlash, available_languages)) {
    return urlThatStartsWithSlash;
  }

  // It is not an problem to use user routes without language code
  // Do not show warn if '/user/ or '/user/<routes>'
  // BUT show error if '/user-faq-page'
  if (urlThatStartsWithSlash !== '/user' && !urlThatStartsWithSlash.startsWith('/user/')) {
    console.warn(`Found url without language code. URL: ${url}. Converted URL: /${language}${urlThatStartsWithSlash}`);
  }

  return `/${language}${urlThatStartsWithSlash}`;
};


// Navigation service object to url
const getURL = ({ url, page, product, catalog, fcid } : MenuItemResponse, language: string, available_languages: string[]) : string => {
  // fixed link
  if (fcid === 'link') {
    return sanitizeUrl(url, language, available_languages);
  }

  // Page, Catalog, Product etc
  if (fcid === 'item') {
    if (page?.seourl) {
      return `/${language}/${page.seourl}`;
    }
    if (product?.seourl) {
      return `/${language}/products/${product.seourl}`;
    }
    if (catalog?.seourl) {
      return `/${language}/products/${catalog.seourl}`;
    }
  }

  // Rest: Folder etc.
  return '';
};


const mapToMenuItem = (items: MenuItemResponse[], language: string, available_languages: string[]) : MenuItemV2[] => {
  return items.map((item) => {
    const { title, page, catalog, product, items, fcid } = item;
    const useLinkFromFirstItem = fcid === 'folder' && items && items.length && items[0].title?.trim().toLowerCase() === title?.trim().toLowerCase();
    const itemToGenerateUrl = useLinkFromFirstItem ? items[0] : item;
    const innerItems = items && items.length ? items : [];
    const filteredItems = useLinkFromFirstItem ? [...innerItems].splice(1) : innerItems;
    const fullUrl = getURL(itemToGenerateUrl, language, available_languages);

    return {
      id: `id-${Math.floor(Math.random() * 10000)}`, // Service doesn't include any ids, so generating one
      title: title || page?.header || product?.header || catalog?.header,
      url: fullUrl,
      items: filteredItems.length ? mapToMenuItem(filteredItems, language, available_languages) : [],
      target: item.new_window ? 'blank' : undefined,
    };
  });
};

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  cachedMenuById: {
    [id: string]: Observable<MenuItemV2[]>
  } = {};
  cachedMenuByName: {
    [name: string]: Observable<MenuItemV2[]>
  } = {};

  constructor(public http: HttpClient, private translate: TranslateService) {}

  getMenuById(id: string): Observable<MenuItemV2[]> {
    if (!this.cachedMenuById[id]) {
      this.cachedMenuById[id] = this.http
      .get<IListMenuResponse[]>(`/cms/cms/listMenus/${id}`)
      .pipe(
        map((s) => mapToMenuItem(s[0].items, this.translate.defaultLang, this.translate.getLangs())),
        shareReplay(1),
      );
    }
    return this.cachedMenuById[id];
  }

  getMenuBySystemPropertyName(propertyName: string): Observable<MenuItemV2[]> {
    if (!this.cachedMenuByName[propertyName]) {
      this.cachedMenuByName[propertyName] = this.http
      .post<IListMenuResponse[]>('/cms/cms/listMenus', { menu: propertyName })
      .pipe(
        map((s) => mapToMenuItem(s[0].items, this.translate.defaultLang, this.translate.getLangs())),
        shareReplay(1),
      );
    }
    return this.cachedMenuByName[propertyName];
  }
}

export interface MenuItemV2 {
  id: string;
  title: string;
  url?: string;
  target?: 'blank';
  items?: MenuItemV2[];
}

type Fcid = 'link' | 'item' | 'folder';
interface MenuItemResponse {
  fcid: Fcid;
  page?: {
    seourl: string;
    header: string;
  };
  product?: {
    header: string;
    seourl: string;
  };
  catalog?: {
    header: string;
    seourl: string;
  };
  url?: string;
  title: string;
  icon: string;
  new_window?: 1;
  items?: MenuItemResponse[];
  show_icon: number;
  Design: string;
}

interface IListMenuResponse {
  fcid: Fcid;
  title: string;
  icon: string;
  show_icon: number;
  Design: string;
  items: MenuItemResponse[];
}





