import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContainerModule } from '@sitemule/ng-components/components/container';

import { BasicArticleComponent } from './basic-article.component';

@NgModule({
  declarations: [BasicArticleComponent],
  imports: [CommonModule, ContainerModule],
  exports: [BasicArticleComponent],
  providers: [],
})
export class BasicArticleModule {}
