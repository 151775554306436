import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../services/sitemule-user.service';
import { SitemuleUserMatLoginComponent } from '../user/dialogs/mat-login/mat-login.component';

@Injectable({
  providedIn: 'root',
})
export class LoggedinGuard implements CanActivate {
  constructor(
    public userService: UserService,
    public router: Router,
    public http: HttpClient,
    private matdialog: MatDialog,
    private translate: TranslateService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.userService.isLoggedIn$.pipe(
      map((loggedIn) => {
        if (!loggedIn) {
          this.matdialog.open(SitemuleUserMatLoginComponent, {
            data: {
              token: route.params.token,
              dontRedirect: true,
              returnUrl: route.queryParams.returnUrl || `/${this.translate.defaultLang}/user`,
            },
            panelClass: 'small-dialog',
          });
        }
        return loggedIn;
      })
    );
  }
}
