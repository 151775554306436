import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItemV2, MenuService, SiteConfigServiceV2, UserService } from '@sitemule/ngx-sitemule';
import { fromEvent, of } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-header-and-footer',
  templateUrl: './header-and-footer.component.html',
  styleUrls: ['./header-and-footer.component.scss'],
})
export class HeaderAndFooterComponent {
  $activeUrl = this.siteSettingService.$activeUrl;
  hideTopbar = false;
  $user = this.userService.currentuser$;
  $loggedin = this.userService.isLoggedIn$;

  $showScrollButton = fromEvent(window, 'scroll').pipe(
    debounceTime(50),
    map(() => {
      return document.documentElement.scrollTop > 600;
    })
  );

  $mainNavigation = this.siteSettingService.$config.pipe(switchMap(config => {
    if (config.primary_navigation_id) {
      return this.menuService.getMenuById(config.primary_navigation_id);
    }
    return of([]as MenuItemV2[]);
  }))

  $secondaryNavigation = this.siteSettingService.$config.pipe(switchMap(config => {
    if (config.secondary_navigation_id) {
      return this.menuService.getMenuById(config.secondary_navigation_id);
    }
    return of([] as MenuItemV2[]);
  }))

  constructor(
    private route: Router,
    private userService: UserService,
    private siteSettingService: SiteConfigServiceV2,
    private menuService: MenuService,
    private translateService: TranslateService,
  ) {
    route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (
          e.url.toLowerCase().includes('/user') ||
          e.url.toLowerCase().includes('/user')
        ) {
          this.hideTopbar = true;
        } else {
          this.hideTopbar = false;
        }
      }
    });
  }

  searchProduct(searchTxt) {
    searchTxt = searchTxt.trim();
    this.route.navigate([`/${this.translateService.currentLang}/products`], { queryParams: { search: searchTxt } });
  }

  scrollTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

}
