<div [formGroup]="form" >
  <label *ngIf="data.label"
         [attr.for]="data.key">{{'web.form.' + i18nFormTitle + '.' + data.id + '.label' |  lowercase | translate}}</label>
  <div [ngSwitch]="data.controlType">
    <input *ngSwitchCase="'textbox'"
           [placeholder]="'web.form.'  + i18nFormTitle + '.' +  data.id +'.placeholder' | lowercase | translate: {default: data.placeholder ? data.placeholder : ''}"
           [formControlName]="data.key"
           [id]="data.id"
           [required]="data.required"
           [value]="data.value"
           [type]="data.type ? data.type : 'text'" class="ui-input">
    <ng-container *ngSwitchCase="'checkbox'">
      <div class="checkbox">
        <input
          [required]="data.required"
          [formControlName]="data.key"
          [id]="data.id"
          [value]="data.value"
          type="checkbox">
        <span>{{'web.form.' + data.id + '.text' | lowercase | translate: {default: ''} }} <a
          (click)="goTo(data.link)">{{'web.form.' + data.id + '.linktxt' | lowercase | translate: {default: ''} }}</a></span>
      </div>
    </ng-container>

    <select *ngSwitchCase="'dropdown'"
            class="ui-input"
            [id]="data.id"
            [required]="data.required"
            [formControlName]="data.key">
      <option value="" disabled selected>
        {{'web.form.' + i18nFormTitle + '.' + data.id + '.placeholder' | lowercase | translate: {default: ''} }}
      </option>
      <option *ngFor="let opt of data.options" [value]="opt.key">
        {{'web.form.' + i18nFormTitle + '.' + data.id + '.options.' + opt.key | lowercase | translate}}
      </option>
    </select>

    <select *ngSwitchCase="'formPicker'"
            (change)="onFormPickerChange($event)"
            class="ui-input"
            [id]="data.id"
            [required]="data.required"
            [formControlName]="data.key">
      <option value="" disabled
              selected>        {{'web.form.' + i18nFormTitle + '.' + data.id + '.placeholder' | lowercase | translate: {default: ''} }}
      </option>
      <option *ngFor="let opt of data.options"
              [value]="opt.key">        {{'web.form.' + i18nFormTitle + '.' + data.id + '.options.' + opt.key | lowercase | translate}}
      </option>
    </select>

    <textarea *ngSwitchCase="'textarea'"
              [placeholder]="'web.form.'  + i18nFormTitle + '.' +  data.id +'.placeholder' | lowercase | translate: {default: ''}"
              [rows]="data.layout.rows"
              [required]="data.required"
              [cols]="data.layout.cols"
              [id]="data.id"
              [value]="data.value"
              [formControlName]="data.key" class="ui-input" style="max-width: 100%;">
    </textarea>
  </div>
</div>
