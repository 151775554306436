<div class="container">
    <div class="dialog-title">
        {{'web.order.editreference' | translate }}
    </div>
    <form [formGroup]="referenceGroup" #form (ngSubmit)="submit()" class="address">

        <div class="left">
            <div class="ui-input-form">
                <div class="label">{{'web.order.deliverydate' | translate }} </div>
                <input type="date" formControlName="deliveryDate" [min]="minDate | date:'yyyy-MM-dd'" />
            </div>
            <div class="ui-input-form">
                <div class="label">{{'web.order.yourreference' | translate }} (Max 35 tegn) </div>
                <textarea formControlName="yourReference" maxlength="35" name="" id="" cols="30" rows="10"></textarea>
            </div>
            <div class="ui-input-form">
                <div class="label">{{'web.order.comment' | translate }} (Max 255 tegn)</div>
                <textarea formControlName="comment" maxlength="255" name="" id="" cols="30" rows="10"></textarea>
            </div>
        </div>
        <div class="right">
        </div>
    </form>
    <div class="buttons">
        <button class="ui-button" mat-dialog-close>
            {{'web.cancel' | translate }}
        </button>
        <div class="right">
            <button class="ui-button success" (click)="submit()" [disabled]="(loading$ | async)  || referenceGroup.invalid">
                {{'web.save' | translate }}
            </button>
        </div>
    </div>
</div>
