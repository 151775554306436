import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedirectWithDefaultLanguageGuard implements CanActivate {
  constructor(
    private router: Router,
    private translateService: TranslateService
  ) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const url = _state.url === '/' ? '' : _state.url;
    return this.router.navigateByUrl(`/${this.translateService.defaultLang}${url}`, { replaceUrl: true });
  }
}
