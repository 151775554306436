<div class="container">
	<div class="dialog-title">
		{{'web.user.createdeliveryaddress' | translate}}
	</div>
	<form [formGroup]="addressForm" #form (ngSubmit)="submit()" class="address">
		<div class="left" style="max-width: 500px;">

			<h4 style="font-size: 14.4px;">{{ 'web.user.deliveryname' | translate }}</h4>
			<div class="ui-input-form">
				<div class="label">{{ 'web.user.name' | translate }}</div>
				<input type="text" formControlName="addressName">
			</div>
			<div class="ui-input-form">
				<div class="label">{{ 'web.user.addressone' | translate }}</div>
				<input type="text" formControlName="addressOne">
			</div>
			<div class="ui-input-form">
				<div class="label">{{ 'web.user.addresstwo' | translate }} ({{ 'web.optional' |
					translate }})</div>
				<input type="text" formControlName="addressTwo">
			</div>
			<div style="display: grid; grid-template-columns: 100px 1fr; gap: 10px;">
				<div class="ui-input-form">
					<div class="label">{{ 'web.user.zipcode' | translate }}</div>
					<input type="number" formControlName="zipcode">
				</div>
				<div class="ui-input-form" style="margin-bottom:25px">
					<div class="label">{{ 'web.user.city' | translate }}</div>
					<input type="text" formControlName="city">
				</div>
			</div>
			<div class="ui-input-form">
				<div class="label">{{ 'web.user.country' | translate }}</div>
				<select type="text" class="ui-input search" formControlName="country" >
					<option *ngFor="let country of _countries" [value]="country.country_code">
						{{country.description}}
					</option>
				</select>
			</div>
			<div class="ui-input-form">
				<div class="label">{{ 'web.user.phonenumber' | translate }} ({{ 'web.optional' |
					translate }})</div>
				<input type="text" formControlName="phoneNumber">
			</div>
		</div>
	</form>
	<div class="buttons">
		<button class="ui-button" mat-dialog-close>
			Annuller
		</button>
		<div class="right">
			<button class="ui-button success" (click)="submit()">
				OK
			</button>
		</div>
	</div>
</div>
