import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Article } from '@sitemule/ngx-sitemule';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface TermsDialogComponentData {
  id: string;
  article: Article;
  acceptButtonText: string;
  rejectButtonText: string;
  undoRejectButtonText: string;
  confirmRejectButtonText: string;
  confimRejectTitle: string;
  confimRejectDescription: string;
  onError: () => {};
}

@Component({
  selector: 'app-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.scss'],
})
export class TermsDialogComponent {
  confirmCancel = false;
  accepting = false;
  cancelling = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TermsDialogComponentData,
    private dialogRef: MatDialogRef<TermsDialogComponent>,
    private http: HttpClient,
  ) {}

  doAccept() {
    this.accepting = true;
    this.http.post('/ip2-services/ip2transaction', {
      action: 'fleCmsCmn.cmnUpdateClientTOC'
    })
      .pipe(catchError(() => {
        this.accepting = false;
        this.data.onError();
        return of();
      }))
      .subscribe((s: any) => {
        this.accepting = false;

        if (s.success) {
          this.dialogRef.close(true);
        }
        else {
          this.data.onError();
        }
      });
  }

  doReject() {
    this.confirmCancel = true;
  }

  undoReject() {
    this.confirmCancel = false;
  }

  confirmReject() {
    this.dialogRef.close(false);
  }
}
