import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { NgxLoadingModule } from 'ngx-loading';
import { SitemuleCoreModule } from '../sitemule-core.module';
import { BasketDialogComponent } from './basket-dialog.component';
import { PricelistComponent } from './pricelist/pricelist.component';

@NgModule({
  declarations: [BasketDialogComponent, PricelistComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatAutocompleteModule,
    SitemuleCoreModule,
    TranslateModule.forChild(),
    NgxLoadingModule,
  ],
  providers: [TranslatePipe],

  exports: [PricelistComponent],
})
export class BasketModule {}
