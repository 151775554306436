import {
  ChangeDetectionStrategy,
  Component, HostBinding,
  Input
} from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { BasketDialogService } from '../../../../core/basket/basket-dialog.service';
import { BasketService } from '../../../../core/basket/basket.service';
import { GetPricesAndInventory, WebshopService } from '../../../../core/services/webshop.service';
import { ListService } from '../../../../user/services/list.service';
import { UserService } from '../../../../user/services/sitemule-user.service';
import { ICatalogItem } from './catalog-item.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'webshop-catalog-item',
  templateUrl: './catalog-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./catalog-item.component.scss'],
})
export class CatalogItemComponent {
  private readonly pricesAndInventory$ = new BehaviorSubject<GetPricesAndInventory | undefined>(undefined);
  private readonly catalogItem$ = new BehaviorSubject<ICatalogItem | undefined>(undefined);

  @Input() set catalogItem(item: ICatalogItem) {
    this.catalogItem$.next(item);
  }
  get catalogItem() {
    return this.catalogItem$.getValue();
  }
  @Input() type: 'list' | 'grid' | 'gallery' = 'grid';
  @Input() expanded: boolean = false;
  @Input() isPlaceholder: boolean = false;
  @Input() disableActions = false;
  @Input() fetchInventoryDetails = false;
  @HostBinding('class') get class () {
    return this.type;
  }
  hasBeenPutInBasket = false;

  public readonly itemWithUpdatedInventoryStatus$ = combineLatest([
    this.catalogItem$,
    this.pricesAndInventory$,
  ]).pipe(map(([ item, inventory ]) => {
    if (!inventory) {
      return item;
    }
    return {
      ...item,
      customerPrice: inventory.customerPrice?.salesPrice,
      inventory: inventory.inventory,
      lagerstatus: inventory.lagerstatus,
    }
  }))

  constructor(
    private userService: UserService,
    private basketService: BasketService,
    private basketDialogService: BasketDialogService,
    private listSerice: ListService,
    private webshopEntryService: WebshopService,
  ) {}


  addToFavorit(evt: Event, item: ICatalogItem) {
    this.listSerice.showAddToListDialog(item);
    evt.stopPropagation();
    evt.preventDefault();
    evt.stopImmediatePropagation();
  }

  addToBasket(evt: Event) {
    evt.stopPropagation();
    evt.preventDefault();
    evt.stopImmediatePropagation();
    if (this.catalogItem.customerPrice || this.catalogItem.price != '0') {
      this.userService.isLoggedIn$.pipe(first()).subscribe((isLoggedIn) => {
        const stuffToDo = () => {
          this.basketService.addItemToBasket({
            image: this.catalogItem.imageUrl,
            title: this.catalogItem.label,
            productId: this.catalogItem.product_id,
            productNumber: this.catalogItem.productNumber,
            productSEOUrl:this.catalogItem.seourl,
            productSEOUrlId_DONOT_USE_THIS: this.catalogItem.seourlid,
            quantity: 1,
            pricePerUnit: (this.catalogItem.customerPrice || this.catalogItem.price) as number,
          }).subscribe();
          this.basketDialogService.openBasket(this.catalogItem.productNumber);
        };
        if (isLoggedIn) {
          stuffToDo();
          return;
        }
        let bla = this.userService.showLoginDialog({ dontRedirect: true });
        bla.subscribe((g) => {
          if (g) {
            stuffToDo();
          }
        });
      });
    }
  }

  onVisible() {
    const productNumber = this.catalogItem?.productNumber
    if (this.fetchInventoryDetails && productNumber) {
      this.webshopEntryService.pricesAndInventoryHTTP({
        customerPrice: true,
        inventory: true,
        lagerstatustekst: true,
        lagerstatus: true,
        useCache: false,
        products: [productNumber]
      }).subscribe(([status]) => {
        this.pricesAndInventory$.next(status);
      })
    }
  }
}
