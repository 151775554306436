<a class="catalog-item" *ngIf="itemWithUpdatedInventoryStatus$ | async as catalogItem" [class.placeholder]="catalogItem.placeholder" [smNavigate]="catalogItem.link" (onVisible)="onVisible()">
  <ng-container *ngIf="catalogItem.lagerstatus; else defaultStock">
    <div class="inventory-status"
         [ngClass]="catalogItem.lagerstatus ">
    </div>
  </ng-container>
  <ng-template #defaultStock>
    <div class="inventory-status s1" [ngClass]="catalogItem.inventory.inventoryStatus" *ngIf="catalogItem.inventory">
    </div>
  </ng-template>
	<div class="image-container">
		<i class="icon" *ngIf="!catalogItem.imageUrl"><i class="icon-profile-docs"></i></i>
		<div *ngIf="catalogItem.imageUrl" class="image" [smScaleImage]="catalogItem.imageUrl" [smWidthRounding]="100">
		</div>
	</div>
	<div class="text-container" [class.expanded]="expanded">
		<div class="center">
			<div class="name-container">
				<div class="product-number" *ngIf="catalogItem.productNumber">
					{{catalogItem.productNumber}}
				</div>
				<div class="name" *ngIf="catalogItem.label">
					{{catalogItem.label}}
				</div>
			</div>
			<div class="body" *ngIf="catalogItem.body" [innerHTML]="catalogItem.body | marked">
			</div>
		</div>
	</div>

	<div class="price-container" *ngIf="catalogItem.price">
		<div class="prefix" *ngIf="catalogItem.pricePrefix">
			<h3 class="text" *ngIf="catalogItem.pricePrefix.text">{{catalogItem.pricePrefix.text}}</h3>
			<span class="label" *ngIf="catalogItem.pricePrefix.label">{{catalogItem.pricePrefix.label}}</span>
		</div>
		<div class="price"
			[class.has-customer-price]="catalogItem.customerPrice && catalogItem.customerPrice != catalogItem.price">

			<h3 class="text"> {{(catalogItem.customerPrice || catalogItem?.price) | sitemuleCurrency: 'pretty'}} </h3>
			<span class="label" style="font-size: 10px;" *ngIf="(catalogItem.customerPrice || catalogItem?.price) !== '0'">
				<span
					*ngIf="catalogItem.customerPrice && catalogItem.customerPrice != catalogItem.price">{{'web.webshop.product.indicative_price'|lowercase | translate}}
					<ng-container>
						{{ catalogItem?.price | sitemuleCurrency }}
					</ng-container>
				</span>
				{{'web.webshop.product.tax'|lowercase |
				translate}}</span>
		</div>
		<div class="suffix" *ngIf="catalogItem.priceSuffix">
			<h3 class="text" *ngIf="catalogItem.priceSuffix.text">{{catalogItem.priceSuffix.text}}</h3>
			<span class="label" style="font-size: 10px;"
				*ngIf="catalogItem.priceSuffix.label">{{catalogItem.priceSuffix.label}}</span>
		</div>
	</div>
  <ng-container *ngIf="!disableActions">
    <div class="actions" *ngIf="expanded">
      <div class="favorit" (click)="addToFavorit($event, catalogItem)">
        <i class="material-icons-outlined">star_border</i>
      </div>
      <div class="cart" [class.disabled]="(catalogItem.customerPrice || catalogItem.price) == '0'"
        [class.inBasket]="hasBeenPutInBasket" (click)="addToBasket($event)">
        <i class="icon-arrowright" *ngIf="hasBeenPutInBasket"></i>
        <i class="material-icons-outlined">shopping_cart</i>
        <!-- STYLE TODO CHANGE ICON ON CART TO SOMETHING THINNER -->
      </div>
    </div>
  </ng-container>
</a>
