import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicFieldInterface } from './dynamic-field.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-dynamic-form',
  templateUrl: './dynamic-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./dynamic-field.component.scss'],
})
export class DynamicFieldComponent implements OnInit {
  @Input() data: DynamicFieldInterface;
  @Input() form: UntypedFormGroup;
  @Input() i18nFormTitle: any;
  @Output() formPickerOut = new EventEmitter();

  ngOnInit(): void {
    if (this.data.selected) {
      this.form.controls[this.data.key].setValue(this.data.selected);
    }
    this.form.controls[this.data.key].statusChanges.subscribe((x) => {
      this.data.error = x === 'INVALID';
    });
  }

  onFormPickerChange(event) {
    this.formPickerOut.emit(event.target.value);
  }

  goTo(link) {
    window.open(location.origin + '/' + link);
  }
}
