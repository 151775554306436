<div
  class="ui-slider-wrapper"
  #wrapper
  (pan)="onPan($event)">
  <div
    class="slides-container"
    [style.width.%]="slides.length * 100"
    [class.animating]="isAnimating"
    [style.transform]="'translateX( ' + percentage + '% )'">
   <ng-content></ng-content>
  </div>
</div>
