export function getYoutubeId(url: string): string {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  let video_id;
  if (url.includes('v=')) {
    video_id = url.split('v=')[1];
  }
  if (url.includes('/embed/')) {
    // https://www.youtube.com/embed/m9p_WxkVGW8?rel=0&wmode=transparent&autoplay=1&gl=BE
    video_id = url.split('/embed/')[1].split('?')[0];
  } else {
    video_id = url.split('/').pop();
  }
  return video_id;
}

export function isYoutubeLink(url: string): boolean {
  return (url.toLowerCase().includes('youtube') || url.toLowerCase().includes('youtu.be'));
}
