<div class="tab-icon">
  <div class="product-buttons">
    <div class="button" (click)="addToList(product)"><i class="material-icons-outlined">star_border</i></div>
    <div class="button" (click)="printMe()"><i class="material-icons-outlined">print</i></div>
    <div class="button" (click)="openDialogMail()"><i class="material-icons-outlined">email</i></div>
  </div>
</div>
<div class="info-container">

  <h2 class="title">{{ product.title }}</h2>
  <div class="info-container">
    <div class="description">
      <div [innerHTML]="description | marked"></div>
      <span style="color:#106DBE; cursor: pointer;" (click)="setShownDescription()"
        *ngIf="shouldBeAbleToToggleDescription">{{!showFullDescription ? ('web.webshop.readmore' | translate) :
        ('web.webshop.readless' | translate) }}</span>
    </div>
    <div class="description" *ngIf="product.supplierdescription">
      <div [innerHTML]="product.supplierdescription | marked">
      </div>
    </div>
    <div class="info-grid" *ngIf="product.properties">
      <div class="info-grid-item" *ngFor="let property of attributes">
        <ng-container *ngIf="property">
          <span class="left">{{ property.label }}:</span>
          <span class="right">{{ property.value }}</span>
        </ng-container>
      </div>
      <div class="info-grid-item" *ngIf="shouldBeAbleToToggleAttributes">
        <a style="color:#106DBE; cursor: pointer;" (click)="setShownAttributes()"> {{!showAllAttributes ? (
          'web.webshop.showallattributes' | translate) : 'web.webshop.hideattributes' | translate }} </a>
      </div>
    </div>
  </div>
  <div class="price-container">
    <div class="price">
      <h3> {{(product?.customerPrice || product?.unitprice) | sitemuleCurrency: 'pretty'}} </h3>
    </div>
  </div>
  <span class="leading-text" *ngIf="product.unitprice !== '0'">
    <ng-container *ngIf="product.customerPrice">
      <span>{{'web.webshop.product.indicative_price'|lowercase | translate}}</span>
      {{product?.unitprice | sitemuleCurrency: 'pretty' }}
    </ng-container>
    {{'web.webshop.product.tax'|lowercase | translate}}
  </span>
  <div class="inventory" *ngIf="product.inventory">
    <ng-container *ngIf="product.lagerstatustekst; else defaultStock">
      <div class="inventory-status"
        [ngClass]="product.lagerstatus ?product.lagerstatus :product.inventory.inventoryStatus"></div>

      {{product.lagerstatustekst}}
    </ng-container>
    <ng-template #defaultStock>
      <div class="inventory-status" [ngClass]="product.inventory.inventoryStatus"></div>
      <ng-container *ngIf="product.inventory.inventoryStatus == 'IN_STOCK'">{{'web.webshop.instock'|lowercase |
        translate}}</ng-container>
      <ng-container *ngIf="product.inventory.inventoryStatus == 'ON_THE_WAY'">{{'web.webshop.ontheway'|lowercase |
        translate}}</ng-container>
      <ng-container *ngIf="product.inventory.inventoryStatus == 'SOLD_OUT'">{{'web.webshop.soldout'|lowercase |
        translate}}</ng-container>
    </ng-template>
  </div>
  <button class="ui-button success buy" [disabled]="(product.customerPrice || product.unitprice) == '0'"
    (click)="addToBasket()">{{'web.webshop.addtobasket'|lowercase |
    translate}}</button>
</div>
<div class="image-slider-container">
  <div style="height: 400px;">
    <ui-image-slider [slides]="imageSlides"></ui-image-slider>
  </div>
  <ngx-sitemule-document-list *ngIf="product.documents?.length" [documents]="product.documents">
  </ngx-sitemule-document-list>
</div>
<div class="related-products" *ngIf="relatedProducts && relatedProducts.items && relatedProducts.items.length">
  <h2>{{'web.webshop.product.itemadditions' | lowercase | translate}} {{getProductName()}}</h2>
  <webshop-catalog-mini [viewOptions]="{maxShownItems: 6}" [catalog]="relatedProducts" type="list">
  </webshop-catalog-mini>
</div>

<div class="related-categories" *ngIf="productsInSameCategory">
  <h2>{{'web.webshop.product.samecategory_products' | lowercase | translate}}</h2>

  <webshop-catalog-mini [viewOptions]="{maxShownItems: 5}" [catalog]="productsInSameCategory" type="grid"></webshop-catalog-mini>
</div>


<!--<ui-dialog [id]="'mail-dialog'">-->
<!--  <h1>Indtast din mail her.</h1>-->
<!--  <p>Mail: <input type="text" /></p>-->
<!--</ui-dialog>-->
