import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  templateUrl: './user-entry-page.component.html',
  styleUrls: ['./user-entry-page.component.scss'],
})

export class UserEntryPageComponent implements OnInit {
  constructor(private meta: Meta) {}

  ngOnInit(): void {
    this.meta.updateTag({ name: 'robots', content: 'none' });
  }
}
