import { Component, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
import { BasketService } from '../../../../core/basket/basket.service';
import { UserService } from '../../../services/sitemule-user.service';

@Component({
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent {
  _countries = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public address,
    private userService: UserService,
    private dialogRef: MatDialogRef<AddressComponent>,
    private basketService: BasketService
  ) {
    this.userService.getCountries().subscribe((data) => {
      this._countries = data;
    });
    if (address) {
      //convert dialogdata.addres to formvalue
      this.addressForm.setValue({
        id: address.address_id,
        addressName: address.name,
        addressOne: address.address_1,
        addressTwo: address.address_2,
        country: address.country_code,
        zipcode: address.zipcode,
        city: address.city,
        phoneNumber: address.phone,
      });
    }
  }
  addressForm = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    addressName: new UntypedFormControl('', Validators.required),
    addressOne: new UntypedFormControl('', Validators.required),
    addressTwo: new UntypedFormControl(''),
    zipcode: new UntypedFormControl('', Validators.required),
    city: new UntypedFormControl('', Validators.required),
    country: new UntypedFormControl('', Validators.required),
    phoneNumber: new UntypedFormControl(''),
  });

  displayFn(country): string {
    return country.description;
  }
  submit() {
    if (this.addressForm.invalid) {
      this.addressForm.markAllAsTouched();
      return;
    }
    const formVal = this.addressForm.value;
    const address = {
      address_type_id: 2,
      name: formVal.addressName,
      address_1: formVal.addressOne,
      address_2: formVal.addressTwo,
      zipcode: formVal.zipcode,
      city: formVal.city,
      country_code: formVal.country,
      phone: formVal.phoneNumber,
    };
    this.userService
      .createOrUpdateAddress(this.address?.address_id || 0, address)
      .pipe(
        switchMap(() => {
          return this.basketService.updateBasket({
            deliveryAddress: {
              name: address.name,
              address1: address.address_1,
              address2: address.address_2,
              zipcode: address.zipcode,
              city: address.city,
              country: address.country_code,
              phoneNumber: address.phone,
            }
          })
        })
      )
      .subscribe(() => {});
    this.dialogRef.close();
  }
}
