import {
  ChangeDetectionStrategy, Component,
  Input, OnChanges,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { getYoutubeId, isYoutubeLink } from '../../../core/utility/youtube';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageSliderComponent implements OnChanges {
  @Input() slides: ImageSlider[] = [];
  currentImage = 0;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.slides.currentValue) {
      simpleChanges.slides.currentValue = simpleChanges.slides.currentValue.map(slide => {
        if (isYoutubeLink(slide.imageUrl)) {
          const oldUrl = slide.imageUrl;
          slide.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + getYoutubeId(oldUrl));
        }
        return slide;
      });
      this.currentImage = 0;
    }
  }

  getBackgroundImage(url: string, element?) {
    if (isYoutubeLink(url)) {
      return `url('https://img.youtube.com/vi/${getYoutubeId(url)}/0.jpg')`;
    } else {
      url = '.0/cms/scaleImage.aspx?path=' + url + '&width=' + element.offsetWidth + '&height=' + element.offsetHeight;
      return `url('${url}')`;
    }
  }

  slideToIndex(index: number) {
    this.currentImage = index;
  }
}

export interface ImageSlider {
  imageUrl?: string;
  name?: string;
  videoUrl?: string;
}
