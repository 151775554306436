<ui-header-msg></ui-header-msg>
<div class="main-header ">
  <a class="main-header--home-link" routerLink="/">
    <img src="/assets/logo.png" alt="">
  </a>

  <div class="links">
    <a *ngFor="let menuItem of secondaryMenu" class="link" [smNavigate]="menuItem.url" [ngClass]="{'activeItem': menuItem.url === activeUrl}">
      {{menuItem.title}}
      <i class="icon-arrowright"></i>
    </a>
    <ng-container *ngIf="$userLoggedIn | async as user; else login">
      <div class="link" (click)="openBasket()" style="display: flex; align-items: center;">
        <i style="display: block; margin-right: 5px;" class="material-icons-outlined">shopping_cart</i>
      </div>
      <div class="link" style="display: flex; align-items: center;" [matMenuTriggerFor]="menu"
        (menuOpened)="showDropdown = true;" (menuClosed)="showDropdown = false">
        <ng-container *ngIf="workAsClientOrCustomer$ | async; else oneProfileIcon">
          <i [class.active]="showDropdown" style="display: block; margin-right: 5px;"
            class="material-icons-outlined">people</i>
        </ng-container>
        <ng-template #oneProfileIcon>
          <i [class.active]="showDropdown" style="display: block; margin-right: 5px;"
            class="material-icons-outlined">person</i>
        </ng-template>
        <mat-menu #menu="matMenu">
          <a mat-menu-item *ngFor="let item of showDropdownMenu$ | async"
            [routerLink]="item.url">{{item.title}}</a>
        </mat-menu>
        <span style="font-size: 11px;">{{user.usrnam}}</span>
      </div>
    </ng-container>

    <ng-template #login>
      <ng-container *ngIf="isBurgerOpen">
        <div class="link" (click)="loginDialog()">Login <i class="icon-arrowright"></i>
        </div>
      </ng-container>
      <ng-container *ngIf="!isBurgerOpen">
        <div class="link" style="display: inherit;
        align-items: center;" (click)="loginDialog()">
          <i style="display: block" class="material-icons-outlined">person</i>
        </div>
      </ng-container>
    </ng-template>
  </div>
  <div class="burger" (click)="isBurgerOpen = !isBurgerOpen" [class.active]="isBurgerOpen">
    <i [class]="isBurgerOpen ? 'icon-multiplylight' : 'icon-list'"></i>
    <div class="mobile-menu" *ngIf="isBurgerOpen" [style.top.px]="isRedBarClosed ? 70 : 90">
      <a *ngFor="let menuItem of mobileMenu" class="link project-content-margin" [ngClass]="{'activeItem': menuItem.url === activeUrl}"
        [smNavigate]="menuItem.url">{{menuItem.title}}<i
          class="icon-arrowright">
        </i>
      </a>
      <ng-container *ngIf="$userLoggedIn | async as user; else login">
        <div class="link project-content-margin" (click)="openBasket()" style="display: flex; align-items: center;">
          <i class="material-icons-outlined main-header--icon-shoppingcart">shopping_cart</i>
        </div>
        <div class="main-header--profile-link project-content-margin">
          <div class="main-header--profile-link--user-details">
            <ng-container *ngIf="workAsClientOrCustomer$ | async; else oneProfileIcon">
              <i [class.active]="showDropdown" class="material-icons-outlined main-header--icon-people">people</i>
            </ng-container>
            <ng-template #oneProfileIcon>
              <i [class.active]="showDropdown"
                class="material-icons-outlined main-header--icon-person">person</i>
            </ng-template>
            <span style="font-size: 11px;">{{user.name}}</span>
          </div>
          <button class="main-header--profile-link--button" *ngFor="let item of showDropdownMenu$ | async"
            [routerLink]="item.url">{{item.title}}</button>
        </div>
      </ng-container>

      <ng-template #login>
        <ng-container *ngIf="isBurgerOpen">
          <div class="link project-content-margin" (click)="loginDialog()">Login <i class="icon-arrowright"></i>
          </div>
        </ng-container>
        <ng-container *ngIf="!isBurgerOpen">
          <div class="link project-content-margin" style="display: inherit;
          align-items: center;" (click)="loginDialog()">
            <i style="display: block" class="material-icons-outlined">person</i>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>
