import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationDirectiveModule } from '@sitemule/ng-components/directives/navigation';
import { MarkdownModule } from '@sitemule/ng-components/pipes/marked';
import { SafeSanitizerPipeModule } from '@sitemule/ng-components/pipes/safe-sanitizer';
import { ScaleImageDirectiveModule } from '@sitemule/utils/directives/scaleimage';
import { TranslationKeyGeneratorPipeModule } from '@sitemule/utils/pipes/translation-key-generator';
import { NgChartsModule } from 'ng2-charts';
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { TableModule } from "primeng/table";
import { SitemuleCoreModule } from '../core/sitemule-core.module';
import { SitemuleUiModule } from '../ui/sitemule-ui.module';
import { AnalyticCardComponent } from './components/analytic-card/analytic-card.component';
import { ArticleComponent } from './components/article/article.component';
import { BusinessCardsComponent } from './components/business-cards/business-cards.component';
import { ChartComponent } from './components/chart/chart.component';
import { ContactCardsComponent } from './components/contact-cards/contact-cards.component';
import { FormSetComponent } from './components/form-set/form-set.component';
import { MediaSliderComponent } from './components/media-slider/media-slider.component';
import { PageBaseComponent } from './components/page-base/page-base.component';
import { TablePComponent } from './components/table/table-p/table-p.component';
import { TableStandardComponent } from './components/table/table-standard/table-standard.component';
import { UiTableDirective } from "./components/table/table-standard/ui-table.directive";
import { TableComponent } from './components/table/table.component';
import { TeaserStackComponent } from './components/teaser-stack/teaser-stack.component';
import { TeaserComponent } from './components/teaser-stack/teaser/teaser.component';
import { WebContainerComponent } from './components/web-container/web-container.component';
import { RunScriptsDirective } from './directives/runscript.directive';
import { PageEntryComponent } from './page-entry/page-entry.component';

@NgModule({
  declarations: [
    ArticleComponent,
    MediaSliderComponent,
    TeaserStackComponent,
    WebContainerComponent,
    FormSetComponent,
    BusinessCardsComponent,
    PageEntryComponent,
    TableComponent,
    ChartComponent,
    TableStandardComponent,
    UiTableDirective,
    TablePComponent,
    ContactCardsComponent,
    PageBaseComponent,
    RunScriptsDirective,
    AnalyticCardComponent,
    TeaserComponent
  ],
  imports: [CommonModule,
    RouterModule,
    SitemuleUiModule,
    SitemuleCoreModule,
    TranslateModule.forChild(),
    TableModule,
    DropdownModule, FormsModule, InputTextModule, NgChartsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    ScaleImageDirectiveModule,
    MarkdownModule,
    NavigationDirectiveModule,
    SafeSanitizerPipeModule,
    TranslationKeyGeneratorPipeModule,
  ],
  exports: [
    ArticleComponent,
    MediaSliderComponent,
    WebContainerComponent,
    TeaserStackComponent,
    FormSetComponent,
    BusinessCardsComponent,
    TableStandardComponent,
    UiTableDirective,
    TablePComponent,
    PageEntryComponent,
    TeaserComponent
  ],
  providers: []
})
export class SitemulePageModule {
}
