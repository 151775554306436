import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

@Directive({
  selector: '[onVisible]',
})
export class OnVisibleDirective implements OnInit, OnDestroy {

  @Output() onVisible = new EventEmitter<Element>();

  private observer?: IntersectionObserver;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    // Sometime IntersectionObserver is not available
    //  - IOS device with latest safari but older device may have IntersectionObserver deactivated as 'Experimental feature'
    if (typeof IntersectionObserver !== 'undefined') {
      this.observer = new IntersectionObserver(([entry]) => {
        if (entry.intersectionRatio > 0 || entry.isIntersecting) {
          this.triggerVisible(this.el.nativeElement);
          this.unsubscribeObserver();
        }
      });
      this.observer.observe(this.el.nativeElement);
    }
    else {
      this.triggerVisible(this.el.nativeElement);
    }
  }

  triggerVisible(el: Element) {
    this.onVisible.emit(el);
  }

  unsubscribeObserver() {
    this.observer?.disconnect();
    this.observer = undefined;
  }

  ngOnDestroy() {
    this.unsubscribeObserver();
  }
}
