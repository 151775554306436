<div class="option" (click)="buttonClicked('list')" >
  <i class="material-icons-outlined">reorder</i>
  {{'web.webshop.gridoptions.gridlist' | lowercase | translate}}
</div>
<div class="option" (click)="buttonClicked('grid')">
  <i class="material-icons-outlined">grid_view</i>
  {{'web.webshop.gridoptions.gridgallery' | lowercase | translate}}
</div>
<div class="option" (click)="openDropdown()" (mouseleave)="closeDropdown()">
  {{'web.webshop.gridoptions.gridrelevance.label' | lowercase | translate}}
  <i class="icon-arrowrightlight" [class.active]="showDropdown" style="font-size: 12px;"></i>
    <div class="dropdown-container">
      <ui-menu *ngIf="showDropdown" (outMenuSelected)="sortOrderChanged($event)" [menuList]="menuList" ></ui-menu>
    </div>
</div>
