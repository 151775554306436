import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IMenuItem } from './menu.interface';

/**
 * @deprecated use NavigationComponent instead
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnChanges {
  @Input() menuList: IMenuItem[] | any[] = [];
  @Input() activeMenu;
  @Input() expandThisMenuItem: any;

  /**
   *
   * @deprecated Do not use this
   */
  @Output() outMenuSelected = new EventEmitter<IMenuItem>();

  @Output() outMenuMouseOver = new EventEmitter<IMenuItem>();

  ngOnChanges(changes: SimpleChanges) {
    changes = this.checkForHeader(changes);
    if (changes.expandThisMenuItem || changes.menuList) {
      if (this.expandThisMenuItem && this.menuList?.length !== 0) {
        const sum = this.findItem(this.menuList, this.expandThisMenuItem);
        if (sum === undefined || sum.length === 0) {
        } else {
          sum[0].items = this.expandThisMenuItem.items;
          this.setExpanded(sum[0]);
        }
      }
    }
  }

  private checkForHeader(menu) {
    if (menu.menuList) {
      const check = menu.menuList.currentValue;
      if (check) {
        check.forEach(menuItem => {
          if (menuItem.header) {
            menuItem.label = menuItem.header;
          }
        });
      }
    }
    return menu;
  }

  // TODO: Remove this when CRL created service.
  private findItem(list, findItem) {
    return list.filter(item => {
      if (item?.items) {
        if (item.label === findItem.title) {
          return item;
        }
        if (item.items?.length !== 0) {
          this.findItem(item.items, findItem);
        }
        if (item.items?.length === 0 && item.label !== findItem.title) {
          return undefined;
        }
      }
    });
  }
  menuItemClicked(item: IMenuItem) {
    this.outMenuSelected.emit(item);
  }


  menuChildItemClicked(item: IMenuItem) {
    if (item.type != 'folder') {
      this.activeMenu = item;
    }
    this.outMenuSelected.emit(item);
  }

  setExpanded(menuItem: IMenuItem) {
    menuItem.expanded = !menuItem.expanded;
    event.stopPropagation();
  }

  menuMouseOver(menu) {
    this.outMenuMouseOver.emit(menu);
    event.stopPropagation();
  }
  isChildSelected(menuItem: IMenuItem) {
    return false;
    return menuItem.items ? menuItem.items.some(s => s === this.activeMenu || this.isChildSelected(s)) : false;
  }
}
