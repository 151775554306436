import { CommonModule, DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxPrintModule } from 'ngx-print';
import { BasketModule } from '../core/basket/basket.module';
import { SitemuleCurrencyPipe } from '../core/pipes/sitemule-currency/sitemule-currency.pipe';
import { SitemuleCoreModule } from '../core/sitemule-core.module';
import { SitemuleUiModule } from '../ui/sitemule-ui.module';
import { BaseModule } from './base/base.module';
import { InfoCardLoadingDialogComponent } from './components/info-card-template/info-card-template.component';
import { SitemuleUserMatChangePasswordComponent } from './components/mat-change-password/mat-change-password.component';
import { SitemuleUserMatForgotPasswordComponent } from './components/mat-forgot-password/mat-forgot-password.component';
import { SitemuleUserMatRegistrationComponent } from './components/mat-registration/mat-registration.component';
import { MfaDialogComponent } from './components/mfa-dialog/mfa-dialog.component';
import { UserMenuModule } from './components/user-menu/user-menu.module';
import { LoggedinGuard } from './guards/loggedin.guard';
import { MaterialModule } from './modules/material.module';
import { BasketPageComponent } from './pages/basket/basket-page.component';
import { CustomersPageComponent } from './pages/customers/customers-page.component';
import { DashbordPageComponent } from './pages/dashbord/dashbord-page.component';
import { OrdersPageComponent } from './pages/orders/orders-page.component';
import { ProfilePageSectionComponent } from './pages/profile/components/profile-page-section/profile-page-section.component';
import { ProfilePageComponent } from './pages/profile/profile-page.component';
import { ConnectionStatusService } from './services/connection-status.service';
import { UserService } from './services/sitemule-user.service';
import { SitemuleUserRoutes } from './sitemule-user.routes';
import { UserEntryPageComponent } from './user-entry/user-entry-page.component';
import { SitemuleUserMatLoginComponent } from './user/dialogs/mat-login/mat-login.component';
import { CreateAccountPageComponent } from './user/pages/create-account/create-account-page.component';





export const routes: Routes = [
  {
    path: '',
    component: UserEntryPageComponent,
    children: SitemuleUserRoutes
  },
  {
    path: 'basket',
    component: BasketPageComponent,
    canActivate: [LoggedinGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    MaterialModule,
    MatDialogModule,
    SitemuleCoreModule,
    MatMenuModule,
    SitemuleUiModule,
    TranslateModule.forChild(),
    BaseModule,
    UserMenuModule,
    BasketModule,
    NgxQRCodeModule,
    NgxPrintModule,
    NgxLoadingModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    SitemuleUserMatLoginComponent,
    SitemuleUserMatRegistrationComponent,
    SitemuleUserMatForgotPasswordComponent,
    MfaDialogComponent,
    SitemuleUserMatChangePasswordComponent,
    CustomersPageComponent,
    DashbordPageComponent,
    UserEntryPageComponent,
    BasketPageComponent,
    OrdersPageComponent,
    ProfilePageSectionComponent,
    ProfilePageComponent,
    CreateAccountPageComponent,
    InfoCardLoadingDialogComponent
  ],
  providers: [
    SitemuleCurrencyPipe,
    TranslatePipe,
    DatePipe
  ],
  exports: [
    SitemuleUserMatLoginComponent,
    SitemuleUserMatRegistrationComponent,
    SitemuleUserMatForgotPasswordComponent,
    SitemuleUserMatChangePasswordComponent
  ]
})
export class SitemuleUserModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SitemuleUserModule,
      providers: [ConnectionStatusService, UserService]
    };
  }
}
