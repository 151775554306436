import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Event, InlineCheckout } from '@bambora/checkout-sdk-web';
import { TranslatePipe } from '@ngx-translate/core';
import { BasketService, IBasket } from '../../../../core/basket/basket.service';
import { SitemuleCurrencyPipe } from '../../../../core/pipes/sitemule-currency/sitemule-currency.pipe';
import {
  EmailService,
  MustHaveEmailData
} from '../../../../core/services/email.service';
import { SiteConfigServiceV2 } from '../../../../core/services/site-config.service';
import { UserService } from '../../../services/sitemule-user.service';

@Component({
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss'],
})
export class OrderConfirmationComponent implements OnInit {
  @ViewChild('payel') payel: ElementRef;
  payHTML;
  epayUrl;
  bambora = false;

  constructor(
    public dialogRef: MatDialogRef<OrderConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { basket: IBasket },
    private http: HttpClient,
    private datePipe: DatePipe,
    private basketService: BasketService,
    private emailService: EmailService,
    private currencyPipe: SitemuleCurrencyPipe,
    private translatePipe: TranslatePipe,
    private siteSettings: SiteConfigServiceV2,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    if (this.data.basket.agreement.useCreditCard) {

      // TODO: Bambora solution
      // (this.data.order);
      this.bambora = true;

      const order = {
        order: {
          id: this.data.basket.id,
          amount: this.data.basket.totalAmount,
          currency: 'DKK',
          paymenttype: '1,3,4,6,7,9,29',
        },
        url: {
          accept: 'https://localhost:4200',
          cancel: 'https://localhost:4200/en/user/orders',
        },
        // FIXME: Payment type and window is currently not working.

        // paymentwindow: {
        //   paymentmethods: [{
        //     id: 'paymentcard',
        //     action: 'include'
        //   }, {
        //     id: 'mobilepay',
        //     action: 'include'
        //   }, {
        //     id: 'vipps',
        //     action: 'include'
        //   }]
        // }
      };
      const based =
        'Basic ' +
        btoa(
          'EcN0spQFbvphoFxGi52U@T525542501:Bxd8x11XYRxtaxSGX3eP6ozDzU7hp7fbMX0qXpdP'
        );
      const header = {
        headers: new HttpHeaders().set('Authorization', based),
      };
      this.http
        .post('https://api.v1.checkout.bambora.com/sessions', order, header)
        .subscribe((res: any) => {
          const checkout = new InlineCheckout(res.token, {
            container: this.payel.nativeElement,
          });
          // new RedirectCheckout(res.token);
          checkout.on(Event.Authorize, () => {
            ('payload AUTH');
            // this.basketService.postOrder(this.data.order).subscribe(s => {
            //   this.sendEmail(this.data.order);
            //   setTimeout(() => {
            //     this.dialogRef.close();
            //   }, 2000);
            // });
          });
          checkout.on(Event.Cancel, () => {
            ('payload Cancel');
          });
          checkout.on(Event.Close, () => {
            ('payload Close');
          });
        });
    } else {
      this.basketService.finalizeBasket().subscribe(() => {
        this.sendEmail(this.data.basket);
        setTimeout(() => {
          this.dialogRef.close();
        }, 2000);
      });
    }
  }
  sendEmail(basket: IBasket) {
    const data: MustHaveEmailData = {
      templatePath: '/custom-config/templates/basket.tpl',
      subject:
        this.translatePipe.transform('web.email.orderconfirmation.subject') +
        basket.id,
      // sender: this.siteSettings._$siteConfig.getValue().properties.mail_replyEmail,
      // replyTo: this.siteSettings._$siteConfig.getValue().properties.mail_replyEmail,
      // 'mailRcpAdr':  this.userService.currentuser.email + ';' +
      // this.siteSettings._$siteConfig.getValue().properties.mail_replyEmail + '(bcc)',
      recipient:
        this.userService.currentuser.email +
        ';' +
        this.siteSettings.$config.getValue().mail_replyEmail +
        '(bcc)',
      data: {
        products: [...this.data.basket.items].map((d) => {
          return {
            product: {
              image: d.image,
              title: d.title,
              productNumber: d.productNumber,
            },
            quantity: d.quantity,
            pricePerPiece: this.currencyPipe.transform(d.pricePerUnit, 'financial'),
            price: this.currencyPipe.transform(
              d.price,
              'financial'
            ),
          }
        }),
        order: {
          customer: {
            name: basket.customerName,
          },
          billing_address_name: basket.billing.name,
          billing_address_1: basket.billing.address1,
          billing_zipcode: basket.billing.zipcode,
          billing_city: basket.billing.city,
          delivery_address_name: basket.delivery.name,
          delivery_address_1: basket.delivery.address1,
          delivery_zipcode: basket.delivery.zipcode,
          delivery_city: basket.delivery.city,
          order_date: this.datePipe.transform(
            basket.orderDate,
            'dd-MM-yyyy'
          ),
          order_id: basket.id,
          reference: basket.reference,
          delivery_remark: basket.deliveryRemark,
        },
        prices: {
          //@ts-ignore
          discount: this.currencyPipe.transform(
            basket.discountAmount,
            'financial'
          ),
          //@ts-ignore
          all: this.currencyPipe.transform(
            basket.totalAmountIncludingDiscount,
            'financial'
          ),
          vat: this.currencyPipe.transform(
            basket.vatAmount,
            'financial'
          ),
          transport: this.currencyPipe.transform(
            basket.deliveryAmount,
            'financial'
          ),
          total: this.currencyPipe.transform(
            basket.totalAmount,
            'financial'
          ),
        },
        aggrement: {
          acceptSalesAndDeliveryTerms: basket.agreement.acceptSalesAndDeliveryTerms,
          collectAtCompany: basket.agreement.collectAtCompany,
        },
        //@ts-ignore
        salesTerms:
          window.location.origin +
          '/' +
          this.siteSettings.$config.getValue()
            .terms_and_condition_article_seourl,
      },
    };
    this.emailService.sendEmail(data).subscribe((s) => s);
  }
}
