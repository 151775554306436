<div class="main-header-msg" *ngIf="templateData$ | async as templateData">
  <p *ngIf="templateData.showLoading">
    Vent venligst...
  </p>
  <p *ngIf="!templateData.showLoading">
    <b>
        Du er aktiv som {{templateData.name}}, {{templateData.customerName}}
        <a style="cursor:pointer" (click)="goBack()"> Tilbage til din egen profil</a>
    </b>
  </p>
</div>
