<div class="teaser-stack-2"
  [style.--background-color]="backgroundColor"
  [style.--background-image-position]="backgroundImagePosition"
  [style.--background-image-size]="backgroundImageSize"
  [smScaleImage]="backgroundImage || ''"
  [smWidthRounding]="100"
  [class]="'grid-layout-' + _gridLayout"
  [ngClass]="{'full-page-width': fullPageWidth}">
  <div class="teaser-stack-2-container">
    <div *ngIf="title || description" class="teaser-stack-text-container">
      <h2 *ngIf="title" class="teaser-stack-title">
        {{ title }}
      </h2>
      <p *ngIf="description" class="teaser-stack-description">
        {{ description }}
      </p>
    </div>
    <div
      class="teaser-stack"
      [class.adjust-width-of-second-last-column]="finalTeasers.length % 3 === 1"
      [style.--column-count]="columnCount"
    >
      <ng-container *ngFor="let teaser of finalTeasers; trackBy: trackByFn">
        <ng-container [ngSwitch]="teaser.layout">
          <sm-teaser-layout-3
            [teaserHeight]="teaser.teaserHeight || _teaserHeight"
            [imageUrl]="teaser.imageUrl"
            [header]="teaser.header"
            [description]="teaser.description"
            [link]="teaser.link"
            [linkTarget]="teaser.linkTarget"
            [border]="teaser.border"
            [hoverBorder]="teaser.hoverBorder"
            [boxShadow]="teaser.boxShadow"
            [hoverBoxShadow]="teaser.hoverBoxShadow"
            [textColor]="teaser.textColor"
            [backgroundColor]="teaser.backgroundColor"
            [backgroundImageSize]="teaser.backgroundImageSize"
            [backgroundImagePosition]="teaser.backgroundImagePosition"
            *ngSwitchCase="'layout-3'">
          </sm-teaser-layout-3>
          <sm-teaser-layout-4
            [teaserHeight]="teaser.teaserHeight || _teaserHeight"
            [imageUrl]="teaser.imageUrl"
            [header]="teaser.header"
            [description]="teaser.description"
            [link]="teaser.link"
            [linkTarget]="teaser.linkTarget"
            [border]="teaser.border"
            [hoverBorder]="teaser.hoverBorder"
            [boxShadow]="teaser.boxShadow"
            [hoverBoxShadow]="teaser.hoverBoxShadow"
            [textColor]="teaser.textColor"
            [backgroundColor]="teaser.backgroundColor"
            [backgroundImageSize]="teaser.backgroundImageSize"
            [backgroundImagePosition]="teaser.backgroundImagePosition"
            [links]="teaser.links"
            *ngSwitchCase="'layout-4'">
          </sm-teaser-layout-4>
          <div *ngSwitchDefault>Unknown teaser layout: {{teaser.layout}}</div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
