import {
  AfterViewInit, Component,
  ElementRef,
  EventEmitter,
  Input, Output,
  QueryList, ViewChildren
} from '@angular/core';
import { IBasket, IBasketItem } from '../basket.service';

@Component({
  selector: 'user-pricelist',
  templateUrl: './pricelist.component.html',
  styleUrls: ['./pricelist.component.scss'],
})
export class PricelistComponent implements AfterViewInit {
  @Input() productNumberToFocus = '';
  @Input() readonly = true;
  @Input() hidePrice = false;
  @Input() mobileVersion = false;

  @Input() basket : IBasket;

  @Output() outUpdate: EventEmitter<{
    newQuantity: number;
    basketLine: IBasketItem;
  }> = new EventEmitter();
  @Output() outRemove: EventEmitter<IBasketItem> = new EventEmitter();

  @ViewChildren('input') inputs: QueryList<ElementRef>;

  update(event, basketLine: IBasketItem) {
    this.outUpdate.emit({
      newQuantity: event.srcElement.value,
      basketLine,
    });
  }

  remove(basketLine: IBasketItem) {
    this.outRemove.emit(basketLine);
  }

  ngAfterViewInit() {
    if (this.productNumberToFocus) {
      // TODO FIX THIS. For some reason it doesnt work when its below 200 ms. How to make it stream
      setTimeout(() => {
        this.focusProduct(this.productNumberToFocus);
      }, 200);
    }
  }

  private focusProduct(productNumberToFocus: string) {
    const foundInput = this.inputs.find((input) =>
      input.nativeElement.className.includes(productNumberToFocus)
    );
    if (!foundInput) {
      console.warn('FOUND INPUT NOT ADDED YET. SERVICE IS TOO SLOW');
      return;
    }
    foundInput.nativeElement.focus();
  }
}
