<article #article *ngIf="articleOptions" [class]="'project-content-margin ' +
    'layout-' +
        (articleOptions.composition?.design
      ? articleOptions.composition?.design
      : 'default')" [class.full-width]="articleOptions.composition?.fullPageWidth"
  [class.inverse]="articleOptions.composition?.inverse" [class.padding]="
    articleOptions.composition?.backgroundcolor ||
    articleOptions.composition.border" [ngStyle]="{'background-color': articleOptions.composition?.backgroundcolor,
    color: articleOptions.composition?.fontColor,
    border: articleOptions.composition.border ? '1px solid' : 'none',
    borderColor: articleOptions.composition.borderColor
      ? articleOptions.composition.borderColor
      : 'black'}">
  <div class="image" [class]="
      'image ' +
      ' h-' +
          (articleOptions.images[0]?.layout?.position
        ? (articleOptions.images[0]?.layout?.position)[0]
        : 'center') +
      ' v-' +
          (articleOptions.images[0]?.layout?.position
        ? (articleOptions.images[0]?.layout?.position)[1]
        : 'center')
    " [class.snapToEdge]="
          articleOptions.images[0]?.layout?.snapToEdge
        ? articleOptions.images[0]?.layout?.snapToEdge
        : ''">
    <ng-container *ngIf="articleOptions.images[0]?.url">
      <ng-container *ngIf="!articleOptions.images[0]?.url.includes('youtube'); else youtube">
        <img *ngIf="articleOptions.images[0]?.url" [smScaleImage]="articleOptions.images[0].url" [smWidthRounding]="100"
          [style]="articleOptions.images[0]?.layout?.css" [alt]="articleOptions.images[0]?.title" class="primary" />
      </ng-container>
      <ng-template #youtube>
        <iframe class="youtube" [src]="articleOptions.images[0]?.url | safeSanitizer: 'resourceUrl'" frameborder="0"
          allow="autoplay;" allowfullscreen></iframe>
      </ng-template>
    </ng-container>
  </div>
  <div class="text-container">
    <header>
      <h3 class="header">{{ articleOptions.header }}</h3>
      <p *ngIf="articleOptions.subHeader" class="subheader">{{ articleOptions.subHeader }}</p>
    </header>
    <div class="html_content" [innerHTML]="articleOptions.description | marked"></div>
    <div class="actions">
      <a [smNavigate]="action.href" [smNavigateTarget]="action.target" *ngFor="let action of articleOptions.call2action">
        <button class="ui-button">
          {{ action.buttontext }}
        </button>
      </a>
    </div>
  </div>
</article>
