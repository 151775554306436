import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// Not used by FLE

@Component({
  selector: 'ngx-sitemule-page-entry',
  templateUrl: './page-entry.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./page-entry.component.scss'],
})
export class PageEntryComponent {
  private _page: any;
  @Input() set page(page) {
    this._page = page;
    this.style = this.setGridStyles();
  }
  get page() {
    return this._page;
  }
  @HostBinding('style') style;
  @Output() tableRowClickOut = new EventEmitter();
  constructor(private sanitizer: DomSanitizer) {}

  setGridStyles() {
    if (!this.page) {
      return '';
    }
    if (!this.page.grid) {
      const defaultStyle = 'grid-template-columns: 1fr;';
      let string =
        'grid-template-areas:' +
        this.page.content.items.map((item) => {
          return `"${
            item.seourl + '' + this.page.content.items.indexOf(item)
          }"`;
        }).join(`
      `);
      return this.sanitizer.bypassSecurityTrustStyle(defaultStyle + string);
    }
    if (!this.page || !this.page.grid) {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustStyle(this.page.grid);
  }
  tableRowClick(click) {
    this.tableRowClickOut.emit(click);
  }
}
