import {Component, Input, ViewChild, ChangeDetectionStrategy, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent implements OnChanges {
  @Input() slides: any[] = [];
  @Input() currentSlide = 0;

  @ViewChild('wrapper', {static: true}) ContainerElement: any;
  activeSlide = 0;
  percentage = 0;
  isAnimating = false;
  bullets: number[];
  autoSlide: any;

  constructor() {
  }

  onPan(evt: any) {
    let percentage =
      ((100 / this.slides.length) * evt.deltaX) /
      this.ContainerElement.nativeElement.clientWidth;
    this.isAnimating = false;
    if (evt.isFinal) {
      const deltaSpeed = evt.deltaX / evt.deltaTime;
      if (percentage < -4 || deltaSpeed < -1) {
        this.goToSlide(this.activeSlide + 1);
      } else if (percentage > 4 || deltaSpeed > 1) {
        this.goToSlide(this.activeSlide - 1);
      } else {
        this.goToSlide(this.activeSlide);
      }
    } else {
      if (this.activeSlide === 0 && percentage > 6) {
        percentage = 6;
      } else if (
        this.activeSlide === this.slides.length - 1 &&
        percentage < -6
      ) {
        percentage = -6;
      }
      this.percentage =
        percentage - (100 / this.slides.length) * this.activeSlide;
    }
  }

  goToSlide(slideIndex: number) {
    this.activeSlide = slideIndex;
    this.isAnimating = true;
    if (slideIndex < 0) {
      this.activeSlide = 0;
    } else if (slideIndex > this.slides.length - 1) {
      this.activeSlide = this.slides.length - 1;
    } else {
      this.activeSlide = slideIndex;
    }
    this.percentage = -(100 / this.slides.length) * this.activeSlide;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentSlide) {
      this.goToSlide(changes.currentSlide.currentValue);
    }
  }
}
