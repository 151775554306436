import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MediaSlider } from '../../page.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-media-slider',
  templateUrl: './media-slider.component.html',
  styleUrls: ['./media-slider.component.scss'],
})
export class MediaSliderComponent implements OnInit {
  @Input() mediaSlider: MediaSlider;
  @ViewChild('wrapper', { static: true }) ContainerElement: any;
  activeSlide = 0;
  percentage = 0;
  isAnimating = false;
  bullets: number[];
  autoSlide: any;

  ngOnInit() {
    if (!this.mediaSlider.composition) {
      this.mediaSlider.composition = { height: 500, autoSpeed: 8000 };
    }
    this.bullets = Array(this.mediaSlider.items.length)
      .fill((i: number) => i)
      .map((_x, i) => i);

    this.startInterval();
  }

  onPan(evt: any) {
    let percentage =
      ((100 / this.mediaSlider.items.length) * evt.deltaX) /
      this.ContainerElement.nativeElement.clientWidth;
    this.isAnimating = false;
    if (evt.isFinal) {
      const deltaSpeed = evt.deltaX / evt.deltaTime;
      if (percentage < -4 || deltaSpeed < -1) {
        this.goToSlide(this.activeSlide + 1);
      } else if (percentage > 4 || deltaSpeed > 1) {
        this.goToSlide(this.activeSlide - 1);
      } else {
        this.goToSlide(this.activeSlide);
      }
    } else {
      if (this.activeSlide === 0 && percentage > 6) {
        percentage = 6;
      } else if (
        this.activeSlide === this.mediaSlider.items.length - 1 &&
        percentage < -6
      ) {
        percentage = -6;
      }
      this.percentage =
        percentage - (100 / this.mediaSlider.items.length) * this.activeSlide;
    }
  }

  goToSlide(slideIndex: number) {
    this.activeSlide = slideIndex;
    this.isAnimating = true;
    if (slideIndex < 0) {
      this.activeSlide = 0;
    } else if (slideIndex > this.mediaSlider.items.length - 1) {
      this.activeSlide = this.mediaSlider.items.length - 1;
    } else {
      this.activeSlide = slideIndex;
    }
    this.percentage = -(100 / this.mediaSlider.items.length) * this.activeSlide;
  }

  next() {
    if (this.activeSlide + 1 < this.mediaSlider.items.length) {
      this.goToSlide(this.activeSlide + 1);
    } else {
      this.goToSlide(0);
    }
  }

  previous() {
    if (this.activeSlide) {
      this.goToSlide(this.activeSlide - 1);
    } else {
      this.goToSlide(this.mediaSlider.items.length - 1);
    }
  }

  stopInterval() {
    if (this.autoSlide) {
      clearInterval(this.autoSlide);
      this.autoSlide = false;
    }
  }

  startInterval() {
    if (!this.autoSlide && this.mediaSlider.composition.autoSpeed) {
      this.autoSlide = setInterval(() => {
        this.next();
      }, this.mediaSlider.composition.autoSpeed);
    }
  }
}
