<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="dynamic-form-container"
        [ngStyle]="{'display': datas.items.length === 1 ? 'block': 'grid'}"
        [style.display]="datas.hidden ? 'none' : (datas.items.length === 1 ? 'block': 'grid')">

    <div *ngFor="let fields of dynamicFields" [style]="fields.style ? fields.style : ''">
      <ui-dynamic-form (formPickerOut)="formPickerOut($event)" [i18nFormTitle]="datas.id" [data]="fields"
                       [form]="form"></ui-dynamic-form>
    </div>
  </form>
</div>

