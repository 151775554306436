import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';



// import 'hammerjs';
if (environment.production) {
  // Sentry.init({
  //   dsn: "https://d2c04a9f1c6747cba3a4996244319594@o1283197.ingest.sentry.io/6492691",
  // });

  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
