import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  BrowserModule
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { BannerLayout1Module } from '@sitemule/banner/layout-1';
import { SITE_SETTINGS_SERVICE_TOKEN } from '@sitemule/ng-components/abstract-services/site-settings.service';
import { ButtonModule } from '@sitemule/ng-components/components/button';
import { NavigationComponentModule } from '@sitemule/ng-components/components/navigation';
import { NavigationDirectiveModule } from '@sitemule/ng-components/directives/navigation';
import { DefaultTranslateConfig, MaterialModule, SitemuleCoreModule, SitemulePageModule, SitemuleUiModule } from '@sitemule/ngx-sitemule';
import { NgChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderAndFooterComponent } from './components/header-and-footer/header-and-footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { PageStackComponent } from './components/page-stack/page-stack.component';
import { Stack2Module } from './components/page/page.module';
import { PreviewComponent } from './components/preview/preview.component';
import { TermsDialogComponent } from './components/terms-dialog/terms-dialog.component';
import { AppErrorHandler } from './error-handlers/app.error-handler';
import { httpInterceptorProviders } from './interceptors/intercept';
import { SiteSettingsService } from './siteSettings.service';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HeaderAndFooterComponent,
    PreviewComponent,
    PageStackComponent,
    TermsDialogComponent,
    LanguageSelectorComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SitemulePageModule,
    MatDialogModule,
    MaterialModule,
    MatNativeDateModule,
    MatMenuModule,
    FormsModule,
    SitemuleUiModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(DefaultTranslateConfig),
    SitemuleCoreModule,
    NgChartsModule,
    ButtonModule,
    NavigationDirectiveModule,
    MatProgressSpinnerModule,
    Stack2Module,
    BannerLayout1Module,
    NavigationComponentModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: SITE_SETTINGS_SERVICE_TOKEN,
      useClass: SiteSettingsService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appService: AppService) =>
        () => appService.downloadInitialData(),
      deps: [AppService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    httpInterceptorProviders,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {provide: MAT_DIALOG_DATA, useValue: {}},{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
})
export class AppModule {
}
