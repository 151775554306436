import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as localStorage from '@sitemule/core/utils/localStorage';
import { SEOService } from '@sitemule/ng-components/services/seo';
import {
  BasketService,
  SiteConfigService,
  SiteConfigServiceV2,
  UserService
} from '@sitemule/ngx-sitemule';
import { of } from 'rxjs';
import { catchError, debounceTime, first } from 'rxjs/operators';

const LOCALSTORAGE_LOCAL_KEY = 'locale';
@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private translate: TranslateService,
    private sitesettingService: SiteConfigService,
    private userService: UserService,
    private seoService: SEOService,
    private siteConfigServiceV2: SiteConfigServiceV2,
    private basketService: BasketService,
  ) {
  }

  getUserLanguage(path: string) {
    const langList = this.translate.getLangs();

    const urlLang = path.split('/')[1];
    if (urlLang && langList.includes(urlLang)) {
      return urlLang;
    }

    const userLang = this.userService.currentuser?.language;
    if (userLang && langList.includes(userLang)) {
      return userLang;
    }

    const localStorageLang = localStorage.getItem(LOCALSTORAGE_LOCAL_KEY);
    if (localStorageLang && langList.includes(localStorageLang)) {
      return localStorageLang;
    }

    const browserLang = this.translate.getBrowserLang();
    if (browserLang && langList.includes(browserLang)) {
      return browserLang;
    }

    const siteSettingsLanguage =
      this.siteConfigServiceV2.$config.getValue().default_language;
    if (siteSettingsLanguage && langList.includes(siteSettingsLanguage)) {
      return siteSettingsLanguage;
    }

    return langList[0];
  }

  downTranslation(path: string) : Promise<void> {
    return new Promise((resolve, reject) => {
      const lang = this.getUserLanguage(path);

      localStorage.setItem(LOCALSTORAGE_LOCAL_KEY, lang);

      this.seoService.setPageLanguage(lang);
      this.translate.setDefaultLang(lang);
      this.translate.use(lang)
        .pipe(
          first(),
          catchError((e) => {
            reject(e);
            return of();
          })
        )
        .subscribe(() => {
          resolve();
        })
    });
  }

  downloadInitialData() {
    return new Promise((resolve, reject) => {
      this.siteConfigServiceV2.getSiteConfigFromServer()
        .pipe(
          first(),
          catchError(e => {
            reject(e);
            return of();
          })
        )
        .subscribe(res => {
          // Do initial load once only if user is logged in
          const loggedInSubscription = this.userService.isLoggedIn$.pipe(debounceTime(200)).subscribe(isLoggedIn => {
            if (isLoggedIn) {
              this.basketService.loadBasket().pipe(first()).subscribe(() => {
                loggedInSubscription.unsubscribe();
              });
            }
          });

          this.sitesettingService.setSiteConfig(res);
          const availableLanguages =
            this.siteConfigServiceV2.$config.getValue().available_languages;
          this.translate.addLangs(availableLanguages);
          this.downTranslation(location.pathname)
            .then(() => this.userService.load())
            .then(resolve)
            .catch(reject);
        })
    });
  }
}
