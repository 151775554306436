import { Routes } from "@angular/router";
import { LoggedinGuard } from "./guards/loggedin.guard";
import { ListModule } from "./list/list.module";
import { CustomersPageComponent } from "./pages/customers/customers-page.component";
import { OrdersPageComponent } from "./pages/orders/orders-page.component";
import { ProfilePageComponent } from "./pages/profile/profile-page.component";
import { AccountModule } from "./user/account.module";

export const SitemuleUserRoutes: Routes = [
  {
    path: 'account',
    loadChildren: () => AccountModule,
  },
  {
    path: '',
    canActivate: [LoggedinGuard],
    children: [
      {
        path: '',
        component: ProfilePageComponent
      },
      {
        path: 'list',
        loadChildren: () => ListModule,
      },
      {
        path: 'customers',
        component: CustomersPageComponent
      },
      {
        path: 'profile',
        component: ProfilePageComponent
      },
      {
        path: 'orders',
        component: OrdersPageComponent,
      },
    ]
  }
];
