import { ChangeDetectionStrategy, Component, Input } from '@angular/core';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sm-basic-article',
  templateUrl: './basic-article.component.html',
  styleUrls: ['./basic-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicArticleComponent {
  @Input() header = '';

  @Input() content = '';

  get splittedContent() {
    return this.content.split('<hr>');
  }
}
