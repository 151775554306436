import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-dynamic-form-group',
  templateUrl: './dynamic-form-group.component.html',
  styleUrls: ['./dynamic-form-group.component.scss']
})
export class DynamicFormGroupComponent implements OnInit {
  @Input() datas: any;
  @Output() formEmit = new EventEmitter();
  @Output() formValid = new EventEmitter();
  @Output() formPicker = new EventEmitter();
  form: UntypedFormGroup;
  dynamicFields: [];

  constructor() {
  }

  ngOnInit(): void {
    this.form = this.toFormGroup(this.datas.items);
    this.dynamicFields = this.datas.items;
    this.form.statusChanges.pipe(debounceTime(1000)).subscribe(res => {
      this.formValid.emit(res);
      if (res === 'VALID') {
        this.formEmit.emit(this.form);
      }
    });
  }

  formPickerOut(event) {
    this.formPicker.emit(event);
  }

  private toFormGroup(datas) {
    const group: any = {};
    datas.forEach(datapoint => {
      group[datapoint.key] = datapoint.required ?
        (datapoint.type === 'email' ?
          new UntypedFormControl(datapoint.value || '', [Validators.required, Validators.email])
          : new UntypedFormControl(datapoint.value || '', Validators.required))
        : new UntypedFormControl(datapoint.value || '');
    });
    return new UntypedFormGroup(group);
  }

  onSubmit() {
    this.formEmit.emit(this.form);
  }
}
