import {Component} from '@angular/core';

@Component({
  selector: 'ui-animatd-burger-menu-icon',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss']
})
/**
 * @deprecated. Not used!
 */
export class BurgerMenuComponent {

  constructor() {
  }

}
