import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { OrderLine } from './basket.interfaces';
import { BasketService, IBasketItem } from './basket.service';

@Component({
  selector: 'user-basket-dialog',
  templateUrl: './basket-dialog.component.html',
  styleUrls: ['./basket-dialog.component.scss'],
})
export class BasketDialogComponent {
  constructor(
    private router: Router,
    private basketService: BasketService,
    public dialogRef: MatDialogRef<BasketDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
  ) {}

  currentBasket$ = this.basketService.basket$;
  currentBasketLoading$ = this.basketService.basketLoading$;

  trackFunction(element: OrderLine) {
    return element ? element.order_line_id : null;
  }

  removeFromBasket({ id }: IBasketItem) {
    this.basketService
      .removeItemFromBasket(id)
      .subscribe();
  }

  emptyBasket() {
    this.basketService.emptyBasket().subscribe();
  }

  goToBasket() {
    this.router.navigateByUrl(`/${this.translate.currentLang}/user/basket`);
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  updateOrderLine({ newQuantity, basketLine: { id } }) {
    this.basketService
      .updateItemInBasket({ id, quantity: newQuantity })
      .pipe(debounceTime(500))
      .subscribe();
  }
}
