import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * @deprecated. Use SiteConfigServiceV2  instead
 */
@Injectable({
  providedIn: 'root',
})
export class SiteConfigService {
  _$siteConfig: BehaviorSubject<IGetCurrentSystemConfig> = new BehaviorSubject(
    undefined
  );

  get $siteConfig() {
    return this._$siteConfig.getValue()
      ? this._$siteConfig.asObservable()
      : this.getSiteConfig();
  }

  constructor(private http: HttpClient) {}

  setSiteConfig(config: IGetCurrentSystemConfig) {
    this._$siteConfig.next(config);
  }

  getSiteConfig(): Observable<IGetCurrentSystemConfig> {
    return this.http
      .get<IGetCurrentSystemConfig>('/cms/cms/getCurrentSystemConfig/')
      .pipe(tap((res) => this._$siteConfig.next({ ...res })));
  }
}

@Injectable({
  providedIn: 'root',
})
export class SiteConfigServiceV2 {
  $config: BehaviorSubject<SiteSettingsV2> = new BehaviorSubject(undefined);
  $activeUrl: BehaviorSubject<string> = new BehaviorSubject(this.route.url);

  constructor(private http: HttpClient, private route: Router) {
    route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.$activeUrl.next(e.url);
      }
    });
  }

  getSiteConfigFromServer(): Observable<IGetCurrentSystemConfig> {
    return this.http
      .get<IGetCurrentSystemConfig>('/cms/cms/getCurrentSystemConfig/')
      .pipe(
        tap((res) => {
          const {
            not_found_404_page_id,
            meta_404_title,
            landing_page_id,
            terms_and_condition_article_seourl,
            mail_replyEmail,
            folder_corpnavigation_token,
            folder_navigation_token,
          } = res.properties;
          const { hostalias, default_language, data_languages } = res.system;

          const not_found_page_id = (() => {
            if (not_found_404_page_id) {
              return not_found_404_page_id;
            }

            if (meta_404_title) {
              console.warn(
                `Using 'meta_404_title' instead of 'not_found_404_page_id'`
              );
              return meta_404_title;
            }

            throw new Error(`404 page is not configured in site settings.`);
          })();

          if (!landing_page_id) {
            throw new Error(`Landing page is not configured in site settings.`);
          }

          if (
            !data_languages ||
            !Array.isArray(data_languages) ||
            !data_languages.length
          ) {
            throw new Error(
              `Available languages are not configured in site settings.`
            );
          }

          if (!default_language) {
            throw new Error(
              `Default language is not configured in site settings.`
            );
          }

          if (!data_languages.includes(default_language)) {
            throw new Error(
              `Default language is not included in available languages.`
            );
          }

          this.$config.next({
            not_found_404_page_id: not_found_page_id,
            landing_page_id,
            terms_and_condition_article_seourl,
            hostalias,
            mail_replyEmail,
            default_language,
            available_languages: data_languages,
            secondary_navigation_id: folder_corpnavigation_token,
            primary_navigation_id: folder_navigation_token
          });
        })
      );
  }
}

// This interface is aimed to replace `interface Properties`
// Properties is mapping all properties regardless of it being used by frontend or not.
export interface SiteSettingsV2 {
  not_found_404_page_id: string;
  landing_page_id: string;
  terms_and_condition_article_seourl: string;
  hostalias: string;
  mail_replyEmail: string;
  default_language: string;
  available_languages: string[];
  secondary_navigation_id: string;
  primary_navigation_id: string;
}

interface Properties {
  fcid: string;
  catalog_footer_article_id: string;
  cookie_policy_article_id: string;
  dashboard_notification_subheader: string;
  dashboard_notification_header: string;
  disableFractionalQuantity: number;
  endNotifications: string;
  endProcessedBaskets: string;
  endUnprocessedBaskets: string;

  folder_corpnavigation_token: string; // secondary navigation
  folder_navigation_token: string; // primary navigation
  folder_navigation_token_xs: string;
  folder_sitemapRight_token: string; // footer buttons
  folder_sitemap_token: string; // footer navigation

  footer_page_id: string;
  google_analytics_domain: string;
  google_analytics_id: string;
  google_webmastertools_varification: string;
  header_contactpage_id: string;
  landing_page_id: string;
  landing_page_id_xs: string;
  mail_orderConfirmationSubject: string;
  mail_order_copyemailaddress: string;
  mail_replyEmail: string;
  mail_replyName: string;
  mail_senderUserProfile: string;
  mailchimp_apikey: string;
  mailchimp_newsletter: string;
  meta_404_title: string;
  not_found_404_page_id: string;
  meta_checkout_description: string;
  meta_checkout_keywords: string;
  meta_checkout_title: string;
  meta_list_description: string;
  meta_list_keywords: string;
  meta_list_title: string;
  meta_products_description: string;
  meta_products_keywords: string;
  meta_products_title: string;
  meta_user_description: string;
  meta_user_keywords: string;
  meta_user_title: string;
  meta_webshop_description: string;
  meta_webshop_keywords: string;
  meta_webshop_title: string;
  root_user_token: string;
  security_maxLoginAttempts: string;
  security_mfa: string;
  security_passwordHashing: string;
  security_password_expiration_interval: string;
  smartsupp_chat_code: string;
  social_facebook_url: string;
  social_instagram_url: string;
  social_linkedin_url: string;
  social_twitter_url: string;
  sys_number_decimals: string;
  sys_number_seperator_decimal: string;
  sys_number_seperator_thousand: string;
  tag_manager_id: string;
  terms_and_condition_article_id: string;
  terms_and_condition_article_seourl: string;
  user_help_article_id: string;
  user_landing_article_id: string;
  user_login_article_id: string;
  user_landing_page_id: string;
  vat_included: number;
  vatrate: string;
  webshop_landing_article_id: string;
  webshop_landing_catalog_id: string;
  webshop_landing_teaser_id: string;
  webshop_catalog_root_id: string;
  webshop_payment_merchantnumber: string;
}

interface System {
  system_id: number;
  name: string;
  protocol: string;
  host: string;
  hostalias: string;
  primary_site: number;
  portfolio_languages: string[];
  data_languages: string[];
  default_language: string;
  properties?: any;
  status_code: number;
  created_by: number;
  created_timestamp: string;
  changed_by: number;
  changed_timestamp: string;
  deleted_by?: any;
  deleted_timestamp?: any;
}

export interface IGetCurrentSystemConfig {
  system_config_id: number;
  properties: Properties;
  status_code: number;
  system: System;
}
