import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IBannerLayout1Input } from '@sitemule/banner/layout-1';
import { IBackgroundImagePosition, IBackgroundImageSize, ITeaser, ITeaserLayout } from '@sitemule/ng-components/components/teaser';

interface ICarouselInput {
  items: IBannerInput[];
}
interface IBannerInput extends IBannerLayout1Input {
  contentType: 'banner';
}
interface ITeaserStackInput {
  contentType: 'teaserstack2';
  teaserHeight?: number;
  columnCount?: number;
  rowCount?: number;
  title?: string;
  description?: string;
  teasers: ITeaser[];
  backgroundColor?: string;
  fullPageWidth?: boolean;
  readMoreLink?: string;
  readMoreLabel?: string;
  layout: ITeaserLayout;
  backgroundImage?: string;
  backgroundImageSize?: IBackgroundImageSize;
  backgroundImagePosition?: IBackgroundImagePosition;
  style?: CSSStyleDeclaration;
}
interface IStackInput {
  contentType: 'stack2';
  header?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  backgroundImageSize?: IBackgroundImageSize;
  backgroundImagePosition?: IBackgroundImagePosition;
  style?: CSSStyleDeclaration;
  items?: IPageItem[];
}
interface IArticleInput {
  contentType: 'text2';
  header?: string;
  content?: string;
}

interface ICarouselInput {
  contentType: 'carousel';
  height: string;
  items: IBannerInput[];
}

type IPageItem = IBannerInput | ITeaserStackInput | IStackInput | ICarouselInput | IArticleInput;

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent {
  @Input() items: IPageItem[] = [];
}
