import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, Input,
  OnChanges, SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { first, tap } from 'rxjs/operators';
import { BasketDialogService } from '../../../../core/basket/basket-dialog.service';
import { BasketService } from '../../../../core/basket/basket.service';
import { EmailService } from '../../../../core/services/email.service';
import { PDFService } from '../../../../core/services/pdf.service';
import { DialogComponent } from '../../../../ui/components/dialog/dialog/dialog.component';
import { ImageSlider } from '../../../../ui/components/image-slider/image-slider.component';
import { ListService } from '../../../../user/services/list.service';
import { UserService } from '../../../../user/services/sitemule-user.service';
import { ICatalogItem } from '../../../catalog/components/catalog-item/catalog-item.interface';
import { ICatalog } from '../../../catalog/components/catalog/catalog.interface';
import { IGetProduct } from '../../product-service.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'webshop-product',
  templateUrl: './product.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnChanges {
  @Input() product: IGetProduct;
  @Input() relatedProducts: ICatalog;
  @Input() productsInSameCategory: ICatalog;
  shouldBeAbleToToggleAttributes = true;
  shouldBeAbleToToggleDescription = true;
  showFullDescription = false;
  showAllAttributes = false;
  loading = false;
  imageSlides: any[] = [];

  constructor(
    private translate: TranslateService,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private pdfService: PDFService,
    private basketService: BasketService,
    private userService: UserService,
    private emailService: EmailService,
    private listSerice: ListService,
    private basketDialogService: BasketDialogService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    if (changes.product && changes.product.currentValue) {
      const product: IGetProduct = changes.product.currentValue;
      const newImgArray: ImageSlider[] = [];
      if (product.images) {
        product.images.forEach((image, index) => {
          let newImageUrl = image.url;
          newImageUrl = encodeURI(newImageUrl);
          newImgArray.push({
            name: image.title ?? product.title + ' ' + (index + 1),
            imageUrl: newImageUrl,
          });
        });
      }
      this.shouldBeAbleToToggleDescription =
        this.product.description.length > 350;
      this.shouldBeAbleToToggleAttributes =
        this.product.properties !=
        this.product.properties.filter((s) => s.prioritized == true);
      this.imageSlides = newImgArray;
    }
    this.loading = false;
  }

  setShownDescription() {
    this.showFullDescription = !this.showFullDescription;
    this.cdr.markForCheck();
  }

  setShownAttributes() {
    this.showAllAttributes = !this.showAllAttributes;
    this.cdr.markForCheck();
  }

  get description() {
    if (this.product.description.length < 350) {
      return this.product.description;
    }
    return !this.showFullDescription
      ? this.product.description.slice(0, 350)
      : this.product.description;
  }

  get attributes() {
    let attributes = [
      {
        label: this.translate.instant('web.webshop.product.itemno'),
        value: this.product.productnumber,
        prioritized: true,
      },
      ...this.product.properties,
    ];
    return this.showAllAttributes
      ? attributes
      : attributes.filter((p) => p.prioritized === true).length > 0
      ? attributes.filter((p) => p.prioritized === true)
      : attributes.slice(0, 4);
  }

  addToBasket() {
    this.userService.isLoggedIn$.pipe(first()).subscribe((d) => {
      const stuffToDo = () => {
        this.basketService.addItemToBasket({
          image: this.product.images && this.product.images.length ? this.product.images[0].url : '',
          title: this.product.title,
          productId: `${this.product.product_id}`,
          productNumber: this.product.productnumber,
          productSEOUrl:this.product.seourl,
          productSEOUrlId_DONOT_USE_THIS: this.product.seourlid,
          quantity: 1,
          pricePerUnit: this.product.customerPrice || this.product.unitprice,
        }).subscribe();
        this.basketDialogService.openBasket(this.product.productnumber);
      };
      if (d) {
        stuffToDo();
      } else {
        this.userService
          .showLoginDialog({ dontRedirect: true })
          .pipe(
            tap((data) => {
              data ? stuffToDo() : '';
            })
          )
          .subscribe();
      }
    });
  }

  addToList(product) {
    this.listSerice.showAddToListDialog(product);
  }

  openDialogMail() {
    const dialogRef = this.matDialog.open(DialogComponent, {
      width: '450px',
      data: {
        title: 'Indtast din e-mail adresse',
        placeholder: 'E-mail adresse',
        input: '',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        const data: any = JSON.parse(JSON.stringify(this.product));
        const pathUrl = location.origin;
        data.logo =
          pathUrl +
          '/.0/cms/scaleImage.aspx?path=/cms-media/page/logo.png&width=340&height=80';
        if (data.unitprice === '0' || !data.unitprice) {
          delete data.unitprice;
          data.askPrice = 'Spørg om pris';
        } else {
          data.textprice = 'Vejledende pris:';
          data.moms = 'ex. moms.';
          data.unitprice = data.unitprice + ',- kr';
        }
        if (data.customerPrice) {
          data.textprice = 'Vejl. pris:';
          data.customerPrice = data.customerPrice + ',- kr';
        }
        delete data.documents;
        delete data.primarycatalogid;
        delete data.product_id;
        data.productUrl = pathUrl + '/' + this.translate.currentLang + '/products/' + data.seourl;
        delete data.seourl;
        data.url =
          pathUrl +
          '/.0/cms/scaleImage.aspx?path=' +
          data.images[0].url +
          '&width=450&height=450';

        data.properties.forEach((property) => {
          const index = data.properties.indexOf(property);
          if (index % 2 === 0) {
            property.color = 'white';
          } else {
            property.color = '#e6e6e6';
          }
          delete property?.name;
          delete property?.prioritized;
        });
        data.specs = data.properties;
        delete data.images;
        delete data.properties;
        const mailObj = {
          recipient: result,
          data,
          subject: 'Mail vedr. produktnummer: ' + this.product.productnumber,
          templatePath: '/custom-config/templates/mailhtml3.tpl',
        };
        this.emailService.sendEmail(mailObj).subscribe();
      }
    });
  }

  getDocumentsAsCatalog(product: IGetProduct): ICatalog {
    const newIcatalog = { items: [] };
    newIcatalog.items = product.documents.map((document) => {
      const catalogItem: ICatalogItem = {
        id: document.title,
        imageUrl: 'icon-catalog-item',
        body: document.title,
      };
      return catalogItem;
    });
    return newIcatalog;
  }

  getProductName() {
    return this.product.title.length > 50
      ? this.product.title.split(',')[0]
      : this.product.title;
  }

  openDocument(document: ICatalogItem) {
    let originalDocument = this.product.documents.find(
      (s) => s.title === document.id
    );
    window.open(originalDocument.url, '_blank');
  }

  printMe() {
    this.pdfService.openPDFWithEntity({
      entity: 'product',
      id: `${this.product.product_id}`,
      templatePath: '/custom-config/templates/product_pdf.hbs.html',
    });
  }
}
