import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnectionStatusService {
  public status$ = new BehaviorSubject<boolean>(navigator.onLine);
  public online = fromEvent(window, 'online');
  private offline = fromEvent(window, 'offline');

  constructor() {
    this.online.subscribe(() => {
      this.status$.next(true);
    });
    this.offline.subscribe(() => {
      this.status$.next(false);
    });
  }
}
