import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  constructor(private translate: TranslateService) {}

  getLink(seourl: string) {
    const links = {
      'om-os/levering': 'about-us/delivery',
      'products': 'products',
      'automatik-montageteknik/montageteknik': 'automatic-assembly/assembly-technology',
      'automatik-montageteknik/automatik': 'automatic-assembly/automatic-solutions',
      'brands': 'brands',
      'om-os/handelsbetingelser': 'about-us/terms',
      'om-os': 'about-us',
      'kundesupport': 'customer-support',
      'service': 'services',
      'faq': 'faq',
      'cookiepolitik': 'cookiepolitik',
    };
    const englishSeourl = links[seourl];

    if (this.translate.currentLang === 'da' || !englishSeourl) {
      return `/${this.translate.currentLang}/${seourl}`;
    }

    return `/${this.translate.currentLang}/${englishSeourl}`;
  }
}
