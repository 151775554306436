<div class="filter-container">
  <div class="filter-group" *ngFor="let filterGroup of filter">
    <div class="filter-header">
      {{filterGroup.label}}
    </div>
    <div class="filter-children">
      <div class="filter-child" *ngFor="let filterOption of filterGroup.values"
           [class.disabled]="filterOption.number === 0"
           (click)="selectFilter(filterOption, filterGroup)">
        <div class="checkbox"
             [class.checked]="filterOption.selected">
            <i class="material-icons-outlined" *ngIf="filterOption.selected">
              done
            </i>
            </div>
        {{filterOption.value}} ({{filterOption.number}})
      </div>
    </div>
  </div>
</div>
