<a class="teaser-item" *ngIf="teaser" [ngClass]="{'hoverPointer': link}"
  [ngStyle]="{
      'border': border,
      'border-color': borderColor,
      'background-color': imageBackgroundColor }" [smNavigate]="teaser.images?.[0]?.link" [smNavigateTarget]="teaser.images?.[0]?.target">
  <div *ngIf="!!imageUrl" [style]="imagePositionStyle">
    <div class="image-container" [class.background]="useImageAsBackground"
      [style.backgroundImage]="'url('+ imageUrl +')'">
    </div>
  </div>

  <div class="icon-container" *ngIf="icon" [style]="iconPositionStyle">
    <i [class]="icon" [ngStyle]="{
          'color': iconColor,
          'background-color': backgroundColor
        }">
    </i>
  </div>
  <h2 [ngStyle]="{'color': textBackgroundColor}" *ngIf="title">
    {{title}}
  </h2>

  <p class="content" [ngStyle]="{'color': textBackgroundColor}"
    [innerHTML]="description"></p>

  <div [style]="buttonPositionStyle" class="teaser-item--button-container"  *ngIf="buttonText">
    <button class="ui-button teaser-item--button" [ngStyle]="{
        'background-color':buttonBackgroundColor,
        'color': buttonTextColor}">
      {{buttonText}}
    </button>
  </div>
</a>
