import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { NgxLoadingModule } from "ngx-loading";
import { SitemuleCoreModule } from '../core/sitemule-core.module';
import { SitemulePageModule } from '../page/sitemule-page.module';
import { SitemuleUiModule } from '../ui/sitemule-ui.module';
import { CatalogModule } from './catalog/catalog.module';
import { ProductModule } from './product/product.module';
import { WebshopEntryComponent } from './webshop-entry/webshop-entry.component';

const routes: Routes = [
  {
    path: '**',
    component: WebshopEntryComponent
  },

];
@NgModule({
  declarations: [WebshopEntryComponent],
  imports: [
    CommonModule,
    SitemuleCoreModule,
    CatalogModule,
    TranslateModule.forChild(),
    SitemuleUiModule,
    FormsModule,
    ProductModule,
    NgxLoadingModule.forRoot({}),
    RouterModule.forChild(routes),
    SitemulePageModule,
  ],
  exports:[
  ],
  providers: [
    TranslatePipe,
  ]
})
export class SitemuleWebshopModule {
}


