
<ng-container *ngIf="$catalogData | async as catalogData">
  <div class="header-filter-container" *ngIf="!disableHeaderFilter">

    <h3 class="catalog-title">{{catalogData.title}} <span class="amount">({{catalogData.totalRows ? catalogData.totalRows : catalogData.items?.length}}<i
          class="icon-pluslight" *ngIf="catalogData.items?.length == searchLimit"></i>)</span>
    </h3>
    <webshop-catalog-menu (outViewmodeChanged)="changeGridCView($event)"
      (outSortOrderChanged)="changeSortOrder($event)">
    </webshop-catalog-menu>
  </div>
  <div class="left">
    <ng-container *ngIf="!showFilter">
      <ui-menu [menuList]="$menuList | async" (outMenuMouseOver)="menuMouseOver($event)" [expandThisMenuItem]="expandMenuItem"></ui-menu>
    </ng-container>
    <ng-container *ngIf="showFilter">
      <webshop-catalog-filter [filter]="$filterData | async" (outFilterClicked)="filterCatalog($event)">
      </webshop-catalog-filter>
    </ng-container>
  </div>
  <div class="content-container">
    <webshop-catalog *ngIf="($catalogItems | async) as catalog" [catalog]="catalog" [type]="viewType" [fetchInventoryDetails]="true" [disableActions]="disableActions"></webshop-catalog>
    <ngx-loading
      [ngStyle]="{
        position: 'relative',
        display: 'block',
        minHeight: '80px'
      }"
      [config]="{
        primaryColour: 'var(--primary-color)',
        secondaryColour: 'var(--primary-color)',
        tertiaryColour: 'var(--primary-color)',
        backdropBackgroundColour: 'transparent',
        backdropBorderRadius: '3px'
      }"
      [show]="true"
      *ngIf="$sparePartsLoading | async">
    </ngx-loading>
    <ng-container *ngIf="$spareParts | async as spareParts">
      <ngx-sitemule-spare-parts
        *ngIf="spareParts.length"
        title="{{'web.webshop.product.spareParts' | translate}} ({{spareParts.length}})">
        <ngx-sitemule-spare-part
          *ngFor="let sparePart of spareParts"
          (addToFavoriteListClick)="addSparePartToFavoriteList(sparePart.id)"
          (addToBasketClick)="addSparePartToBasket(sparePart.id)"
          [id]="sparePart.id"
          [productNumber]="sparePart.productNumber"
          [vendorItemNo]="sparePart.vendorItemNo"
          [price]="sparePart.price"
          [inventory]="sparePart.inventory"
          [title]="sparePart.title"
          [description]="sparePart.description"
          [label]="sparePart.label"
          [vatText]="sparePart.vatText"
          [link]="sparePart.link"
        ></ngx-sitemule-spare-part>
      </ngx-sitemule-spare-parts>
    </ng-container>
  </div>
</ng-container>
