import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SEOService } from '@sitemule/ng-components/services/seo';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { WebshopService } from '../../core/services/webshop.service';
import { IGetProduct, IGetRelatedProduct } from './product-service.interface';

@Injectable({
  providedIn: 'root'
})
export class ProductService {


  private productSource = new BehaviorSubject<IGetProduct>(undefined);
  productItem = this.productSource.asObservable();

  constructor(
    private http: HttpClient,
    private webshopService: WebshopService,
    private seoService: SEOService
  ) {}

  setProduct(product: any) {
    if (product.customerPrice?.salesPrice) {
      product.customerPrice = product.customerPrice.salesPrice;
    }
    this.seoService.setPageTitle(product.metatitle ?? product.title);
    this.seoService.setPageMetaDescription(product.metadescription ?? product.description);
    this.webshopService.pricesAndInventoryHTTP({
      products: [product.productnumber],
      useCache: false,
      customerPrice: true,
      inventory: true
    }).pipe(first()).subscribe(s => {
        let pricesFound = s[0];
        if (pricesFound) {
          let newProduct: IGetProduct = {
            ...product,
            lagerstatustekst: pricesFound.lagerstatustekst,
            lagerstatus: pricesFound.lagerstatus,
            inventory: pricesFound.inventory,
            customerPrice: pricesFound.customerPrice?.salesPrice
          }
          this.productSource.next(newProduct);
        }
      }
    )
  }

  getProductById(id: string | number): Observable<IGetProduct> {
    return this.http.get<IGetProduct>(`/cms/prd/getProduct/${id}`);
  }

  getProductBySeoUrl(seoUrl): Observable<IGetProduct> {
    return this.http.get<IGetProduct>('/cms/prd/getProductBySeoUrl/' + encodeURIComponent(seoUrl)).pipe();
  }

  getRelatedProductById(id: any): Observable<IGetRelatedProduct[]> {
    return this.http.get<IGetRelatedProduct[]>(`/cms/prd/listRelatedProducts/${id}`);
  }

  getRelatedProductByProductNumber(productNumber) {
    return this.http.get<IGetRelatedProduct[]>(`/cms/prd/listRelatedProductsByNumber/${productNumber}`);
  }

  getRelatedProductBySeoUrl(seoUrl: number | string): Observable<IGetProduct[]> {
    return this.http.get<IGetProduct[]>(`/cms/prd/listRelatedProductsBySeoUrl/${seoUrl}`).pipe(switchMap(products => {
      return this.webshopService.getPricesAndInventory(products.map(prd => prd.productnumber)).pipe(map(prices => {
        let newProducts = [...products];
        prices.forEach(price => {
          let foundProduct = newProducts.find(s => s.productnumber == price.productNumber)
          if (foundProduct) {
            foundProduct.inventory = price.inventory,
              foundProduct.customerPrice = price.customerPrice?.salesPrice;
          }
        })
        return newProducts;
      }))
    }));
  }
}
