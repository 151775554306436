import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first, map } from 'rxjs/operators';
import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'ui-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  $breadcrumblist = this.breadCrumbService.breadcrumbs.pipe(
    map((res) => {
      return res.map(item => {
        return {
          ...item,
          full_seourl: `/${this.translate.currentLang}/${item.seourl}`,
          menu: item.menu ? item.menu.map(i => {
            return {
              ...i,
              seourl: `/${this.translate.currentLang}/${i.seourl}`,
            }
          }) : item.menu
        }
      })
    })
  );

  selectedBreadcrumb;

  constructor(private breadCrumbService: BreadcrumbService, private translate: TranslateService) {}

  onMouseEnter(breadCrumb, breadCrumbList) {
    if (breadCrumb.type === 'catalog') {
      const index = breadCrumbList.findIndex(
        (bc) => bc.seourl === breadCrumb.seourl
      );
      let getMenuSeoUrl = '';
      if (index !== 0) {
        getMenuSeoUrl = breadCrumbList[index - 1].seourl;
        if (!breadCrumb.menu) {
          this.breadCrumbService
            .getBreadcrumbMenu(getMenuSeoUrl)
            .pipe(first())
            .subscribe((res) => {
              breadCrumb.menu = res.map(i => {
                return {
                  ...i,
                  seourl: `/${this.translate.currentLang}/${i.seourl}`,
                }
              });
            });
        }
      }
    }
  }
}
