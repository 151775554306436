import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subscriber, Subscription, throwError } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { WebshopService } from '../services/webshop.service';
import { Status } from './basket.interfaces';

class AutoCancelPreviousObservable<T> {
  private subscribers : Subscriber<T>[] = [];
  private providedObservableSubscription : Subscription;
  private cleanSubscription = () => {
    this.providedObservableSubscription.unsubscribe();
    this.providedObservableSubscription = null;
  }
  observable$ = new Observable<T>(subscriber => {
    this.subscribers.push(subscriber);
    if (this.providedObservableSubscription) {
      this.cleanSubscription();
    }

    this.providedObservableSubscription = this.providedObservable.subscribe((response) => {
      this.subscribers.forEach(subscriber => {
        subscriber.next(response);
        subscriber.complete();
      })
      this.subscribers = [];
      this.cleanSubscription();

      return () => {
        this.cleanSubscription();
      }
    })
  });
  constructor(private providedObservable: Observable<T>) {}
}


@Injectable({
  providedIn: 'root',
})
export class BasketService {
  private _basketLoading$ = new BehaviorSubject<boolean>(false);
  private _basket$ = new BehaviorSubject<IBasket>(null);
  basket$ = this._basket$.asObservable();
  basketLoading$ = this._basketLoading$.asObservable();

  constructor(
    private http: HttpClient,
    private notification: NotificationService,
    private webshopService: WebshopService,
  ) {
  }

  private setBasketLoading(loading: boolean) {
    if (loading !== this._basketLoading$.getValue()) {
      this._basketLoading$.next(loading);
    }
  }

  updateBasket(updateBasketInput: IUpdateBasketInput): Observable<IBasket> {
    this.setBasketLoading(true);
    const currentBasket = this._basket$.getValue();
    const { deliveryDate, deliveryRemark, reference, deliveryAddress, agreement } = updateBasketInput;
    const inputKeys = Object.keys(updateBasketInput);
    const deliveryDateIncluded = inputKeys.indexOf('deliveryDate') > -1;
    const deliveryRemarkIncluded = inputKeys.indexOf('deliveryRemark') > -1;
    const referenceIncluded = inputKeys.indexOf('reference') > -1;
    const deliveryAddressIncluded = inputKeys.indexOf('deliveryAddress') > -1;
    const agreementIncluded = inputKeys.indexOf('agreement') > -1;

    let newBasket : IBasket = {
      ...currentBasket,
    };
    let payload : any = {};

    if (deliveryDateIncluded) {
      newBasket = {
        ...newBasket,
        deliveryDate
      };
      payload = {
        ...payload,
        delivery_date: deliveryDate,
      };
    }

    if (deliveryRemarkIncluded) {
      newBasket = {
        ...newBasket,
        deliveryRemark
      };
      payload = {
        ...payload,
        delivery_remark: deliveryRemark,
      };
    }

    if (referenceIncluded) {
      newBasket = {
        ...newBasket,
        reference
      };
      payload = {
        ...payload,
        reference
      };
    }

    if (deliveryAddressIncluded) {
      newBasket = {
        ...newBasket,
        delivery: {
          name: deliveryAddress.name,
          address1: deliveryAddress.address1,
          address2: deliveryAddress.address2,
          zipcode: deliveryAddress.zipcode,
          city: deliveryAddress.city,
          country: deliveryAddress.country,
          phoneNumber: deliveryAddress.phoneNumber,
        }
      };
      payload = {
        ...payload,
        delivery_address_name: deliveryAddress.name,
        delivery_address_1: deliveryAddress.address1,
        delivery_address_2: deliveryAddress.address2,
        delivery_zipcode: deliveryAddress.zipcode,
        delivery_city: deliveryAddress.city,
        delivery_country: deliveryAddress.country,
        delivery_phone: deliveryAddress.phoneNumber,
      }
    }

    if (agreementIncluded) {
      newBasket = {
        ...newBasket,
        agreement,
      };
      payload = {
        ...payload,
        properties :{
          ...agreement
        }
      }
    }

    this._basket$.next(newBasket)

    return this.http.put<IBasket>('/cms/ord/order/' + currentBasket.id, payload).pipe(
      tap(() => {
        this.setBasketLoading(false);
        // Do it on background
        return this.fetchBasketAndRejectPreviousCall$.subscribe();
      })
    );
  }

  addItemToBasket(item: IAddItemToBasketInput): Observable<IBasket> {
    return this.addItemsToBasket([item]);
  }

  addItemsToBasket(
    items: IAddItemToBasketInput[]
  ): Observable<IBasket> {
    this.setBasketLoading(true);
    const currentBasket = this._basket$.getValue();
    const updatedItems = [...(currentBasket ? currentBasket.items : [])]; //Soft clone all items

    items.forEach(addedItem => {
      const index = updatedItems.findIndex(({ productId }) => productId === addedItem.productId);

      if (index > -1) {
        const newQuantity = updatedItems[index].quantity + addedItem.quantity;

        updatedItems[index] = {
          ...updatedItems[index],
          quantity: newQuantity,
          price: newQuantity * updatedItems[index].pricePerUnit,
        }
      }
      else {
        updatedItems.push({
          ...addedItem,
          price: addedItem.quantity * addedItem.pricePerUnit,
          id: '__temp__'
        });
      }
    });

    // Append new items in the bottom,
    // And sync with backend later, if backend decide it to be merged
    this._basket$.next({
      ...currentBasket,
      items: updatedItems,
    })
    return forkJoin(
      items.map(({ productId, quantity }) => {
        return this.http.post('/cms/ord/orderAddProduct/' + productId, {
          quantity,
        });
      })
    )
    .pipe(
      switchMap(() => this.fetchBasketAndRejectPreviousCall$),
      tap(() => this.setBasketLoading(false))
    );
  }

  removeItemFromBasket(itemId: string): Observable<any> {
    return this.removeItemsFromBasket([itemId]);
  }

  private removeItemsFromBasket(orderLineIds: string[]): Observable<any> {
    this.setBasketLoading(true);
    const currentBasket = this._basket$.getValue();

    this._basket$.next({
      ...currentBasket,
      items: currentBasket.items.filter(({ id }) => orderLineIds.indexOf(`${id}`) === -1),
    });

    return forkJoin(
      orderLineIds.map((itemId) => {
        return this.http.delete('/cms/ord/orderline/' + itemId, {});
      })
    ).pipe(
      switchMap(() => {
        return this.fetchBasketAndRejectPreviousCall$;
      }),
      tap(() => {
        this.setBasketLoading(false);
      })
    );
  }

  updateItemInBasket(item: IUpdateItemInBasketInput): Observable<IBasket> {
    return this.updateItemsInBasket([item]);
  }

  private updateItemsInBasket(
    updatedItems: IUpdateItemInBasketInput[]
  ): Observable<IBasket> {
    this.setBasketLoading(true);
    const basket = this._basket$.getValue();
    const { id: basketId, items } = basket;

    const newItems = items.map((item) => {
      const updatedItem = updatedItems.find(({ id }) => {
        return id === item.id;
      });

      if (updatedItem) {
        item.quantity = updatedItem.quantity;
        item.price = updatedItem.quantity * item.pricePerUnit;
      }

      return item;
    });

    this._basket$.next({
      ...basket,
      items: newItems
    });

    return forkJoin(
      updatedItems.map(({ id, quantity }) => {
        return this.http.put('/cms/ord/orderline/' + id, {
          quantity,
          order_id: basketId,
        });
      })
    ).pipe(
      switchMap(() => {
        return this.fetchBasketAndRejectPreviousCall$;
      }),
      tap(() => {
        this.setBasketLoading(false);
      })
    );
  }

  protected emptyBasket$ = this.http.get('cms/ord/basketEmpty').pipe(
    switchMap(() => {
      return this.fetchBasketAndRejectPreviousCall$;
    })
  );

  emptyBasket(): Observable<IBasket> {
    this.setBasketLoading(true);
    const basket = this._basket$.getValue();

    this._basket$.next({
      ...basket,
      items: [],
    });

    return this.emptyBasket$.pipe(tap(() => {
      this.setBasketLoading(false);
    }))
  }

  private finalizeBasket$ = this.http
    .post<{
      success: boolean;
      message?: string;
    }>('/ip2-services/ip2transaction.aspx', {
      action: 'NAVserv.NAVselectService',
      webservice: 'SalesOrder',
      function: 'Create',
      deleteResponse: false,
      logPath: '/www/dev/flecms/customized/navlogs/',
    });

  finalizeBasket(): Observable<IFinalizeBasketOutput> {
    this.setBasketLoading(true);
    return this.finalizeBasket$
      .pipe(
        map((data) => {
          if (!data.success) {
            this.notification.show(data.message, 'error');
            return throwError(data.message);
          }
          return of(data);
        }),
        switchMap(() => {
          return this.emptyBasket$;
        }),
        tap(() => {
          this.setBasketLoading(false);
        })
      );
  }

  loadBasket() {
    this.setBasketLoading(true);
    return this.fetchBasketAndRejectPreviousCall$.pipe(tap(() => {
      this.setBasketLoading(false);
    }));
  }

  private fetchBasket$ = this.http.get<IBasketResponse>('/cms/ord/basket', { params: new HttpParams().set('forceBasket', 'true') })
    .pipe(
      // Map the response to internal model
      map(({
        order_id,
        order_status,
        order_date,
        order_total_free_delivery,
        order_total_additional_buy_for_free_delivery,
        order_total_web_discount,
        order_total_included_discount,
        order_total_freight,
        order_total_VAT,
        order_total_sum,
        delivery_remark,
        reference,
        delivery_date,
        billing_address_name,
        billing_address_1,
        billing_address_2,
        billing_city,
        billing_zipcode,
        billing_country,
        billing_phone,
        customer: {
          name: customer_name
        },
        delivery_address_name,
        delivery_address_1,
        delivery_address_2,
        delivery_city,
        delivery_zipcode,
        delivery_country,
        delivery_phone,
        properties,
      }) : IBasket => {
        const {
          acceptSalesAndDeliveryTerms,
          collectAtCompany,
          useCreditCard,
        } = properties || {};
        return  {
          id: `${order_id}`,
          status: order_status,
          orderDate: order_date,
          isDeliveryForFree: order_total_free_delivery,
          missingAmountForFreeDelivery: order_total_additional_buy_for_free_delivery,
          discountAmount: order_total_web_discount,
          totalAmountIncludingDiscount: order_total_included_discount,
          deliveryAmount: order_total_freight,
          vatAmount: order_total_VAT,
          totalAmount: order_total_sum,
          deliveryDate: delivery_date,
          reference,
          deliveryRemark: delivery_remark,
          customerName: customer_name,
          delivery: {
            name: delivery_address_name,
            address1: delivery_address_1,
            address2: delivery_address_2,
            city: delivery_city,
            zipcode: delivery_zipcode,
            country: delivery_country,
            phoneNumber: delivery_phone
          },
          billing: {
            name: billing_address_name,
            address1: billing_address_1,
            address2: billing_address_2,
            city: billing_city,
            zipcode: billing_zipcode,
            country: billing_country,
            phoneNumber: billing_phone
          },
          agreement: {
            acceptSalesAndDeliveryTerms,
            collectAtCompany,
            useCreditCard,
          },
          items: this._basket$.getValue()?.items || [],
        };
      }),
      // Set the basket
      tap((basket) => {
        this._basket$.next(basket);
      }),
      // Fetch lines of the basket and update the items property
      switchMap((basket: IBasket) => {
        return this.http
          .get<IBasketItemResponse[]>('/cms/ord/order/' + basket.id + '/lines')
          .pipe(
            map((items) : IBasket => {
              return {
                ...basket,
                items: items.map(({ order_line_id, product: { image, title, product_id, productNumber, seourl, seourlid }, quantity, price }) => ({
                  id: `${order_line_id}`,
                  image,
                  title,
                  productId: `${product_id}`,
                  productNumber,
                  productSEOUrl: seourl,
                  productSEOUrlId_DONOT_USE_THIS: seourlid,
                  quantity,
                  price: price * quantity,
                  pricePerUnit: price,
                }))
              };
            })
          );
      }),
      // Set the basket again with updated items property
      tap((basket) => {
        this._basket$.next(basket);

        // TODO: We can't just fetch the price,
        // we also have to refetch the basket because total may not match
        this.fetchPricesAndInventory();
      })
    );

  private fetchBasketAndRejectPreviousCall$ = new AutoCancelPreviousObservable<IBasket>(this.fetchBasket$).observable$;

  private fetchPricesAndInventory() {
    const productNumbers = this._basket$.getValue().items.map(({ productNumber }) => {
      return productNumber;
    });
    this.webshopService.getPricesAndInventory(productNumbers, false).pipe(debounceTime(500)).subscribe((results) => {
      const basket = this._basket$.getValue();

      this._basket$.next({
        ...basket,
        items: basket.items.map((item) => {
          const inventory = results.find(({ productNumber }) => {
            return productNumber === item.productNumber;
          });

          const updatedInformation = inventory ? {
            inventoryStatusText: inventory.lagerstatustekst,
            inventoryStatus: inventory.lagerstatus || inventory?.inventory?.inventoryStatus as any,
          } : {};

          return {
            ...item,
            ...updatedInformation,
          }
        }),
      });
    })
  }
}

export interface IBasketItem {
  id: string;
  image: string;
  title: string;
  productId: string;
  productNumber: string;
  productSEOUrl: string;
  productSEOUrlId_DONOT_USE_THIS: string; // Add basket item to fav-list service is expecting seourlId, not productid, fix service instead
  quantity: number;
  pricePerUnit: number;
  price: number;
  inventoryStatusText?: string;
  inventoryStatus?: 'IN_STOCK' | 'ON_THE_WAY' | 'SOLD_OUT';
}

export interface IAddress {
  name: string;
  address1: string;
  address2: string;
  zipcode: string;
  city: string;
  country: string;
  phoneNumber: string;
}

export interface IBasket {
  id: string;
  status: Status,
  orderDate: string;
  isDeliveryForFree: boolean;
  missingAmountForFreeDelivery: number;
  discountAmount: number;
  totalAmountIncludingDiscount: number;
  deliveryAmount: number;
  vatAmount: number;
  totalAmount: number;
  deliveryDate?: string;
  reference?: string;
  deliveryRemark?: string;
  customerName?: string;
  delivery?: IAddress;
  billing?: IAddress;
  agreement?: {
    acceptSalesAndDeliveryTerms: boolean;
    useCreditCard: boolean;
    collectAtCompany: boolean;
  };
  items: IBasketItem[];
}

interface IBasketResponse {
  order_id: number;
  order_status: Status;
  order_date: string;
  order_total_free_delivery: boolean;
  order_total_additional_buy_for_free_delivery: number;
  order_total_web_discount: number;
  order_total_included_discount: number;
  order_total_freight: number;
  order_total_VAT: number;
  order_total_sum: number;
  delivery_remark: string;
  reference: string;
  delivery_date: string;
  billing_address_name: string;
  billing_address_1: string;
  billing_address_2: string;
  billing_zipcode: string;
  billing_city: string;
  billing_country: string;
  billing_phone: string;
  delivery_address_name: string;
  delivery_address_1: string;
  delivery_address_2: string;
  delivery_zipcode: string;
  delivery_city: string;
  delivery_country: string;
  delivery_phone: string;
  customer: {
    name: string;
  };
  properties?: {
    acceptSalesAndDeliveryTerms: boolean;
    useCreditCard: boolean;
    collectAtCompany: boolean;
  };
}

interface IBasketItemResponse {
  order_line_id: number;
  order_id: number;
  product: {
    image: string;
    product_id: number;
    productNumber: string;
    seourl: string;
    seourlid: string;
    title: string;
  };
  quantity: number;
  price: number;
  properties: any;
}

interface IUpdateBasketInput {
  deliveryDate?: string;
  reference?: string;
  deliveryRemark?: string;

  deliveryAddress?: IAddress;
  agreement?: {
    acceptSalesAndDeliveryTerms: boolean;
    collectAtCompany: boolean;
    useCreditCard: boolean;
  }
}
interface IAddItemToBasketInput {
  productId: string;
  quantity: number;

  image: string;
  title: string;
  productNumber: string;
  productSEOUrl: string;
  productSEOUrlId_DONOT_USE_THIS: string; // Should be removed, check IBasketItem.productSEOUrlId_DONOT_USE_THIS
  pricePerUnit: number;
}

interface IUpdateItemInBasketInput {
  id: string;
  quantity: number;
}

interface IFinalizeBasketOutput {}
