import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tooltip]',

})
export class TooltipDirective {


  tooltip: HTMLElement;
  @Input('tooltip') tooltipTitle = 'inital stuff';
  delay = 500;
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.showTooltip();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }
  showTooltip() {
    this.tooltip = this.renderer.createElement('span');
    // creating a span
    this.renderer.appendChild(
      this.tooltip,
      this.renderer.createText(this.tooltipTitle)
    );
    const position = this.getTooltipPosition();
    this.renderer.setStyle(this.tooltip, 'top', `${position.top}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${position.left}px`);
    this.renderer.appendChild(document.body, this.tooltip);
    this.renderer.addClass(this.tooltip, 'tooltip');
  }
   hideTooltip() {
    this.tooltip.remove();
  }
  getTooltipPosition(){
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    let top, left;
    top = hostPos.bottom + window.pageYOffset;
    left = hostPos.left + hostPos.width / 2;
    return {
      top,
      left
    };
  }
}
