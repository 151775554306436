<div class="template">
  <div class="main-header ">
    <div class="max-width">
      <img src="/assets/logo.svg" class="logo">
    </div>
  </div>

  <h2 class="title">{{ product.title }}</h2>
  <div class="grid">
    <div class="info-container">
      <div class="descriptionHeader">Produktbeskrivelse</div>
      <div class="description">{{ product.description }}</div>

      <div class="productNumber">Varenummer: {{product.productnumber}}</div>

    </div>
    <div class="image-slider-container" *ngIf="product?.images?.[0]?.url">
      <img class="img" [src]="product?.images?.[0]?.url">
    </div>
    <div class="info-container">
      <div class="info-grid" *ngIf="product.properties">
        <div class="info-grid-item">
          <!--          <ng-container *ngIf="property">-->
          <span class="left">Teknisk data:</span>
          <!--          </ng-container>-->
        </div>
        <div class="info-grid-item" *ngFor="let property of product.properties">
          <ng-container *ngIf="property">
            <span class="left">{{ property.label }}:</span>
            <span class="right">{{ property.value }}</span>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="info-container">
      <div class="price-container" [ngClass]="{'crossedOutText':product.customerPrice}" *ngIf="product.unitprice">
        <ng-container>
          <ng-container *ngIf="product.unitprice">
            <span *ngIf="product.customerPrice">{{'web.webshop.product.indicative_price_short'|lowercase | translate}}</span>
            <span class="leading-text"
              *ngIf="!product.customerPrice">{{'web.webshop.product.indicative_price'|lowercase | translate}}</span>
            <h3 class="price"> {{product?.unitprice | sitemuleCurrency: 'pretty'}} </h3>
          </ng-container>
        </ng-container>
      </div>
      <div class="price-container" *ngIf="product.customerPrice">
        <ng-container>
          <h3 class="price">{{product.customerPrice | sitemuleCurrency: 'pretty'}}</h3>
        </ng-container>
      </div>
      <div class="price-container">
        <span class="leading-text" *ngIf="product.unitprice !== '0'"> ex. moms.</span>
      </div>
    </div>
  </div>
  <div class="blue-bar">
    <div class="bar-element" style="margin-left: 20px">Folke-Larsens Eftf. A/S</div>
    <div class="bar-element">|</div>
    <div class="bar-element">Ejby Industrivej 106</div>
    <div class="bar-element">|</div>
    <div class="bar-element">DK – 2600 Glostrup</div>
    <div class="bar-element">|</div>
    <div class="bar-element">Tlf.: +45 43 96 75 77</div>
    <div class="bar-element">|</div>
    <div class="bar-element">E-mail: info@folkelarsen.dk</div>
    <div class="bar-element">|</div>
    <div class="bar-element" style="margin-right: 20px"><b>www.folkelarsen.dk</b></div>
  </div>
</div>
