import {
  linkMe
} from '@sitemule/ngx-sitemule';
import * as marked from 'marked';

const BANNER_LAYOUTS = {
  'layout-1': {
    layout: 'layout-1', // Value that is used to pick the actual component
    height: '400px',
    contentPosition: 'left',
    fullPageWidth: false,
    backgroundImagePosition: 'center',
    backgroundImageSize: 'cover',
    backgroundImageFullPageWidth: false,
    buttonSize: 'lg',
    imageBorderRadius: '9px',
    contentBorderRadius: '9px',
    buttonBorderRadius: '36px',
  },
  'layout-2': {
    layout: 'layout-1',
    height: '400px',
    contentPosition: 'right',
    fullPageWidth: false,
    backgroundImagePosition: 'center',
    backgroundImageSize: 'cover',
    backgroundImageFullPageWidth: false,
    buttonSize: 'lg',
    imageBorderRadius: '9px',
    contentBorderRadius: '9px',
    buttonBorderRadius: '36px',
  },
  'layout-3': {
    layout: 'layout-2',
    fullPageWidth: true,
  }
};

const BANNER_THEMES = {
  'primary-theme': {
    textColor: '#fff',
    textBackgroundColor: 'var(--primary-color)',
    buttonStyle: {
        '--button-background-color': '#fff',
        '--button-hover-background-color': '#fff',
        '--button-text-color': 'var(--primary-color)',
        '--button-hover-text-color': 'var(--primary-color)'
    }
  },
  'danger-theme': {
    textColor: '#fff',
    textBackgroundColor: '#C51A1B',
    buttonStyle: {
        '--button-background-color': '#fff',
        '--button-hover-background-color': '#fff',
        '--button-text-color': '#C51A1B',
        '--button-hover-text-color': '#C51A1B'
    }
  },
  'basic-theme-1': {
  },
  'basic-theme-2': {
  }
};

export const sanitizeBanner = (content: any, availableLanguages: string[], defaultLang: string) => {
  const selectedLayoutKey = BANNER_LAYOUTS[content.properties.page_layout.componentLayout] ? content.properties.page_layout.componentLayout : 'layout-1';
  const selectedThemeKey = BANNER_THEMES[content.properties.page_theme.componentTheme] ? content.properties.page_theme.componentTheme : 'primary-theme';
  const selectedLayout = BANNER_LAYOUTS[selectedLayoutKey];
  const selectedTheme = BANNER_THEMES[selectedThemeKey];

  return {
    contentType: 'banner',
    title: content.properties.header,
    description: marked.parse(content.properties.description),
    backgroundImageUrl: content.properties.images,

    layout: selectedLayout.layout,
    height: selectedLayout.height,
    contentPosition: selectedLayout.contentPosition,
    fullPageWidth: selectedLayout.fullPageWidth,
    backgroundImagePosition: selectedLayout.backgroundImagePosition,
    backgroundImageSize: selectedLayout.backgroundImageSize,
    backgroundImageFullPageWidth: selectedLayout.backgroundImageFullPageWidth,
    imageBorderRadius: selectedLayout.imageBorderRadius,
    contentBorderRadius: selectedLayout.contentBorderRadius,

    contentTextColor: selectedTheme.textColor,
    contentBackgroundColor: selectedTheme.textBackgroundColor,

    className: `layout-${selectedLayoutKey} theme-${selectedThemeKey}`,

    wrapperLink: (() => {
      if (!content.properties.buttonText && content.properties.link) {
        return {
          href: linkMe(content.properties.link, defaultLang, availableLanguages),
          target: content.properties.linkTarget
        };
      }
    })(),
    links: (() => {
      if (content.properties.buttonText) {
        return [{
          label: content.properties.buttonText,
          href: linkMe(content.properties.link, defaultLang, availableLanguages),
          target: content.properties.linkTarget,
          borderRadius: selectedLayout.buttonBorderRadius,
          size: selectedLayout.buttonSize,
          style: selectedTheme.buttonStyle,
        }];
      }

      return [];
    })(),
  }
}
