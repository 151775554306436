import {ICatalogFilter, ICatalogFilterOption} from './filter.interface';
import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'webshop-catalog-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogFilterComponent {
  @Input() filter: ICatalogFilter[];
  @Output() outFilterClicked: EventEmitter<any> = new EventEmitter();

  // listOfSelectedFilterOptions: ICatalogFilterOption[] = [];
  listOfSelectedFilterGroups: ICatalogFilter[] = [];
  id = 0;

  // getSelectedFilters() {
  //   return {filterSet: this.filter, selectedFilterGroups: this.listOfSelectedFilterGroups};
  // }

  selectFilter(filterOption: ICatalogFilterOption, filterGroup) {
    filterOption.selected = !filterOption.selected;
    filterOption.id = filterOption.id ? filterOption.id : this.id++;


    // = [...this.listOfSelectedFilterGroups, filterGroup];
    if (filterOption.selected) {
      const check = this.listOfSelectedFilterGroups.find(group => group.label === filterGroup.label);
      // this.blistOfSelectedFilterOptions = [...this.listOfSelectedFilterOptions, filterOption];
      if (!check || this.listOfSelectedFilterGroups.length === 0) {
        this.listOfSelectedFilterGroups = [...this.listOfSelectedFilterGroups, filterGroup];
      }
      this.listOfSelectedFilterGroups[0].isMasterFilter = true;
    }

    if (!filterOption.selected) {
      let spliceNumber = 0;
      const index = this.listOfSelectedFilterGroups.findIndex(fGroup => {
        const found = fGroup.values.find(x => x.selected);
        if (found === undefined) {
          spliceNumber = 1;
          return fGroup;
        }
        return null;
      });
      if (index === 0 && this.listOfSelectedFilterGroups.length >= 2) {
        this.listOfSelectedFilterGroups[1].isMasterFilter = true;
        this.listOfSelectedFilterGroups[0].isMasterFilter = false;
      }
      this.listOfSelectedFilterGroups.splice(index, spliceNumber);


      // this.listOfSelectedFilterOptions.splice(
      //   this.listOfSelectedFilterOptions.findIndex(x => x.id === filterOption.id), 1);
    }
    this.outFilterClicked.emit({filterSet: this.filter, selectedFilterGroups: this.listOfSelectedFilterGroups});
  }
}
