import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TeaserLayout3Module } from '@sitemule/ng-components/components/teaser/teaser-layout-3';
import { TeaserLayout4Module } from '@sitemule/ng-components/components/teaser/teaser-layout-4';
import { NavigationDirectiveModule } from '@sitemule/ng-components/directives/navigation';
import { ScaleImageDirectiveModule } from '@sitemule/utils/directives/scaleimage';
import { TeaserStack2Component } from './teaser-stack-2.component';

@NgModule({
  declarations: [
    TeaserStack2Component,
  ],
  imports: [CommonModule, TeaserLayout3Module, TeaserLayout4Module, BrowserAnimationsModule, NavigationDirectiveModule, ScaleImageDirectiveModule],
  exports: [
    TeaserStack2Component,
  ],
  providers: [],
})
export class TeaserStack2Module {}
