import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { prefixScaleImageUrl } from '@sitemule/utils/utils/prefixScaleImageUrl';
import { forkJoin, Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { BasketDialogService } from '../../core/basket/basket-dialog.service';
import { IGetProduct } from '../../core/basket/basket.interfaces';
import { BasketService } from '../../core/basket/basket.service';
import { PDFService } from '../../core/services/pdf.service';
import { SiteConfigServiceV2 } from '../../core/services/site-config.service';
import { AddToListComponent } from '../list/dialog/add-to-list/add-to-list.component';
import { UserService } from './sitemule-user.service';

@Injectable({
  providedIn: 'root',
})
export class ListService {
  constructor(
    private http: HttpClient,
    private pdfService: PDFService,
    private matDialog: MatDialog,
    private userService: UserService,
    private systemConfigService: SiteConfigServiceV2,
    private basketService: BasketService,
    private basketDialogService: BasketDialogService,
  ) {}

  getLists(search, limit): Observable<IListsDTO[]> {
    const searchData = {
      search,
      pageno: 1,
      limit,
    };
    return this.http.post<IListsDTO[]>('/cms/lst/listLists', searchData);
  }

  removeLines(lineIds: number[]) {
    if (!lineIds || lineIds.length == 0) {
      throw new Error('RemoveLines got to lineids to remove');
    }
    const data: RemoveLinesDTO = {
      lineIds: lineIds,
    };
    return this.http.post('/cms/lst/removeListLine', data);
  }

  setLineSequence(lineIds: string[], previousSequence, nextSequence) {
    const data: SetLineSequenceDTO = {
      lineIds: lineIds,
      previousSequence: previousSequence,
      nextSequence: nextSequence,
    };
    return this.http.post('/cms/lst/setListLineSequence', data);
  }

  getListTypes(): Observable<IListTypesDTO[]> {
    const searchData = {
      search: '',
      type: 'list or line',
    };
    return this.http.post<IListTypesDTO[]>(
      '/cms/lst/listListTypes',
      searchData
    );
  }

  getProductsOnList(listId) {
    return this.getList(listId).pipe(
      map((list) => {
        const productIds = list.lines
          .filter((s) => s.product)
          .map((s) => s.product.id);
        return productIds;
      })
    );
  }

  getGroupedList(search, limit) {
    const searchData = {
      search,
      pageno: 1,
      limit,
    };
    return this.http.post('/cms/lst/listGroupedLists', searchData);
  }

  getList(id: string) {
    return this.http.post<SingleList>(`/cms/lst/getList/${id}`, {}).pipe(
      map((s: SingleList) => {
        s.lines.forEach((line) => {
          line.properties = line.properties ? line.properties : {};
        });
        return s;
      })
    );
  }

  createOrUpdateList(id, list) {
    return this.http.post('/cms/lst/upsertList/' + id, list);
  }

  getListsBasedOnListTypes(searchText, type) {
    const searchData = {
      search: searchText ?? '',
      listtype: type,
      pageno: 1,
      limit: 50,
    };
    return this.http.post<IListsDTO>('/cms/lst/listLists', searchData);
  }

  upsertListLine(lineId = 0, line) {
    return this.http.post<Line>(`/cms/lst/upsertListLine/${lineId}`, line);
  }

  getProductForId(pId) {
    return this.http.post('/cms/prd/getProduct/' + pId, { '': '' });
  }

  addProductToList(listId: number, productId) {
    if (!listId) {
      throw new Error('No listId provided');
      return;
    }
    if (!productId) {
      throw new Error('No productId provided');
      return;
    }
    const data: AddToListDTO = {
      seourlIds: productId,
    };

    return this.http.post<Line[]>(`/cms/lst/addRowToList/${listId}`, data).pipe(
      map((lines) => {
        lines.forEach((line) => {
          line.properties = line.properties ? line.properties : {};
        });
        return lines;
      })
    );
  }

  deleteList(list: SingleList) {
    return this.http.post(`/cms/lst/removeList/${list.list_id}`, {});
  }

  getProductData(productIds: number[]): Observable<IGetProduct[]> {
    const productHttpCalls = productIds.map((productID) =>
      this.http.get<IGetProduct>('/cms/prd/getProduct/' + productID)
    );
    return forkJoin(productHttpCalls);
  }

  printList(listId) {
    return this.pdfService.openPDFWithEntity({
      id: listId,
      entity: 'list',
      templatePath: '/custom-config/templates/list_pdf.hbs.html',
    });
  }

  printListAsInfoCards(listId) {
    return this.pdfService.openPDFWithEntity({
      id: listId,
      entity: 'infocard',
      templatePath: '/custom-config/templates/info_card_pdf.hbs.html',
    });
  }

  sendList(result) {
    return this.http.post('/cms/lst/copyList/', result);
  }

  // Use system config hostalias, instead of window.location.origin.
  scaleImage(image, maxWidth, maxHeight) {
    const siteConfig = this.systemConfigService.$config.getValue();
    let hostAlias = siteConfig.hostalias?.split(',')[0];
    if (hostAlias == '') {
      hostAlias = window.location.host;
    }
    if (!hostAlias.toLowerCase().includes('http')) {
      hostAlias = 'http://' + hostAlias;
    }

    return `${hostAlias}${prefixScaleImageUrl(image, {
      width: maxWidth,
      height: maxHeight,
    })}`;
  }


  copyListToBasket(listId: string) {
    return this.getList(listId)
      .pipe(
        tap(() => {
          this.basketDialogService.openBasket();
        }),
        switchMap((res: any) => {
          return this.basketService.addItemsToBasket(res.lines.filter(line => line.row_type === 'product').map(line => {
            return {
              image: line.product.imageUrl,
              title: line.product.title,
              productId: line.product.id,
              productNumber: line.product.productnumber,
              productSEOUrl: line.product.seourl,
              quantity: line.properties?.quantity || 1,
              pricePerUnit: line.product.price
            };
          }))
        }));
  }

  /**
   * @deprecated use BasketServiceV2.addItemsToBasket
   */
  copyProductToBasket(product, quantity?) {
    const payload = {
      quantity: quantity ? quantity : 1,
      properties: {},
    };
    return this.http.post('/cms/ord/orderAddProduct/' + product.id, payload);
  }

  showAddToListDialog(product) {
    this.userService.isLoggedIn$.pipe(first()).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.matDialog.open(AddToListComponent, {
          maxWidth: '100%',
          panelClass: 'small-dialog',
          autoFocus: false,
          data: {
            productIds: [product.seourlid]
          },
        });
      } else {
        this.userService.showLoginDialog();
      }
    });
  }
}

export interface CreateListDTO {
  collection_name: string;
  collection_id: string;
}

export interface IListsDTO {
  list_id: number;
  customer_id: number;
  description: string;
  listtype: string; //group by this
  properties?: any;
}

interface AddToListDTO {
  seourlIds: string[];
}

interface RemoveLinesDTO {
  lineIds: number[];
}

export interface IListTypesDTO {
  id: number;
  description: string; //label
  listtype: string; //Keyß
}

export interface Properties {
  customer_logo: string;
  invoicing_address: number;
  delivery_address: number;
  date: string;
  comment: string;
  fcid: string;
}

export interface Collection {
  name: string;
  description: string;
  placeholder?: any;
  dbtype: string;
  uom?: any;
  values?: any;
}

export interface LineCollection {
  name: string;
  description: string;
  placeholder?: any;
  dbtype: string;
  uom?: any;
  values?: any;
}

export interface SingleList {
  list_id: number;
  collection_id: number;
  line_collection_id: number;
  collection_name: string;
  customer_id: number;
  description: string;
  listype: string;
  lines: Line[];
  properties: Properties;
  collection: Collection[];
  line_collection: LineCollection[];
}

export interface Line {
  list_line_id: number;
  sequence: number;
  seo_url_id: number;
  product?;
  row_type: 'product' | 'heading';
  row_id: number;
  row_no: number;
  title: string;
  active: number;
  properties: any;
}

export interface SetLineSequenceDTO {
  lineIds: string[];
  previousSequence: number;
  nextSequence: number;
}
