import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { UserService } from '../../services/sitemule-user.service';

@Component({
  selector: 'sitemule-user-mat-registration',
  templateUrl: './mat-registration.component.html',
  styleUrls: ['./mat-registration.component.scss'],
})
export class SitemuleUserMatRegistrationComponent implements OnInit {
  userRegForm: any;
  formEmit: any;
  formValid = true;

  constructor(private userService: UserService, private http: HttpClient) {}

  ngOnInit() {
    this.userService
      .getUserRegistrationForm()
      .pipe(first())
      .subscribe((res) => {
        this.userRegForm = res;
      });
    // this.forgotPasswordForm = this.formBuilder.group({
    //   username: ['', Validators.required]
    // });
  }

  goBack() {}

  formValidFn(event) {
    this.formValid = event !== 'VALID';
  }

  setForm(event) {
    this.formEmit = event.value;
  }

  onSubmit() {
    // const payload = {};
    // this.userRegForm.items.forEach(item => {
    //   payload[item.key] = item.value;
    // });
    this.http
      .post(this.userRegForm.submitUrl, this.formEmit)
      .pipe(first())
      .subscribe(() => {});
  }
}
