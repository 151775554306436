import { IInventory } from '../services/webshop.service';

export interface AddToBasketDTO {
  quantity: number;
  properties?: any;
}

export interface Order {
  order_id: number;
  customer_id: number;
  order_status: Status;
  order_status_description: string;
  customer: {
    name: string;
    kam: string;
    cvr: string;
    erp_id: string;
    reference: string;
    properties: any;
  };
  order_date: string;
  delivery_date: string;
  billing_address_id: number;
  billing_address_name: string;
  billing_address_1: string;
  billing_address_2: string;
  billing_country_code: string;
  billing_zipcode: string;
  billing_city: string;
  billing_country: string;
  billing_mobile: string;
  billing_reference: any;
  billing_remark: any;
  billing_properties: any;
  billing_phone: string;
  delivery_address_id: number;
  delivery_address_name: string;
  delivery_address_1: string;
  delivery_address_2: string;
  delivery_country_code: string;
  delivery_zipcode: string;
  delivery_city: string;
  delivery_country: string;
  delivery_phone: string;
  delivery_mobile: string;
  delivery_reference: any;
  delivery_remark: any;
  delivery_properties: any;
  order_total_VAT: number;
  order_total_VAT_basis: number;
  order_total_web_discount: number;
  order_total_freight: number;
  order_total_additional_buy_for_free_delivery: number;
  order_total_free_delivery: boolean;
  order_total_price: number;
  order_total_included_discount: number;
  order_total_sum: number;
  reference: any;
  properties: any;
  total_discount_amount: number;
  total_discount_percent: number;
  total_freight_amount: number;
  total_gross_amount: number;
  total_net_amount: number;
  total_total_order: number;
  total_vat: number;
  total_vat_basis: number;
}

export enum Status {
  deleted = 0,
  draft = 10,
  offer = 20,
  basket = 30,
  ordered = 40,
  completed = 50,
  invoiced = 60,
  cancelled = 90,
}

export enum AddressType {
  billing = 1,
  delivery = 2,
}

export interface OrderLine {
  order_line_id: number;
  order_id: number;
  product: {
    image: string;
    product_id: number;
    productNumber: string;
    seourl: string;
    seourlid?: string;
    title: string;
  };
  quantity: number;
  price: number;
  properties: any;
}

export interface IGetProduct {
  product_id: number;
  productnumber: string;
  seourl: string;
  seourlid: number;
  categorySeourl: string;
  title: string;
  description: string;
  images: {
    caption: string;
    layout: any;
    title: string;
    type: string;
    url: string;
  }[];
  suppliernumber: string;
  unitprice?: number | string;
  unitPrice?: number | string;
  inventory: IInventory;
  customerPrice?: any;
  supplierdescription: string;
  documents: {
    title: string;
    url: string;
    prioritized?: boolean;
    type: string;
  }[];
  properties: {
    name: string;
    label: string;
    rule?: any;
    value: string;
    prioritized?: boolean;
  }[];
}
