import { Injectable } from '@angular/core';
import { SiteSettingsService as AbstractSiteSettingsService } from '@sitemule/ng-components/abstract-services/site-settings.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SiteSettingsService extends AbstractSiteSettingsService {
  override siteName$ = new BehaviorSubject('Folke-Larsens Eftf. A/S');
}
