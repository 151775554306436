import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CustomerPrice, Inventory, WebshopService } from './webshop.service';

interface ISparepartsPayload {
  language: string;
  search: string;
  pageno: number;
  limit: number;
}

export interface ISparePart {
  productId: string;
  productNumber: string;
  vendorItemNo: string;
  customerPrice: CustomerPrice;
  inventory: Inventory;
  seourl: string;
  seoUrlId_DONOT_USE_THIS: string; // Should be removed, check IBasketItem.productSEOUrlId_DONOT_USE_THIS
  price: number;
  imageUrl: string;
  title: string;
  description: string;
}

interface ISpareParts {
  items: ISparePart[];
}

interface ISparePartResponse {
  product_id: string;
  productNumber: string;
  vendorItemNo: string;
  seourl: string;
  seourlid: string;
  customerPrice: CustomerPrice;
  inventory: Inventory;
  imageUrl: string;
  label: string;
  vendDescription: string;
  vendDescription_2: string;
}
interface ISparepartsResponse {
  items: ISparePartResponse[];
}
@Injectable({
  providedIn: 'root',
})
export class SparePartsService {

  $loading : BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private webshopService: WebshopService
  ) {}

  fetchSpareParts(data: ISparepartsPayload): Observable<ISpareParts> {
    this.$loading.next(true);
    return this.http
      .post<ISparepartsResponse>('/cms/prd/listSpareparts', data)
      .pipe(
        switchMap((spareParts) => {
          const items = spareParts.items || [];
          const productNumbersToLookUp = items.map((s) => s.productNumber);

          return this.webshopService
            .getPricesAndInventory(productNumbersToLookUp, false)
            .pipe(
              map((inventories) => {
                return {
                  items: items.map((sparepart) => {
                    const inventory = inventories.find(
                      (inventory) =>
                        inventory.productNumber === sparepart.productNumber
                    );
                    return {
                      productId: sparepart.product_id,
                      productNumber: sparepart.productNumber,
                      vendorItemNo: sparepart.vendorItemNo,
                      inventory: sparepart.inventory,
                      customerPrice: sparepart.customerPrice,
                      seourl: sparepart.seourl,
                      seoUrlId_DONOT_USE_THIS: sparepart.seourlid,
                      price: inventory?.customerPrice?.salesPrice ? inventory.customerPrice.salesPrice : sparepart.customerPrice.salesPrice,
                      imageUrl: sparepart.imageUrl,
                      title: sparepart.label,
                      description: [sparepart.vendDescription, sparepart.vendDescription_2].filter(x => x).join(' ').trim(),
                    };
                  }),
                };
              })
            );
        }),
        catchError(err => {
          this.$loading.next(false);
          return throwError(() => err);
        }),
        tap(() => {
          this.$loading.next(false);
        })
      );
  }
}
