import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SEOService } from '@sitemule/ng-components/services/seo';
import { NotificationService, SitemulePageService, UserService } from '@sitemule/ngx-sitemule';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { DialogIEComponent } from './components/dialog-ie/dialog-ie.component';
import { TermsDialogComponent } from './components/terms-dialog/terms-dialog.component';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(private router: Router,
              private dialog: MatDialog,
              private dateAdapter: DateAdapter<Date>,
              private seoService: SEOService,
              private userService: UserService,
              private pageService: SitemulePageService,
              private translate: TranslateService,
              private notificationService: NotificationService) {

    // TODO Remove; Only for removing our service worker from FE
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }

    this.seoService.setPageTitle(''); // setPageTitle will add sitename by default on title
    this.router.events.pipe(first()).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (typeof gtag === 'function') {
          gtag('config', environment.GAid, {
            page_path: event.urlAfterRedirects,
          });
        }
      }
    });
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {
    const isIE = /msie\s|trident\//i.test(window.navigator.userAgent);
    if (isIE) {
      this.dialog.open(DialogIEComponent, {
        panelClass: 'dialogIE',
        width: '590px',
      });
    }

    let isTOSDialogVisible = false;
    this.userService.currentSession$.subscribe((session) => {
      const { show_terms_of_service, terms_of_service_article_id } = session || {};

      if(!isTOSDialogVisible && show_terms_of_service && terms_of_service_article_id) {
        isTOSDialogVisible = true;
        forkJoin({
          article: this.pageService.getContent(terms_of_service_article_id, true),
          acceptButtonText: this.translate.get('web.tos.acceptButtonText'),
          rejectButtonText: this.translate.get('web.tos.rejectButtonText'),
          undoRejectButtonText: this.translate.get('web.tos.undoRejectButtonText'),
          confirmRejectButtonText: this.translate.get('web.tos.confirmRejectButtonText'),
          confimRejectTitle: this.translate.get('web.tos.confimRejectTitle'),
          confimRejectDescription: this.translate.get('web.tos.confimRejectDescription'),
        }).subscribe(({ article: { content }, ...restData}) => {
          this.dialog.open(TermsDialogComponent, {
            disableClose: true,
            hasBackdrop: true,
            backdropClass: 'tos-dialog-backdrop',
            panelClass: 'tos-dialog-panel',
            data: {
              ...restData,
              id: '1234',
              article: content,
              onError: () => {
                this.notificationService.show(this.translate.instant('web.tos.error'), 'error');
              }
            }
          }).afterClosed().subscribe(accepted => {
            isTOSDialogVisible = false;
            if (accepted) {
              this.notificationService.show(this.translate.instant('web.tos.acceptedConfirmation'), 'success');
            }
            else {
              this.router.navigateByUrl(`/${this.translate.currentLang}/user/account/logout`);
            }
          });
        })
      }
    });

  }

}
