import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ngx-sitemule-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './table-standard.component.html',
  styleUrls: ['./table-standard.component.scss'],
})
export class TableStandardComponent {
  _dataSource: BehaviorSubject<TableUsingBuiltInDataTypes> =
    new BehaviorSubject(undefined);
  @Input() set data(data: TableUsingBuiltInDataTypes) {
    this._dataSource.next(data);
  }
  //Pagination rules and pagination page
  @Input() set pagination(pagination: TablePagination) {
    pagination.currentPage = pagination.currentPage || 0;
    pagination.shownEntries = pagination.shownEntries || 50;
    this._paginationSource.next(pagination);
  }

  _paginationSource: BehaviorSubject<TablePagination> = new BehaviorSubject({
    shownEntries: 50,
    currentPage: 0,
    direction: 'desc',
  });
  @Output() outPaginationChanged = new EventEmitter();
  _totalPages: any[] = [];

  //Sorting rules
  @Input() set sorting(sort: Headers) {
    this._sortBySource.next(sort);
  }

  _sortBySource: BehaviorSubject<any> = new BehaviorSubject(undefined);
  @Output() outSortingChanged = new EventEmitter();

  @Input() set selectedRow(row) {
    this._sortBySource.next(row);
  }

  _selectedRowSource: BehaviorSubject<any> = new BehaviorSubject(undefined);
  @Output() outRowClick = new EventEmitter();

  $tableHeaders = this._dataSource.pipe(
    map((s) => (s && s.headers ? s.headers : []))
  );
  $tableData = combineLatest([
    this._dataSource,
    this._sortBySource,
    this._paginationSource,
  ]).pipe(
    map((results) => {
      const data = results[0];
      const sort = results[1];
      const pagination = results[2];
      let itemsToShow =
        data && data.rows && data.rows.length > 0 ? data.rows : [];
      itemsToShow = sort
        ? this.doSort(itemsToShow, sort, data.headers)
        : itemsToShow;
      itemsToShow = pagination
        ? this.doPaginate(pagination, itemsToShow)
        : itemsToShow;
      return itemsToShow;
    })
  );

  doSort(
    itemsToShow: Row[],
    selectedHeader: TableHeader,
    allHeaders: TableHeader[]
  ) {
    const indexOfSelectedHeader = allHeaders.findIndex(
      (s) => s == selectedHeader
    );
    if (indexOfSelectedHeader > -1) {
      itemsToShow = itemsToShow.sort((a, b) => {
        const valueA = a.columns[indexOfSelectedHeader];
        const valueB = b.columns[indexOfSelectedHeader];
        return valueA.localeCompare(valueB);
      });
    }
    return itemsToShow;
  }

  doPaginate(pagination: TablePagination, columns: Row[]) {
    const shownEntries = pagination.shownEntries;
    const currentPage = pagination.currentPage || 0;
    const paginated = columns.slice(
      currentPage * shownEntries,
      currentPage * shownEntries + shownEntries
    );
    //SIDE EFFECT: Setting total pages. Maybe incorporate into RXJS stream?
    this._totalPages = Array(Math.ceil(columns.length / shownEntries));
    return paginated;
  }

  setSorting(header: TableHeader) {
    if (this._sortBySource.getValue() == header) {
      let updatePagination = { ...this._paginationSource.getValue() };
      updatePagination.direction =
        this._sortBySource.getValue() == header ? 'desc' : 'asc';
      this._paginationSource.next(updatePagination);
    }
    this._sortBySource.next(header);
    this.outSortingChanged.emit(header);
  }
  setCurrentPage(index) {
    if (this._totalPages.length - 1 < index || index < 0) {
      return;
    }
    let updatePagination = { ...this._paginationSource.getValue() };
    updatePagination.currentPage = index;
    this._paginationSource.next(updatePagination);
    this.outPaginationChanged.emit(updatePagination);
  }
  checkIfLink(link: string): boolean {
    link = link + '';
    return link.includes('http');
  }

  setShownRows(changeEvent) {
    let rows = changeEvent.currentTarget.value;
    let updatePagination = { ...this._paginationSource.getValue() };
    updatePagination.shownEntries = rows;
    this._paginationSource.next(updatePagination);
    this.outPaginationChanged.emit(updatePagination);
  }

  rowClick(row) {
    this._selectedRowSource.next(row);
    this.outRowClick.emit({
      row,
      index: this._dataSource.getValue().rows.indexOf(row),
    });
  }
}

export interface TablePagination {
  shownEntries?: number;
  currentPage?: number;
  direction: 'asc' | 'desc';
}

export interface TableUsingBuiltInDataTypes {
  headers: TableHeader[];
  rows?: Row[];
}

export interface TableHeader {
  title: string;
  datatype?: 'string' | 'number' | 'date'; //used to overwrite date format
}

export interface Row {
  columns: string[] | any;
}
