<!-- https://marvelapp.com/prototype/hd9he34/screen/84818617/handoff -->
<div class="color-overlay" *ngIf="color">
</div>
<div class="sum" *ngIf="data?.sum">
  {{data.sum.preText | translationKeyGenerator : 'web' | translate : { default: data.sum.preText } }}
  <div class="sum-number">
    {{data.sum.bigText}}
  </div>
  {{data.sum.postText | translationKeyGenerator : 'web' | translate : { default: data.sum.postText } }}
</div>
<ngx-sitemule-table-p [tableInitData]="data" [tableRowData]="data.rowData" (rowClickOut)="tableRowClick($event)"
  (lazyLoadEvt)="lazyLoadfn($event)">

</ngx-sitemule-table-p>
