import {
  ChangeDetectionStrategy,
  Component,
  Input, OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { BasketDialogService } from '../../core/basket/basket-dialog.service';
import { BasketService } from '../../core/basket/basket.service';
import { ISparePart, SparePartsService } from '../../core/services/spareparts.service';
import {
  CatalogContent,
  WebshopService
} from '../../core/services/webshop.service';
import { IMenuItem } from '../../ui/components/menu/menu.interface';
import { ListService } from '../../user/services/list.service';
import { UserService } from '../../user/services/sitemule-user.service';
import { CatalogService } from './catalog.service';
import { ICatalogItem } from './components/catalog-item/catalog-item.interface';

@Component({
  selector: 'webshop-catalog-entry',
  templateUrl: './catalog-entry.component.html',
  styleUrls: ['./catalog-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogEntryComponent implements OnInit {
  @Input() disableHeaderFilter = false;
  @Input() disableActions = false;

  showFilter = false;
  filterClick = false;

  expandMenuItem;
  filterUrl: object;
  searchLimit;
  $menuList = this.dataService.catalogMenus.pipe(map(menus => {
    return menus.map(menu => {
      return {
        ...menu,
        seourl: `/${this.translate.currentLang}/products/${menu.seourl}`
      }
    })
  }))
  $filterData = this.dataService.filterMenus;
  $catalogData: Observable<CatalogContent> = this.dataService.catalogs.pipe(
    tap((res) => {
      this.showFilter = res.filter ? res.filter : false;
      if (res.title !== 'Produkter' && res.title !== 'Products') {
        this.expandMenuItem = {
          ...res,
          items: res.items.map(item => {
            return {
              ...item,
              seourl: `/${this.translate.currentLang}/products/${item.seourl}`
            }
          })
        }
      }
    }),
    tap(() => {
      if (!this.showFilter) {
        this.dataService.resetFilter();
        this.filterClick = false;
      }
      if (this.showFilter && !this.filterClick) {
        let seoUrl;
        this.activatedRoute.url.pipe(first()).subscribe((r) => {
          seoUrl = this.checkUrl(r);
          this.dataService.initFilterMenu(seoUrl);
        });
      }
    })
  );

  viewType = 'grid';
  idsArr = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: CatalogService,
    private userService: UserService,
    private route: Router,
    private webshopEntryService: WebshopService,
    private translate: TranslateService,
    private listService: ListService,
    private basketService: BasketService,
    private sparePartsService: SparePartsService,
    private acitvatedRoute: ActivatedRoute,
    private basketDialogService: BasketDialogService,
  ) {
    this.searchLimit = this.webshopEntryService.searchLimit;
  }

  $catalogItems = this.dataService.catalogs.pipe(
    map((data) => {
      const mapper = (item) => {
        let catalogItem: ICatalogItem = {...item};
        catalogItem.link = `/${this.translate.currentLang}/products/${item.seourl}`;
        if (item.customerPrice) {
          catalogItem.customerPrice = item.customerPrice.salesPrice;
        }
        return catalogItem;
      };

      return {
        items: data.items.map(mapper),
      };
    })
  );

  $sparePartsLoading = this.sparePartsService.$loading.asObservable();
  private fetchedSpareParts: ISparePart[] = [];
  $spareParts = this.acitvatedRoute.queryParams.pipe(switchMap((queryParams) => {
    return this.sparePartsService.fetchSpareParts({
      search: queryParams.search,
      language: this.translate.defaultLang,
      limit: 300,
      pageno: 1,
    }).pipe(
      tap(({items: spareparts}) => {
        this.fetchedSpareParts = spareparts;
      }),
      map(({items: spareparts}) => {
        return spareparts.map(
          ({
             productId,
             productNumber,
             vendorItemNo,
             seourl,
             price,
            customerPrice,
            inventory,
             title,
             description,
           }) => ({
            id: productId,
            productNumber: productNumber,
            vendorItemNo: vendorItemNo,
            price,
            title,
            customerPrice:customerPrice.salesPrice,
            inventory,
            description,
            label: this.translate.instant('web.webshop.product.itemNumber'),
            vatText: this.createVATtext(price, customerPrice.listPrice),
            link: seourl,
          })
        );
      })
    )
  }));

  private createVATtext(price, customerPrice) {
    if(customerPrice && customerPrice != price){
      return (this.translate.instant('web.webshop.product.indicative_price') + " " +
        customerPrice + " " + this.translate.instant('web.webshop.product.tax'));
    }
    return this.translate.instant('web.webshop.product.tax')
  }

  private checkUrl(url) {
    let seoUrl = '';
    url.forEach((seg) => {
      if (seoUrl !== '') {
        seoUrl = seoUrl + '/' + seg.path;
      } else {
        seoUrl = seoUrl + seg.path;
      }
    });
    return seoUrl;
  }

  ngOnInit(): void {
    this.dataService.initCatalogMenuList();
  }

  filterCatalog(filterEvent: any) {
    this.filterClick = true;
    this.filterUrl = {};
    this.idsArr = [];
    filterEvent.selectedFilterGroups.forEach((filterGroup) => {
      filterGroup.values.forEach((value) => {
        if (value.selected || value.selected === false) {
          if (filterGroup.isMasterFilter && value.selected) {
            this.addToFilterUrlObj(filterGroup, value);
            this.idsArr = [...this.idsArr, ...value.products];
          }
          if (
            this.idsArr.length !== 0 &&
            !filterGroup.isMasterFilter &&
            value.selected
          ) {
            this.addToFilterUrlObj(filterGroup, value);
            this.idsArr = this.idsArr.filter((x) => value.products.includes(x));
          }
        }
      });
    });
    this.route.navigate([location.pathname], {
      queryParams:
        Object.keys(this.filterUrl).length !== 0
          ? {filter: JSON.stringify(this.filterUrl)}
          : {},
    });
    if (this.idsArr.length > 0) {
      this.doFilterFilter(this.idsArr);
    }
    if (this.idsArr.length === 0) {
      this.dataService.setOriginalCatalogAndFilterList();
    }
  }

  private addToFilterUrlObj(filterGroup, value) {
    if (!this.filterUrl[filterGroup.property]) {
      this.filterUrl[filterGroup.property] = [value.value];
    } else {
      if (
        !this.filterUrl[filterGroup.property].find((r) => r === value.value)
      ) {
        this.filterUrl[filterGroup.property] = [
          ...this.filterUrl[filterGroup.property],
          value.value,
        ];
      }
    }
  }

  private doFilterFilter(ids) {
    this.$filterData.pipe(first()).subscribe((filter2: any) => {
      filter2.forEach((parentFilter) => {
        parentFilter.values.forEach((value) => {
          value.products.filter((pValue) => ids.includes(pValue));
          if (parentFilter.isMasterFilter) {
            value.number = value.products.length;
          }
          if (value.products.length === 0 && !parentFilter.isMasterFilter) {
            value.number = 0;
          }
          if (value.products.length !== 0 && !parentFilter.isMasterFilter) {
            const foundValue = JSON.parse(
              JSON.stringify(value.products.filter((x) => ids.includes(x)))
            );
            value.number = foundValue.length;
          }
        });
      });
    });
    this.changeCatalogDataByFilter(ids);
  }

  private changeCatalogDataByFilter(ids) {
    const orgCat = this.dataService.getOrgCatalog();
    const filt = orgCat.items.filter((catalogItem) => {
      catalogItem.product_id = catalogItem.product_id
        ? catalogItem.product_id
        : -1;
      return ids.includes(catalogItem.product_id.toString());
    });
    const newCat = {
      title: orgCat.title,
      items: [],
      filter: true,
      breadcrumb: orgCat.breadcrumb,
    };
    newCat.items = filt;
    this.dataService.setCatalogFilteredList(newCat);
  }

  menuMouseOver(menu) {
    this.dataService.addChildToMenu(menu);
  }

  changeGridCView(event) {
    this.viewType = event;
  }

  changeSortOrder(menuItem: IMenuItem) {
    this.filterClick = true;
    const res: any = this.dataService.getOrgCatalog();

    switch (menuItem.label) {
      case 'Lavest pris': {
        res.items = res.items.sort((a, b) => {
          return Number(a.price) - Number(b.price);
        });
        break;
      }
      case 'Højest pris': {
        res.items = res.items.sort((a, b) => {
          return Number(b.price) - Number(a.price);
        });
        break;
      }
      case 'Alfabetisk A-Z': {
        res.items = res.items.sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
        break;
      }
      case 'Alfabetisk Z-A': {
        res.items = res.items.sort((a, b) => {
          return b.label.localeCompare(a.label);
        });
        break;
      }
      default: {
        break;
      }
    }
    const newCat = {
      title: res.title,
      items: res.items,
      filter: true,
      breadcrumb: res.breadcrumb,
    };
    this.dataService.setCatalogFilteredList(newCat);
  }

  addSparePartToBasket(sparePartId: string) {
    const sparePart = this.fetchedSpareParts.find(
      ({productId}) => productId === sparePartId
    );

    if (!sparePart) {
      throw new Error(`Couldn't find the clicked spare-part: ${sparePartId}`);
    }


    this.userService.isLoggedIn$.pipe(first()).subscribe((isLoggedIn) => {
      const stuffToDo = () => {
        this.basketService.addItemToBasket({
          image: sparePart.imageUrl,
          title: sparePart.title,
          productId: sparePart.productId,
          productNumber: sparePart.productNumber,
          productSEOUrl: sparePart.seourl,
          productSEOUrlId_DONOT_USE_THIS: sparePart.seoUrlId_DONOT_USE_THIS,
          quantity: 1,
          pricePerUnit: sparePart.price,
        }).subscribe();
        this.basketDialogService.openBasket(sparePart.productNumber);
      };
      if (isLoggedIn) {
        stuffToDo();
        return;
      }
      let bla = this.userService.showLoginDialog({dontRedirect: true});
      bla.subscribe((g) => {
        if (g) {
          stuffToDo();
        }
      });
    });
  }

  addSparePartToFavoriteList(sparePartId: string) {
    const sparePart = this.fetchedSpareParts.find(
      ({productId}) => productId === sparePartId
    );

    if (!sparePart) {
      throw new Error(`Couldn't find the clicked spare-part: ${sparePartId}`);
    }
    this.listService.showAddToListDialog({
      id: sparePart.seoUrlId_DONOT_USE_THIS
    });
  }
}
