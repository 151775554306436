import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../../../user/services/sitemule-user.service';

@Component({
  selector: 'ui-header-msg',
  templateUrl: './header-msg.component.html',
  styleUrls: ['./header-msg.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMsgComponent {
  constructor(private userService: UserService) {}

  hideMessage$ = new BehaviorSubject(false); // Boolean flag so we instantly hide the message when clicked on button
  templateData$ = combineLatest([
    this.hideMessage$,
    this.userService.tryingToWorkAsClientOrCustomer$,
    this.userService.currentSession$
  ]).pipe(map(([hideMessage, tryingToWorkAsClientOrCustomer, currentSession]) => {
    if (hideMessage) {
      return false;
    }

    if (tryingToWorkAsClientOrCustomer) {
      return {
        showLoading: true
      }
    }
    if(currentSession && (currentSession.workAsClient || currentSession.workAsCustomer)) {
      return {
        name: currentSession.user.name,
        customerName: currentSession.customer.name,
      };
    }

    return false;
  }))

  goBack() {
    this.hideMessage$.next(true);
    this.userService.removeSellerAsCustomer()
      .subscribe(() => {
        this.hideMessage$.next(false);
      });
  }
}
