import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { WebshopService } from '../../core/services/webshop.service';
import { ICatalogItem } from '../catalog/components/catalog-item/catalog-item.interface';
import { ICatalog } from '../catalog/components/catalog/catalog.interface';
import { ProductService } from './product.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'webshop-product-entry',
  template: `
    <webshop-product
      [product]="product"
      *ngIf="$product | async as product"
      [productsInSameCategory]="$productsInSameCategory | async"
      [relatedProducts]="$relatedProducts | async"
    ></webshop-product>
    <product-template *ngIf="$product | async as productT" [product]="productT">
    </product-template>
  `,
  styleUrls: ['./product-entry.component.scss'],
})
export class ProductEntryComponent {
  $product = this.dataService.productItem;
  $relatedProducts = this.dataService.productItem.pipe(
    switchMap((product) => {
      return this.getRelatedProduct(product.seourl);
    })
  );
  $productsInSameCategory = this.dataService.productItem.pipe(
    switchMap((product) => {
      return this.webshopEntryService
        .getContentBySeo(product.categorySeourl)
        .pipe(
          map((res) => {
            // Removing the product that is displayed, from the "Flere produkter i samme kategori" list.
            if (res.content.items) {
              const items = res.content.items;
              const findIndexDub = items.findIndex(
                (p) => p.product_id === product.product_id
              );
              if (findIndexDub !== undefined || findIndexDub !== null) {
                items.splice(findIndexDub, 1);
              }
              const obj: ICatalog = { items: [] };
              obj.items = items.map((s) => {
                const catalogItem: ICatalogItem = s;
                catalogItem.customerPrice =
                  s.customerPrice?.salesPrice || undefined;
                catalogItem.link = `/${this.translate.currentLang}/products/${s.seourl}`;
                return catalogItem;
              });
              return obj;
            }
          })
        );
    })
  );

  constructor(
    private dataService: ProductService,
    private webshopEntryService: WebshopService,
    private translate: TranslateService,
  ) {}

  private getRelatedProduct(seoUrl) : Observable<ICatalog> {
    return this.dataService.getRelatedProductBySeoUrl(seoUrl).pipe(
      map((relProducts) => {
        if (!relProducts) {
          return;
        }

        return {
          items: relProducts.map((relatedProduct) => ({
            id: relatedProduct.product_id,
            product_id: relatedProduct.product_id,
            imageUrl:
              relatedProduct.images && relatedProduct.images.length > 0
                ? relatedProduct.images[0].url
                : '',
            body: relatedProduct.description,
            label: relatedProduct.title,
            price: relatedProduct.unitPrice as string,
            customerPrice:
              relatedProduct.customerPrice?.salesPrice || undefined,
            inventory: relatedProduct.inventory,
            productNumber: relatedProduct.productnumber,
            seourl: relatedProduct.seourl,
            link: `/${this.translate.currentLang}/products/${relatedProduct.seourl}`,
          })),
        };
      })
    );
  }
}
